export const getInitials = (name) => {
  if (name) {
    const initials = name
      .split(" ")
      .map((name) => `${name.charAt(0).toUpperCase()}`)
      .join("");

    return initials;
  }
  return "N/A";
};

export const CamelCaseToTitleCase = (str) => {
  const regex = /[a-z][A-Z]/g;
  return str
    .replace(regex, (match) => match[0] + " " + match[1].toUpperCase())
    .replace(/\b\w/g, (match) => match.toUpperCase());
};

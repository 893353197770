import { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import AddSite from "../../components/ProjectSite/AddSite";
import Map from "../../components/ProjectSite/Map";
// import ProjectSiteTable from "../../components/ProjectSite/Tables/";
import TableTop from "../../components/TableTop";
import TopCard from "../../components/TopCard";
// import TopNav from "../../components/TopNav";
import axios from "../../utils/axios";
import toast from "react-hot-toast";
import EditSite from "../../components/ProjectSite/EditSite";
import DeleteSite from "../../components/ProjectSite/DeleteSite";
// import TopNavTitle from "../../components/TopNav/TopNavTitle";

/** AntD Table */
import AntDTable from "../../components/Table/AntdTable";
import ListLoader from "../../components/ListLoader";
import { projectSiteColumns } from "../../components/TableColumns";
import { Button } from "../../components/Button";
import { themeColor } from "../../constant/color";
import ProjectSiteTable from "../../components/ManageMeter/Table/ProjectSiteTable";
import ProjectSitesTable from "../../components/ProjectSite/Tables/ProjectSiteTable";

const ProjectSites = () => {
  const [addSite, setAddSite] = useState(false);
  const [editSite, setEditSite] = useState(null);
  const [deleteSite, setDeleteSite] = useState(null);
  const [selectedSite, setselectedSite] = useState({});
  const [map, setMap] = useState(false);
  const [projectSites, setProjectSites] = useState([]);
  const [siteTypes, setSiteTypes] = useState([]);
  const controller = new AbortController();

  const [loading, setLoading] = useState(true);

  function getProjectSites() {
    axios
      .post("/sitemanagement/getallsitesdetails", {})
      .then((response) => {
        setLoading(false);
        const { body } = response.data;
        // body.forEach((item, index) => {
        //   item.tableIndex = index + 1;
        // });
        setProjectSites(response.data.body);
      })
      .catch((err) => {
        setLoading(false);
        toast(err.message);
      });
  }

  useEffect(() => {
    getProjectSites();
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    axios
      .post("/settings/getdropdown", { comboname: "Site type" })
      .then((response) => {
        // if (!Object.entries(response.data).length == 0) return null;
        // console.log(response, "dropdown");
        console.log(response.data, "dropdown");
        setSiteTypes(response.data.list.split(",").map((t) => t.trim()));
      })
      .catch((err) => {
        toast(err.message);
      });
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      <Layout>
        {addSite && (
          <AddSite
            closeModal={setAddSite}
            siteTypes={siteTypes}
            refresh={getProjectSites}
          />
        )}
        {editSite && (
          <EditSite
            closeModal={setEditSite}
            siteTypes={siteTypes}
            site={editSite}
            refresh={getProjectSites}
          />
        )}
        {deleteSite && (
          <DeleteSite
            closeModal={setDeleteSite}
            site={deleteSite}
            selectedSite={selectedSite}
            setselectedSite={setselectedSite}
            refresh={getProjectSites}
          />
        )}
        <div className="">
          <div className="p-x-2 mt-4 p-0">
            <div className=" bg-white p-2 rounded-md shadow-md">
              <div className="flex items-cente justify-between">
                <div className="flex items-center ">
                  <div className=" w-[15px] h-[25px] bg-[#e0c5ff] rounded-sm"></div>
                  <div className="font-semibold ml-2">
                    Registered project site
                  </div>
                </div>
                <div className="flex items-center w-[170px] justify-end">
                  <Button
                    text={"Add project site."}
                    bg={themeColor.secondary}
                    onClick={() => setAddSite(true)}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-3 my-1 gap-4">
                {/* <TopCard title={"All registered users"} value={count} /> */}
                <div
                  className={`h-[150px] p-2 border-[1px] border-gray-200 shadow-sm rounded-md`}
                >
                  <div className="h-[50px] w-[50px] rounded-full bg-gray-100"></div>
                  <div className="pt-2 font-light">Total Project site</div>
                  <div className="pt-2 font-bold text-3xl">
                    {projectSites.length}
                  </div>
                </div>
                <div
                  className={`h-[150px] p-2 border-[1px] border-gray-200 shadow-sm rounded-md`}
                >
                  <div className="h-[50px] w-[50px] rounded-full bg-gray-100"></div>
                  <div className="pt-2 font-light">Project site type.</div>
                  <div className="pt-2 font-bold text-3xl">
                    {siteTypes.length}
                  </div>
                </div>
                <div
                  className={`h-[150px] p-2 border-[1px] border-gray-200 shadow-sm rounded-md`}
                >
                  <div className="h-[50px] w-[50px] rounded-full bg-gray-100"></div>
                  <div className="pt-2 font-light">unknown card</div>
                  <div className="pt-2 font-bold text-3xl">0</div>
                </div>
              </div>
            </div>
            <div className=" grid-cols-4 hidden">
              <div className="">
                <TopCard title={"Project site"} value={projectSites.length} />
              </div>
            </div>
            <div className="mt-4 px-3 h-[500px] bg-white shadow-md rounded-md">
              <ProjectSitesTable
                data={projectSites}
                loading={loading}
                setEditSite={setEditSite}
                setDeleteSite={setDeleteSite}
                selectedSite={selectedSite}
                setselectedSite={setselectedSite}
              />
              {/* <div className="bg-white  hidden">
                <TableTop
                  showAddSite
                  title={"List of Project Sites"}
                  setAddSiteBtn={setAddSite}
                  showMap
                  setShowMap={setMap}
                />
              </div> */}
              {/* {!map && ( */}
              {/* <ProjectSiteTable
                    projectSites={projectSites}
                    setEditSite={setEditSite}
                    setDeleteSite={setDeleteSite}
                  /> */}
              {/* {!loading && (
                 
              {/* <ListLoader rows={10} /> */}
              {/* )} */}
              {/* {map && <Map />} */}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ProjectSites;

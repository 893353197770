import { themeColor as color } from "../../constant/color";
import "./styles.css";
import { css } from "@emotion/react";
import { SyncLoader, ClipLoader, BarLoader } from "react-spinners";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const ProcessingLoader = ({ msg }) => {
  return (
    <div className="backdrop">
      <div className="loader-cover animate__animated animate__fadeInUp rounded-1 bg-white  flex-column p-3 shadow-sm d-flex align-items-center justify-content-center">
        <div className="">
          <SyncLoader
            color={color.baseColor}
            loading={true}
            css={override}
            size={8}
            width={30}
            speedMultiplier={0.8}
          />
        </div>
        <div
          className="loader-text pt-2 text-center fst-italic"
          style={{
            color: color.baseColor,
            fontSize: "12px",
          }}
        >
          {msg}...
        </div>
      </div>
    </div>
  );
};

export default ProcessingLoader;

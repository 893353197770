import { BiUserPlus } from "react-icons/bi";
import "./styles.css";
import AddSiteImg from "../../../assets/svg/siteimg.svg";
import { useEffect, useState } from "react";
import { Select } from "antd";
import "antd/dist/antd.css";
import { themeColor as color } from "../../../constant/color";
import { Button } from "../../Button";
import axios from "../../../utils/axios";
import toast from "react-hot-toast";
import { BsTrash, BsTrash2 } from "react-icons/bs";
import Rule from "../../Rule/Index";

const DeleteSite = ({
  closeModal,
  site,
  refresh,
  selectedSite,
  setselectedSite,
}) => {
  const [loading, setLoading] = useState(false);
  const [sitename, setSitename] = useState("");

  const { Option } = Select;

  const deleteProjectSite = () => {
    setLoading(true);
    axios
      .post("/sitemanagement/deletesite", { sitename: selectedSite })
      .then((response) => {
        setLoading(false);
        toast("Project site has been deleted");
        refresh();
        closeModal(null);
      })
      .catch((err) => {
        setLoading(false);
        toast(err.message);
      });
  };

  useEffect(() => {
    // setSitename(site.sitename);
  });

  return (
    <div className="backdrop">
      <div className="bg-white shadow-md rounded-md px-3 py-4 animate__animated animate__fadeInUp ">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <BsTrash size={14} className="text-red-400" />
            <div className="text-red-400 ml-2">Delete {selectedSite}</div>
          </div>
          <di
            className="text-secondary cursor-pointer"
            onClick={() => closeModal(null)}
          >
            Close
          </di>
        </div>
        <div className="my-4 text-center">
          Are you sure you want to delete this project site? <br />
          This operation is irreversable!
        </div>
        <Rule />
        <div className="w-100 mt-4" onClick={() => deleteProjectSite(true)}>
          <Button text="Yes, delete" height={"40px"} status={loading} />
        </div>
      </div>
    </div>
  );
};

export default DeleteSite;

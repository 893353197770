import { useState } from "react";
import {  themeColor as color } from "../../constant/color";
import "./styles.css";

const TopDateFilter = ({ startDate }) => {
  const [tabState, setTabState] = useState("today");
  return (
    <div className="flex w-full items-center justify-between">
      <div
        className="px-4 py-2 date-filter__btn"
        style={{
          background:
            tabState == "today" ? color.baseColor : color.btnFadeColor,
          color: tabState == "today" ? color.whiteColor : color.baseColor,
          fontSize: "12px",
        }}
        onClick={() => setTabState("today")}
      >
        Today
      </div>
      <div
        className="px-4 py-2 date-filter__btn"
        style={{
          background: tabState == "week" ? color.baseColor : color.btnFadeColor,
          color: tabState == "week" ? color.whiteColor : color.baseColor,
          fontSize: "12px",
        }}
        onClick={() => setTabState("week")}
      >
        This week
      </div>
      <div
        className="px-4 py-2 date-filter__btn"
        style={{
          background:
            tabState == "month" ? color.baseColor : color.btnFadeColor,
          color: tabState == "month" ? color.whiteColor : color.baseColor,
          fontSize: "12px",
        }}
        onClick={() => setTabState("month")}
      >
        This month
      </div>
    </div>
  );
};

export default TopDateFilter;

import { BiUserPlus } from "react-icons/bi";
import "./styles.css";
import AddSiteImg from "../../../assets/svg/siteimg.svg";
import { useEffect, useState } from "react";
import { Select } from "antd";
import "antd/dist/antd.css";
import { themeColor as color } from "../../../constant/color";
import { Button } from "../../Button";
import axios from "../../../utils/axios";
import toast from "react-hot-toast";

const AddSite = ({ closeModal, siteTypes, refresh }) => {
  const [loading, setLoading] = useState(false);
  const [sitename, setSitename] = useState("");
  const [sitetype, setSitetype] = useState(null);
  const [gps, setGps] = useState("");

  const { Option } = Select;

  const submitProjectSite = () => {
    if (!sitename) return toast("Please enter a name for the site");
    if (!sitetype) return toast("Please select a site type");
    if (!gps) return toast("Please enter a GPS value");

    console.log(gps);
    if (!/^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/.test(gps))
      return toast("Invalid GPS format.");
    setLoading(true);
    axios
      .post("/sitemanagement/addnewsite", { sitename, sitetype, gps })
      .then((response) => {
        setLoading(false);

        toast.success("Project site has been added");

        refresh();
        closeModal(false);
      })
      .catch((err) => {
        setLoading(false);
        toast(err.message);
      });
  };

  return (
    <div className="backdrop">
      <div className="w-[95%] md:w-[400px] animate__animated animate__fadeInUp px-3 py-2 flex flex-col items-center justify-start  bg-white  shadow-sm rounded-sm">
        <div className="w-full flex items-center justify-between">
          <div className="">
            <div className="font-bold text-start">
              Create a new project site
            </div>
            <div className=" text-start">
              Fill in the field below to create a new project site
            </div>
          </div>
          <div
            className="text-secondary cursor-pointer"
            onClick={() => closeModal(false)}
          >
            Close
          </div>
        </div>

        <div className="flex overflow-hidden  items-center w-full border-[1px] rounded-sm border-gray-300 mt-4 h-[40px] w-100">
          <div className="ml-2 w-100">
            <input
              type={"text"}
              value={sitename}
              onChange={(e) => setSitename(e.target.value)}
              placeholder={"Enter site name."}
              // onChange={(e) => setinputfield(e.target.value)}
              className="placeholder:text-sm block w-100 text-[13px] placeholder:font-normal outline-none border-[0px] "
            />
          </div>
        </div>
        <div className="w-full hidden mt-2">
          <input
            type="text"
            className="w-full px-2 rounded-sm"
            placeholder={"Enter site name."}
            value={sitename}
            onChange={(e) => setSitename(e.target.value)}
          />
        </div>
        <div className=" w-full mt-3 ">
          <Select
            style={{ width: "100%", fontSize: "1rem" }}
            allowClear
            size="large"
            placeholder="Select site type."
            value={sitetype}
            onChange={(val) => setSitetype(val)}
          >
            {siteTypes.map((type) => (
              <Option value={type} key={type}>
                {type}
              </Option>
            ))}
          </Select>
        </div>
        <div className="flex overflow-hidden  items-center w-full border-[1px] rounded-sm border-gray-300 mt-2 h-[40px] w-100">
          <div className="ml-2 w-100">
            <input
              type={"text"}
              value={gps}
              onChange={(e) => setGps(e.target.value)}
              placeholder={"Enter GPS location."}
              // onChange={(e) => setinputfield(e.target.value)}
              className="placeholder:text-sm block w-100 text-[13px] placeholder:font-normal outline-none border-[0px] "
            />
          </div>
        </div>
        <div className="hidden w-full mt-4 ">
          <input
            type="text"
            className="w-full input-box px-2 rounded-sm"
            placeholder={"Enter GPS location."}
            value={gps}
            onChange={(e) => setGps(e.target.value)}
          />
        </div>
        <div className="flex items-center mt-3 justify-between w-full addrolebtn-cover pt-3">
          <div className="addrole-btn hidden" onClick={() => closeModal(false)}>
            <Button
              text="Cancel"
              // bg={color.btnFadeColor}
              // color={color.darkColor}
              height={"40px"}
            />
          </div>
          <div className="w-full" onClick={() => submitProjectSite(true)}>
            <Button
              text="Done"
              // bg={color.baseColor}
              // color={color.whiteColor}
              height={"40px"}
              status={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSite;

import moment from "moment";
import { RiHashtag } from "react-icons/ri";
import { Link } from "react-router-dom";
import { themeColor as color } from "../../../../constant/color";
import { FaMapMarkedAlt } from "react-icons/fa";
import "./style.css";

const ProjectManagerCard = ({ entity, role, link }) => {
  return (
    <Link
      to={link}
      className="d-flex align-items-start justify-content-between my-1 py-2 role-card"
      style={{
        textDecoration: "none",
      }}
    >
      <div className=" d-flex align-items-center justify-content-start">
        <div
          className="d-flex align-items-center justify-content-center rounded-1 hashbg"
          style={{
            background: color.lightbaseCOlor,
          }}
        >
          <FaMapMarkedAlt size={19} color={color.darkColor} />
        </div>
        <div className="px-2">
          <div className="role-name">{entity?.username || "Orkland John"}</div>
          <div className="role-caption">
            {entity?.role || role || "Project Manager"}
          </div>
        </div>
      </div>
      <div className="role-date">
        {moment(Date.now()).format("DD-MM-YYYY HH:mm")}
      </div>
    </Link>
  );
};

export default ProjectManagerCard;

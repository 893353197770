export const columnData = [
  "Meter",
  "Customerid",
  "Phone",
  "Email",
  "Agent",
  "Section",
  "Zone",
  "Datecreated",
  "Dumbmeterfee",
  "Connectionbalance",
  "Connectionfee",
  "Maxperiodiccharge",
];

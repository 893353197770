import {
  AiFillEye,
  AiOutlineEye,
  AiOutlineKey,
  AiOutlineUser,
} from "react-icons/ai";
import { Button } from "../../../components/Button";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { getToken, setToken } from "../../../utils/token";
import axios from "axios";
import BGImg from "../../../assets/png/v300batch2-nunoon-13.jpg";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [subdomain, setSubdomain] = useState("we");
  const [loading, setLoading] = useState(false);
  const [eye1, setEye1] = useState(false);
  const [eye, setEye] = useState(false);
  let navigate = useNavigate();
  const handleReset = async () => {
    try {
      if (!newPassword || !password || !username) {
        toast.error("All values are required");
        return;
      }
      setLoading(true);
      let res = await axios.post(
        "https://api.mms.spiral.systems/auth/resetdefaultpassword",
        {
          username,
          password,
          newpassword: newPassword,
          subdomain: "IKJ",
        }
      );
      if (res.data.response == "failure") {
        toast.error("Operation failed. Please try again.");
        setLoading(false);
        return;
      }
      await setToken("spiral_token", res.data.sessionid);
      await setToken("spiral_username", username);
      console.log(res.data);
      if (!res.data?.registration) {
        setLoading(false);
        await setToken("spiral_registration", 0);
        navigate("/completeregistration");
        return;
      }
      if (res.data?.registration) {
        setLoading(false);
        await setToken("spiral_registration", 1);
        navigate("/userroles");
        return;
      }
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  };

  return (
    <div className="h-screen w-screen h-100 bg-gray-50 flex flex-row items-center justify-center">
      <div
        className="h-screen absolute top-0 left-0 w-screen"
        style={{
          background: `url(${BGImg})`,
          backgroundPosition: "center",
          backgroundRepeat: "center",
          backgroundSize: "cover",
          opacity: 0.3,
          backgroundAttachment: "fixed",
        }}
      ></div>
      <div className="">
        <div className="bg-white w-[95%px] md:w-[500px] h-auto drop-shadow-md rounded-md p-6">
          <div className="text-center text-primary font-semibold text-2xl pb-4">
            Spiral Energy
          </div>
          <div className="font-normal text-center text-[16px] text-gray-600">
            Please reset your default password to continue.
          </div>
          <hr className="my-4" />
          <div className="w-100">
            {/* Username */}
            <div className="mt-6">
              <label className="text-primary font-normal text-sm">
                Username
              </label>
              <div className="flex items-center justify-between w-100 border-[1px] rounded-sm border-gray-300 mt-2 h-[45px]">
                <div className="flex ">
                  <div className="d-inline flex items-center justify-center px-2">
                    <AiOutlineKey
                      color="text-primary"
                      className="text-primary"
                    />
                  </div>
                  <div className="ml-2">
                    <input
                      required
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      type={"text"}
                      placeholder={"Enter your old password"}
                      className=" placeholder:text-sm placeholder:font-normal outline-none border-[0px]"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Default password */}
            <div className="mt-6">
              <label className="text-primary font-normal text-sm">
                Default password
              </label>
              <div className="flex items-center justify-between w-100 border-[1px] rounded-sm border-gray-300 mt-2 h-[45px]">
                <div className="flex ">
                  <div className="d-inline flex items-center justify-center px-2">
                    <AiOutlineKey
                      color="text-primary"
                      className="text-primary"
                    />
                  </div>
                  <div className="ml-2">
                    <input
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type={eye ? "text" : "password"}
                      placeholder={"Enter your old password"}
                      className=" placeholder:text-sm placeholder:font-normal outline-none border-[0px]"
                    />
                  </div>
                </div>

                <div
                  className="px-3 cursor-pointer"
                  onClick={() => setEye(!eye)}
                >
                  <AiOutlineEye color="text-primary" className="text-primary" />
                </div>
              </div>
            </div>
            {/* New Password */}
            <div className="mt-6">
              <label className="text-primary font-normal text-sm">
                New Password
              </label>
              <div className="flex items-center justify-between w-100 border-[1px] rounded-sm border-gray-300 mt-2 h-[45px]">
                <div className="flex ">
                  <div className="d-inline flex items-center justify-center px-2">
                    <AiOutlineKey
                      color="text-primary"
                      className="text-primary"
                    />
                  </div>
                  <div className="ml-2">
                    <input
                      required
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      type={eye1 ? "text" : "password"}
                      placeholder={"Enter your new password"}
                      className=" placeholder:text-sm placeholder:font-normal outline-none border-[0px]"
                    />
                  </div>
                </div>
                <div
                  className="px-3 cursor-pointer"
                  onClick={() => setEye1(!eye1)}
                >
                  <AiOutlineEye color="text-primary" className="text-primary" />
                </div>
              </div>
            </div>
            {/* Button */}
            <div className=" w-100 mt-7">
              <Button
                text={"Continue"}
                loading={loading}
                onClick={handleReset}
              />
            </div>

            <div className="w-100 mt-4 ">
              <div className="text-primary block w-100 text-center font-normal py-3">
                {/*  Need help with you account? */}
              </div>
              <Link
                to="/"
                className="underline w-100 block cursor-pointer text-sm text-secondary text-center"
              >
                Already have an account? Log in.
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

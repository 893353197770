import moment from "moment";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import Rule from "../../components/Rule/Index";
import { IoNotificationsOutline } from "react-icons/io5";
import { FcCalendar } from "react-icons/fc";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { NotificationFormatter, alertFormatter } from "../../helperFuncs";
import NotiImg from "../../assets/svg/2246841_bell_notification_one notification_twitter_icon.svg";
import BackBtn from "../../components/BackBtn";
import { useMQTT } from "../../context/MqttContext";
const Notification = () => {
  const [noti, setnoti] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const navigate = useNavigate();
  const { client, mqttEvents } = useMQTT();
  useEffect(() => {
    (async () => {
      try {
        let data = await JSON.parse(
          localStorage.getItem("spiral_notification")
        );
        setnoti(data);
      } catch (err) {
        toast.error(err.message);
      }
    })();
    return () => {};
  }, [refresh, localStorage.length]);

  useEffect(() => {
    (async () => {
      try {
        mqttEvents.on("notification", () => {
          let data = JSON.parse(localStorage.getItem("spiral_notification"));
          setnoti(data);
        });
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, []);

  // Use this to listen to localStorage changes in your app
  // useEffect(() => {
  //   window.addEventListener("storage", () => {
  //     // When storage changes refetch
  //     refetch();
  //   });

  //   return () => {
  //     // When the component unmounts remove the event listener
  //     window.removeEventListener("storage");
  //   };
  // }, []);
  return (
    <Layout>
      <>
        <div className="bg-white drop-shadow-md rounded-md mt-6 mb-10">
          <div className="px-4">
            <BackBtn />
            <PageTitle title={"Notifications"} caption={""} />
          </div>
          <Rule />
          <div className="px-4 py-2">
            <div className="border-[1px] border-gray-300 h-[90vh] rounded-md p-3">
              {!noti ? (
                <div className="flex items-center justify-center h-full">
                  <div className="">
                    <div className="flex items-center justify-center">
                      <img src={NotiImg} alt="" className="w-[70px] h-[70px]" />
                    </div>
                    <div className="font-Kanit mt-3 font-semibold ">
                      You don't have any notification at the moment.
                    </div>
                  </div>
                </div>
              ) : (
                <table>
                  <thead>
                    <th className="text-start">Date received</th>
                    <th className="text-center">Device Id</th>
                    {/* <th className="text-center">Response</th> */}
                    <th className="text-center">Alert type</th>
                    <th className="text-center">Action</th>
                  </thead>
                  <tbody>
                    {noti.map(
                      (
                        {
                          transactionid,
                          deviceid,
                          status,
                          type,
                          messagetype,
                          datetime,
                          alerttype,
                        },
                        index
                      ) => (
                        <tr
                          key={transactionid}
                          className="cursor-pointer even:bg-gray-50 hover:bg-gray-50"
                        >
                          <td className="text-start">
                            <div className="flex justify-start items-center">
                              <div className="">
                                <FcCalendar size={21} />
                              </div>
                              <div className="pl-2">
                                {datetime
                                  ? moment(datetime).format("lll")
                                  : moment(Date.now()).format("lll")}
                              </div>
                            </div>
                          </td>
                          <td className="text-center">{deviceid}</td>
                          {/* <td className="text-center">{status}</td> */}
                          <td className="text-center">
                            {NotificationFormatter(type) == "Unknown Command"
                              ? alertFormatter(alerttype).title
                              : NotificationFormatter(type)}
                          </td>
                          <td className="text-start flex justify-center items-center">
                            <div
                              // to={`/channel/${deviceid}`}
                              onClick={async () => {
                                try {
                                  let filteredNoti = noti.filter(
                                    (i) => i?.transactionid != transactionid
                                  );
                                  await localStorage.setItem(
                                    "spiral_notification",
                                    JSON.stringify(filteredNoti)
                                  );
                                  setrefresh((prev) => !prev);
                                  // setnoti(filteredNoti);
                                  navigate(`/channel/${deviceid}`);
                                } catch (err) {
                                  toast.error(err.message);
                                }
                              }}
                              className="border-[1px] text-secondary text-[13px] border-secondary p-1 rounded-md w-[fit-content]"
                            >
                              View details
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        <div className="pb-[50px]"></div>
      </>
    </Layout>
  );
};

export default Notification;

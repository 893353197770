import { v4 as uuidv4 } from "uuid";

export const data = [
  {
    id: `${uuidv4()}`,
    title: "Username",
    tag: "customerid",
  },
  {
    id: `${uuidv4()}`,
    title: "Agent Name",
    tag: "agent",
  },
  {
    id: `${uuidv4()}`,
    title: "User role",
    tag: "role",
  },
  {
    id: `${uuidv4()}`,
    title: "Pole Number.",
    tag: "pole",
  },
  {
    id: `${uuidv4()}`,
    title: "Unique Section.",
    tag: "section",
  },
  {
    id: `${uuidv4()}`,
    title: "Project zone.",
    tag: "zone",
  },
  {
    id: `${uuidv4()}`,
    title: "Date created.",
    tag: "datecreated",
  },
  {
    id: `${uuidv4()}`,
    title: "Meter Number.",
    tag: "meter",
  },
  {
    id: `${uuidv4()}`,
    title: "Connection Balance.",
    tag: "connectionbalance",
  },
  {
    id: `${uuidv4()}`,
    title: "Connection Fee.",
    tag: "connectionfee",
  },
];

import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Layout from "../../components/Layout";
// import TopNav from "../../components/TopNav";
// import TableTop from "../../components/TableTop";
import CustomersTable from "../../components/Customer/Table/CustomersTable";
import { channelController } from "../../controllers/channelController";
// import TopNavTitle from "../../components/TopNav/TopNavTitle";
import { Select, theme } from "antd";
import { columnData } from "./data";
import "./style.css";
import { themeColor as color, themeColor } from "../../constant/color";
import axios from "../../utils/axios";
import { Button } from "../../components/Button";
import AdvancedFitler from "../../components/Customer/AdvancedFilter";

/** AntD Table */
import AntDTable from "../../components/Table/AntdTable";
import { customersColumns } from "../../components/TableColumns";

const Customers = () => {
  const [data, setData] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState("");
  const [columnInput, setColumnInput] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const { Option } = Select;
  const controller = new AbortController();

  useEffect(() => {
    (async () => {
      try {
        // 7654
        // if (!selectedColumn && !columnInput) return null;
        setLoading(true);
        let res = await axios.post("/customer/getallmetercustomers", {
          column: "customerid",
          columnparameter: columnInput,
        });
        let result = channelController(res);
        if (result.type !== "success") {
          toast.error(result.message);
          setLoading(false);
          return;
        }
        setData(result.message.body);
        setLoading(false);
        // 3456;
      } catch (err) {
        toast.error(err.message);
        setLoading(false);
        // 3456
      }
    })();

    return () => {
      controller.abort();
    };
  }, [refresh]);

  const btnStyles = {
    padding: "20px",
  };

  return (
    <Layout>
      <div className="mt-4">
        {modal && (
          <AdvancedFitler
            closeModal={setModal}
            refreshBtn={setRefresh}
            // setColumn={setSelectedColumn}
            // setColumnInput={() => console.log()}
            setData={setData}
          />
        )}
        <div className="mb-[200px]">
          <div className="p-2 mt-5 bg-white shadow-md rounded-md">
            <div className="col-span-12">
              <div className="flex items-center justify-between">
                <div className="col-span-7 w-3/5">
                  <div className="customer-cover">
                    <div className="font-semibold">
                      Details of meter owned by customers
                    </div>
                    <div className=""></div>
                  </div>
                </div>
                <div className="">
                  <div className="flex items-center justify-between">
                    <div className=" ">
                      <Select
                        bordered
                        style={{
                          width: 200,
                          height: 33,
                        }}
                        placeholder={"Select a search field."}
                        onChange={(e) => setSelectedColumn(e)}
                      >
                        {columnData.map((item) => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div
                      className="hidden mt-2"
                      onClick={() => setModal(true)}
                    ></div>
                    <div className="ml-4 flex overflow-hidden md:w-[200px] h-[33px] lg:w-[200px] items-center w-full border-[1px] rounded-sm border-gray-300  w-100">
                      <div className="ml-2 w-100">
                        <input
                          type={"text"}
                          placeholder={"Enter customer name"}
                          // onChange={(e) => setinputfield(e.target.value)}
                          className="placeholder:text-sm block w-100 text-[13px] placeholder:font-normal outline-none border-[0px] "
                        />
                      </div>
                    </div>
                    <div className="hidden">
                      <div className=" flex items-center justify-between rounded-sm w-full">
                        <input
                          type={"text"}
                          placeholder={`Enter username.`}
                          value={columnInput}
                          className=" px-2"
                          onChange={(e) => setColumnInput(e.target.value)}
                        />
                        <div
                          className="column-btn rounded-sm text-center text-white pt-1"
                          style={{ background: themeColor.baseColor }}
                          onClick={() => setRefresh((prev) => !prev)}
                        >
                          {loading ? "Searching..." : "Search"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-span-12 mt-5"
              style={{
                height: "70vh",
              }}
            >
              <CustomersTable data={data} />
              {/* {<AntDTable columns={customersColumns} data={[]} />} */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Customers;

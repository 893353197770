import { createContext, useState, useEffect, useContext, useRef } from "react";
import { toast } from "react-hot-toast";
import * as mqtt from "react-paho-mqtt";
import { getToken } from "../utils/token";
import Events from "events";
import Backdrop from "../components/Backdrop";
import GEN from "../components/MQTTModals/GEN";
import TON from "../components/MQTTModals/TON";
import OFF from "../components/MQTTModals/OFF";
import DIS from "../components/MQTTModals/DIS";
import CON from "../components/MQTTModals/CON";
import LUP from "../components/MQTTModals/LUP";
import ECC from "../components/MQTTModals/ECC";
import ECA from "../components/MQTTModals/ECA";
import SDE from "../components/MQTTModals/SDE";
import SFE from "../components/MQTTModals/SFE";
import RST03 from "../components/MQTTModals/RST03";
import RST04 from "../components/MQTTModals/RST04";
import PSS05 from "../components/MQTTModals/PSS05";
import PSS00 from "../components/MQTTModals/PSS00";
import PSS01 from "../components/MQTTModals/PSS01";
import REP from "../components/MQTTModals/REP";
import APP from "../components/MQTTModals/APP";
import CTP from "../components/MQTTModals/CTP";
import RST11 from "../components/MQTTModals/RST11";
import RST10 from "../components/MQTTModals/RST10";
import RST09 from "../components/MQTTModals/RST09";
import { alertFormatter, filterDouble } from "../helperFuncs";
import AlertModal from "../components/MQTTModals/Alerts/AlertModal";
import useSound from "use-sound";
import soundObj from "../assets/sounds/sound-notification.mp3";
import BEN from "../components/MQTTModals/BEN";
import LEN from "../components/MQTTModals/LEN";
import { v4 } from "uuid";
const MQTTContext = createContext();

export const useMQTT = () => useContext(MQTTContext);

const mqttEvents = new Events();

export const MQTTProvider = ({ children }) => {
  const [client, setClient] = useState("");
  var serverPort = 8083;
  const [topic, setTopic] = useState("");
  const [data, setData] = useState("");
  const [vol, setVolt] = useState([]);
  const [mqttType, setMqttType] = useState(null);
  const [res, setRes] = useState(null);
  const [showingNotification, setshowingNotification] = useState(false);
  const [username, setusername] = useState("");
  const [responseType, setresponseType] = useState("");
  const [responsedata, setresponsedata] = useState("");
  const [modal, setmodal] = useState(true);
  const noti = useRef();
  useEffect(() => {
    // _init();
    return () => {};
  }, []);

  useEffect(() => {
    noti.current = false;
    // console.log(noti.current, "noti current");
  }, []);

  const _init = async () => {
    try {
      let token = await getToken("spiral_token");
      var user = await getToken("spiral_username");
      setusername(user);

      // process.env.REACT_APP_MQTT_BROKER,
      // Number(process.env.REACT_APP_MQTT_PORT),

      let c = mqtt.connect(
        "broker.emqx.io",
        8083,
        token,
        _onConnectionLost,
        _onMessageArrived
      ); // mqtt.connect(host, port, clientId, _onConnectionLost, _onMessageArrived)

      c.connect({
        onSuccess: () => {
          c.subscribe("up");
          c.subscribe(user);
          c.subscribe("23456789AB000");
          // c.subscribe("r_0123456789AB000");
          setTopic((prev) => "mqtt_" + user);
          c.subscribe(`mqtt_${user}`);
          console.log("connected successfully");
        },
        // userName: process.env.REACT_APP_MQTT_USERNAME,
        // password: process.env.REACT_APP_MQTT_PASSWORD,
        // useSSL: true,
        keepAliveInterval: 60,
        timeout: 72000,
        // reconnect: true,
      });
      setClient(c);
    } catch (err) {
      console.log(err.message, "///");
    }
  };

  const getUniqueListBy = (arr, key) => {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  };

  var _onMessageArrived = async (message) => {
    try {
      let user = await getToken("spiral_username");
      if (!user) return null;
      let { topic, payloadString } = message;
      mqttEvents.emit("message", message);
      console.log(message.payloadString, "///payloadString");
      if (topic == `mqtt_${user}`) {
        let messagePayload = JSON.parse(message.payloadString);
        if (noti.current == true) {
          let res = [];
          let toggle = true;
          mqttEvents.emit("notification", !toggle);
          let localNotification = await JSON.parse(
            localStorage.getItem("spiral_notification")
          );
          if (!localNotification || localNotification.length < 1) {
            res.push(messagePayload);
            await localStorage.setItem(
              "spiral_notification",
              JSON.stringify(filterDouble(res, "transactionid"))
            );
          } else {
            res.push(...localNotification, messagePayload);
            await localStorage.setItem(
              "spiral_notification",
              JSON.stringify(filterDouble(res, "transactionid"))
            );
          }
          // for all other commands that are not GEN, TON, or OFF
          // show notification
          if (
            !JSON.parse(message.payloadString).type == "GEN" ||
            !JSON.parse(message.payloadString).type == "TON" ||
            !JSON.parse(message.payloadString).type == "OFF"
          ) {
            toast.success("You have new notification");
          }
        } else if (!noti.current) {
          const { type } = JSON.parse(payloadString);
          noti.current = true;
          setresponseType(type);
          setresponsedata(JSON.parse(payloadString));
          setmodal(true);
        }
      } else if (topic == user) {
        let messagePayload = JSON.parse(message.payloadString);
        if (noti.current) {
          let res = [];
          console.log("Already showing alert ");
          // get notification
          mqttEvents.emit("notification", messagePayload);
          let localNotification = await JSON.parse(
            localStorage.getItem("spiral_notification")
          );
          // assign unique id

          let obj = { ...messagePayload, transactionid: v4() };
          // check if the notification is empty
          if (!localNotification || localNotification.length < 1) {
            res.push(obj);
            await localStorage.setItem(
              "spiral_notification",
              JSON.stringify(res)
            );
            toast.success("You have new notification");
          } else {
            // spread the old notification and add the nw one
            res.push(...localNotification, obj);
            await localStorage.setItem(
              "spiral_notification",
              JSON.stringify(res)
            );
            toast.success("You have new notification");
          }
        } else if (!noti.current) {
          console.log("Not showing alert");
          setresponsedata(messagePayload);
          setresponseType("ALERT");
          noti.current = true;
          setmodal(true);
        }
      }
    } catch (err) {
      toast.error(err.message);
      console.log(err.message);
    }
  };

  const handleNoti = () => (noti.current = false);

  const _onConnectionLost = (responseObject) => {
    if (responseObject.errorCode !== 0) {
      console.log("onConnectionLost: " + responseObject.errorMessage);
    }
  };

  const _onSubscribe = () => {
    client.connect({
      onSuccess: () => {
        // console.log(client, "inner client");
        console.log("Connected successfully");
      },
    });
  };

  return (
    <MQTTContext.Provider
      value={{
        mqttEvents,
        onConnectionLost: _onConnectionLost,
        client: client,
      }}
    >
      {/* {responseType == "GEN" && (
        <GEN
          handleNoti={handleNoti}
          responsedata={responsedata}
          setresponsedata={setresponsedata}
          responseType={responseType}
          setresponseType={setresponseType}
          restype={responseType}
          open={modal}
          setopen={setmodal}
          showingNotification={showingNotification}
          setshowingNotification={setshowingNotification}
        />
      )} */}

      {responseType == "ALERT" && (
        <AlertModal
          handleNoti={handleNoti}
          responsedata={responsedata}
          setresponsedata={setresponsedata}
          responseType={responseType}
          setresponseType={setresponseType}
          restype={responseType}
          open={modal}
          setopen={setmodal}
          showingNotification={showingNotification}
          setshowingNotification={setshowingNotification}
        />
      )}

      {responseType == "TON" && responsedata?.status != "SUCCESS" && (
        <TON
          handleNoti={handleNoti}
          responsedata={responsedata}
          setresponsedata={setresponsedata}
          responseType={responseType}
          setresponseType={setresponseType}
          restype={responseType}
          open={modal}
          setopen={setmodal}
          showingNotification={showingNotification}
          setshowingNotification={setshowingNotification}
        />
      )}

      {responseType == "OFF" && responsedata?.status != "SUCCESS" && (
        <OFF
          handleNoti={handleNoti}
          responsedata={responsedata}
          setresponsedata={setresponsedata}
          responseType={responseType}
          setresponseType={setresponseType}
          restype={responseType}
          open={modal}
          setopen={setmodal}
          showingNotification={showingNotification}
          setshowingNotification={setshowingNotification}
        />
      )}

      {responseType == "DIS" && (
        <DIS
          handleNoti={handleNoti}
          responsedata={responsedata}
          setresponsedata={setresponsedata}
          responseType={responseType}
          setresponseType={setresponseType}
          restype={responseType}
          open={modal}
          setopen={setmodal}
          showingNotification={showingNotification}
          setshowingNotification={setshowingNotification}
        />
      )}

      {responseType == "CON" && (
        <CON
          handleNoti={handleNoti}
          responsedata={responsedata}
          setresponsedata={setresponsedata}
          responseType={responseType}
          setresponseType={setresponseType}
          restype={responseType}
          open={modal}
          setopen={setmodal}
          showingNotification={showingNotification}
          setshowingNotification={setshowingNotification}
        />
      )}

      {responseType == "LUP" && (
        <LUP
          handleNoti={handleNoti}
          responsedata={responsedata}
          setresponsedata={setresponsedata}
          responseType={responseType}
          setresponseType={setresponseType}
          restype={responseType}
          open={modal}
          setopen={setmodal}
          showingNotification={showingNotification}
          setshowingNotification={setshowingNotification}
        />
      )}

      {responseType == "ECC" && (
        <ECC
          handleNoti={handleNoti}
          responsedata={responsedata}
          setresponsedata={setresponsedata}
          responseType={responseType}
          setresponseType={setresponseType}
          restype={responseType}
          open={modal}
          setopen={setmodal}
          showingNotification={showingNotification}
          setshowingNotification={setshowingNotification}
        />
      )}

      {responseType == "ECA" && (
        <ECA
          handleNoti={handleNoti}
          responsedata={responsedata}
          setresponsedata={setresponsedata}
          responseType={responseType}
          setresponseType={setresponseType}
          restype={responseType}
          open={modal}
          setopen={setmodal}
          showingNotification={showingNotification}
          setshowingNotification={setshowingNotification}
        />
      )}

      {responseType == "SDE" && (
        <SDE
          handleNoti={handleNoti}
          responsedata={responsedata}
          setresponsedata={setresponsedata}
          responseType={responseType}
          setresponseType={setresponseType}
          restype={responseType}
          open={modal}
          setopen={setmodal}
          showingNotification={showingNotification}
          setshowingNotification={setshowingNotification}
        />
      )}
      {responseType == "SFE" && (
        <SFE
          handleNoti={handleNoti}
          responsedata={responsedata}
          setresponsedata={setresponsedata}
          responseType={responseType}
          setresponseType={setresponseType}
          restype={responseType}
          open={modal}
          setopen={setmodal}
          showingNotification={showingNotification}
          setshowingNotification={setshowingNotification}
        />
      )}

      {responseType == "RST03" && (
        <RST03
          handleNoti={handleNoti}
          responsedata={responsedata}
          setresponsedata={setresponsedata}
          responseType={responseType}
          setresponseType={setresponseType}
          restype={responseType}
          open={modal}
          setopen={setmodal}
          showingNotification={showingNotification}
          setshowingNotification={setshowingNotification}
        />
      )}

      {responseType == "RST04" && (
        <RST04
          handleNoti={handleNoti}
          responsedata={responsedata}
          setresponsedata={setresponsedata}
          responseType={responseType}
          setresponseType={setresponseType}
          restype={responseType}
          open={modal}
          setopen={setmodal}
          showingNotification={showingNotification}
          setshowingNotification={setshowingNotification}
        />
      )}

      {responseType == "PSS05" && (
        <PSS05
          handleNoti={handleNoti}
          responsedata={responsedata}
          setresponsedata={setresponsedata}
          responseType={responseType}
          setresponseType={setresponseType}
          restype={responseType}
          open={modal}
          setopen={setmodal}
          showingNotification={showingNotification}
          setshowingNotification={setshowingNotification}
        />
      )}

      {responseType == "PSS00" && (
        <PSS00
          handleNoti={handleNoti}
          responsedata={responsedata}
          setresponsedata={setresponsedata}
          responseType={responseType}
          setresponseType={setresponseType}
          restype={responseType}
          open={modal}
          setopen={setmodal}
          showingNotification={showingNotification}
          setshowingNotification={setshowingNotification}
        />
      )}

      {responseType == "PSS01" && (
        <PSS01
          handleNoti={handleNoti}
          responsedata={responsedata}
          setresponsedata={setresponsedata}
          responseType={responseType}
          setresponseType={setresponseType}
          restype={responseType}
          open={modal}
          setopen={setmodal}
          showingNotification={showingNotification}
          setshowingNotification={setshowingNotification}
        />
      )}

      {responseType == "REP" && (
        <REP
          handleNoti={handleNoti}
          responsedata={responsedata}
          setresponsedata={setresponsedata}
          responseType={responseType}
          setresponseType={setresponseType}
          restype={responseType}
          open={modal}
          setopen={setmodal}
          showingNotification={showingNotification}
          setshowingNotification={setshowingNotification}
        />
      )}

      {responseType == "APP" && (
        <APP
          handleNoti={handleNoti}
          responsedata={responsedata}
          setresponsedata={setresponsedata}
          responseType={responseType}
          setresponseType={setresponseType}
          restype={responseType}
          open={modal}
          setopen={setmodal}
          showingNotification={showingNotification}
          setshowingNotification={setshowingNotification}
        />
      )}

      {responseType == "CTP" && (
        <CTP
          handleNoti={handleNoti}
          responsedata={responsedata}
          setresponsedata={setresponsedata}
          responseType={responseType}
          setresponseType={setresponseType}
          restype={responseType}
          open={modal}
          setopen={setmodal}
          showingNotification={showingNotification}
          setshowingNotification={setshowingNotification}
        />
      )}

      {responseType == "RST09" && (
        <RST09
          handleNoti={handleNoti}
          responsedata={responsedata}
          setresponsedata={setresponsedata}
          responseType={responseType}
          setresponseType={setresponseType}
          restype={responseType}
          open={modal}
          setopen={setmodal}
          showingNotification={showingNotification}
          setshowingNotification={setshowingNotification}
        />
      )}

      {responseType == "RST10" && (
        <RST10
          handleNoti={handleNoti}
          responsedata={responsedata}
          setresponsedata={setresponsedata}
          responseType={responseType}
          setresponseType={setresponseType}
          restype={responseType}
          open={modal}
          setopen={setmodal}
          showingNotification={showingNotification}
          setshowingNotification={setshowingNotification}
        />
      )}

      {responseType == "RST11" && (
        <RST11
          handleNoti={handleNoti}
          responsedata={responsedata}
          setresponsedata={setresponsedata}
          responseType={responseType}
          setresponseType={setresponseType}
          restype={responseType}
          open={modal}
          setopen={setmodal}
          showingNotification={showingNotification}
          setshowingNotification={setshowingNotification}
        />
      )}

      {responseType == "BEN" && (
        <BEN
          handleNoti={handleNoti}
          responsedata={responsedata}
          setresponsedata={setresponsedata}
          responseType={responseType}
          setresponseType={setresponseType}
          restype={responseType}
          open={modal}
          setopen={setmodal}
          showingNotification={showingNotification}
          setshowingNotification={setshowingNotification}
        />
      )}

      {responseType == "LEN" && (
        <LEN
          handleNoti={handleNoti}
          responsedata={responsedata}
          setresponsedata={setresponsedata}
          responseType={responseType}
          setresponseType={setresponseType}
          restype={responseType}
          open={modal}
          setopen={setmodal}
          showingNotification={showingNotification}
          setshowingNotification={setshowingNotification}
        />
      )}

      {children}
    </MQTTContext.Provider>
  );
};

import { FaMapMarkedAlt } from "react-icons/fa";
import { themeColor as color, themeColor } from "../../constant/color";
import "./styles.css";

const TopCard = ({
  title,
  value,
  fontSize,
  caption,
  fontSizeCaption,
  pt,
  borderColor,
  Icon,
}) => {
  return (
    <div
      className="p-3 flex items-center border-b-[1px] border-secondary  justify-between bg-white rounded-md drop-shadow-md topcard-cover"
      style={{}}
    >
      <div className="topcard-left__cover">
        <div className="text-gray-600 font-semibold text-[17px]">{title}</div>
        <div className="topcard-value text-[24px] pt-1" style={{}}>
          {value}
        </div>
        <div
          className="mt-2"
          style={{
            fontSize: "12px",
            fontWeight: "400",
            color: color.baseColor,
            fontStyle: "italic",
          }}
        >
          {caption}
        </div>
      </div>
      <div
        className="topcard-right__cover bg-secondary rounded-sm flex items-center justify-center"
        style={{}}
      >
        {Icon || <FaMapMarkedAlt size={20} color={"#fff"} />}
      </div>
    </div>
  );
};

export default TopCard;

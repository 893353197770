// import {
//   ColumnDirective,
//   ColumnsDirective,
//   Filter,
//   GridComponent,
//   Group,
//   Inject,
//   Page,
//   Search,
//   Toolbar,
//   ExcelExport,
//   Sort,
// } from "@syncfusion/ej2-react-grids";
import moment from "moment";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { useState, useContext } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { themeColor as color } from "../../../constant/color";
import { Button } from "../../Button";

const DCURecordTable = () => {
  const [selectedRow, setSelectedRow] = useState(null);
  let grid = useRef();
  let num = 1;

  const Sample = (item, { selected = selectedRow }) => {
    return (
      <Link
        to="/dashboard/systemadmin"
        className="w-100 d-flex align-items-center justify-content-center no-underline"
      >
        <div
          style={{
            position: "relative",
            cursor: "pointer",
            width: "100px",
          }}
          className="text-center "
          onClick={() => setSelectedRow(item?.id)}
        >
          <Button
            border={`1px solid ${color.baseColor}`}
            text={"View"}
            color={color.baseColor}
            fontSize={"11px"}
            fontWeight={"400"}
            height={"35px"}
            bg={color.whiteColor}
          />
        </div>
      </Link>
    );
  };

  const handleExport = (args) => {
    if (grid && args.item.id === "grid_excelexport") {
      const excelExportProperties = {
        dataSource: [],
      };
      grid.current.excelExport(excelExportProperties);
    }
  };
  return (
    <div>
      <div
        style={{
          borderTop: "1px solid #eee",
        }}
      >
        <div className="table-container" style={{}}>
          {/* <GridComponent
            ref={(g) => (grid.current = g)}
            id="grid"
            style={{
              display: "block",
            }}
            allowPaging={true}
            allowSorting={true}
            dataSource={[]}
            pageSettings={{
              pageSize: 15,
            }}
            toolbarClick={handleExport}
            allowExcelExport={true}
            toolbar={["Search", "ExcelExport"]}
            width={"100%"}
            height={400}
            // searchSettings={searchOptions}
          >
            <ColumnsDirective>
              <ColumnDirective
                field="num"
                width="50"
                textAlign="Center"
                headerText="num"
                headerTextAlign="Center"
                headerTemplate={() => {
                  return (
                    <div
                      className="st-header"
                      style={{
                        color: color.darkColor,
                        fontSize: "12px",
                        fontWeight: "300",
                      }}
                    >
                      #
                    </div>
                  );
                }}
              />
              <ColumnDirective
                field="dcuid"
                width="100"
                textAlign="Center"
                headerText="DCU ID"
                headerTextAlign="Center"
                headerTemplate={() => {
                  return (
                    <div
                      className="st-header"
                      style={{
                        color: color.darkColor,
                        fontSize: "12px",
                        fontWeight: "300",
                      }}
                    >
                      DCU Id
                    </div>
                  );
                }}
              />
              <ColumnDirective
                field="dcualias"
                width="100"
                textAlign="Center"
                headerText="DCU alias"
                headerTextAlign="Center"
                headerTemplate={() => {
                  return (
                    <div
                      className="st-header"
                      style={{
                        color: color.darkColor,
                        fontSize: "12px",
                        fontWeight: "300",
                      }}
                    >
                      DCU Alias
                    </div>
                  );
                }}
                template={(item) => {
                  return (
                    <div
                      style={{
                        color: color.baseColor,
                        fontSize: "13px",
                      }}
                      className="text-center"
                    >
                      {item?.dcualias}
                    </div>
                  );
                }}
              />
              <ColumnDirective
                field="dcucreator"
                width="100"
                textAlign="Center"
                headerText="DCU creator"
                headerTextAlign="Center"
                headerTemplate={() => {
                  return (
                    <div
                      className="st-header"
                      style={{
                        color: color.darkColor,
                        fontWeight: "300",

                        fontSize: "12px",
                      }}
                    >
                      DCU creator
                    </div>
                  );
                }}
              />
              <ColumnDirective
                field="datecreated"
                width="100"
                textAlign="Center"
                headerText="Date created"
                headerTextAlign="Center"
                headerTemplate={() => {
                  return (
                    <div
                      className="st-header"
                      style={{
                        color: color.darkColor,
                        fontSize: "12px",
                        fontWeight: "300",
                      }}
                    >
                      Date created
                    </div>
                  );
                }}
                template={(item) => {
                  return (
                    <div
                      style={{
                        fontSize: "13px",
                      }}
                      className="text-center"
                      onClick={() => console.log(selectedRow)}
                    >
                      {moment(item?.date).format("DD-MM-YYYY HH:mm")}
                    </div>
                  );
                }}
              />
            </ColumnsDirective>
            <Inject
              services={[
                Page,
                Sort,
                Filter,
                Group,
                Toolbar,
                Search,
                ExcelExport,
              ]}
            />
          </GridComponent> */}
        </div>
      </div>
    </div>
  );
};

export default DCURecordTable;

import { BiUserPlus } from "react-icons/bi";
import "./styles.css";
import AddSiteImg from "../../../assets/svg/siteimg.svg";
import { useEffect, useState } from "react";
import { Select } from "antd";
import "antd/dist/antd.css";

import { Button } from "../../Button";
import axios from "../../../utils/axios";
import toast from "react-hot-toast";
import Rule from "../../Rule/Index";

const AddDropdown = ({ dropdowns, refresh, closeModal }) => {
  const [loading, setLoading] = useState(false);
  const [dropdownName, setDropdownName] = useState(null);
  const [dropdownOptions, setDropdownOptions] = useState([]);

  const changeDropdown = (val) => {
    setDropdownName(val);
    let dropdownItems = dropdowns.find((e) => e.name == val).items.split(",");
    setDropdownOptions(dropdownItems.map((i) => i.trim()));
  };

  const submitDropdown = () => {
    if (!dropdownName) return toast("Please select a dropdown");
    if (dropdownOptions.length < 1)
      return toast("Please enter some dropdown options");
    setLoading(true);
    axios
      .post("/settings/setdropdown", {
        comboname: dropdownName,
        combolist: dropdownOptions.join(", "),
      })
      .then((response) => {
        setLoading(false);
        toast.success("Dropdown has been set");
        refresh();
        closeModal();
      })
      .catch((err) => {
        setLoading(false);
        toast(err.message);
      });
  };

  const { Option } = Select;
  return (
    <div className="backdrop">
      <div className="w-[95%] md:w-[450px] rounded-md shadow-m bg-white  px-3 py-4 animate__animated animate__fadeInUp">
        <div className="flex items-center justify-between">
          <div className="font-semibold mb-1 ">Dropdown List</div>
          <div
            className="text-secondary cursor-pointer"
            onClick={() => closeModal(false)}
          >
            Close
          </div>
        </div>

        <div className="">
          Fill in the fields below to create a dropdown (options) list
        </div>
        <Rule />
        <div className="input-entry w-full my-3">
          <div>
            Enter a name for your new list. This name will be used to fetch all
            the options you have created.
          </div>
          <Select
            style={{ width: "100%", fontSize: "13px" }}
            allowClear
            size="large"
            placeholder="Select dropdown name."
            value={dropdownName}
            onChange={(val) => changeDropdown(val)}
          >
            {dropdowns.map((dropdown) => (
              <Option value={dropdown.name} key={dropdown.name}>
                {dropdown.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="input-entry w-full my-3">
          <div>
            Enter comma, separated values of the options you intend to create.
          </div>
          {/* <input
            type="text"
            className="w-full px-2 input-entry h-[40px] border-[1px] border-gray-400 rounded-1"
            placeholder={"Enter options."}
            onChange={e => setDropdownOptions(e.target.value)}
            value={dropdownOptions}
          /> */}
          <Select
            mode="tags"
            style={{ width: "100%", fontSize: "14px" }}
            value={dropdownOptions}
            size="large"
            onChange={(val) => setDropdownOptions(val)}
            tokenSeparators={[","]}
          ></Select>
        </div>
        <div className="mt-4">
          {/* <div className="addrole-btn">
            <Button
              text="Cancel"
              height={"40px"}
             
            />
          </div> */}
          <div className="w-full">
            <Button
              text="Done"
              height={"40px"}
              status={loading}
              onClick={() => submitDropdown()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDropdown;

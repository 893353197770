import axios from "../../../../utils/axios";
import moment from "moment";
import { useState } from "react";
import { toast } from "react-hot-toast";
import Backdrop from "../../../Backdrop";
import CloseButton from "../../../CloseButton";
import Rule from "../../../Rule/Index";
import { Button } from "../../../Button";

const ViewProjectMeter = ({ open, setopen, details, refreshBtn }) => {
  const [loading, setloading] = useState(false);

  const handleDelete = async (item) => {
    try {
      setloading(true);
      let res = await axios.post("/submeter/paymentplan/deletepaymentplan", {
        planalias: item,
      });
      refreshBtn((prev) => !prev);
      toast.success("Plan deleted successfully.");
      setloading(false);
      setopen(false);
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.response);
        setloading(false);
        return;
      }
      toast.error(err.message);
    }
  };
  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[550px] bg-white drop-shadow-md rounded-sm p-3">
          <div className="flex items-start  w-full justify-between">
            <div className="">
              <div className="font-Kanit font-semibold text-[17px]">
                Meter details
              </div>
              <div className="hidden w-[80%] text-gray-700 font-light">
                Send a request to user to grant him/her access to your s to the
                channel <br />
              </div>
            </div>
            <div className=" w-[200px] flex justify-end">
              <CloseButton closeBtn={setopen} />
            </div>
          </div>
          <Rule />
          <div className="flex items-center justify-between my-4">
            <div className="font-semibold text-gray-800">
              Update Load Control Policy
            </div>
            <div className="flex items-center justify-end">
              <div className="w-[130px] mr-4">
                <Button
                  text={"Set Load Policy"}
                  bg={"white"}
                  height={"35px"}
                  border="1px solid #6b48ff"
                  loaderColor={"tomato"}
                  // onClick={() => handleDelete(details?.planalias)}
                  loading={loading}
                  color={"#6b48ff"}
                />
              </div>
              <div className="w-[130px]">
                <Button
                  text={"Get load Policy"}
                  height={"35px"}
                  // onClick={() => handleDelete(details?.planalias)}
                  loading={loading}
                />
              </div>
            </div>
          </div>
          <Rule />
          <div className="pb-10">
            <div className="flex items-center justify-between mt-2">
              <div className="text-gray-700">Meter number:</div>
              <div className="font-semibold text-gray-800">
                {details?.number}
              </div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="text-gray-700">Meter type :</div>
              <div className="font-semibold text-gray-800">{details?.type}</div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="text-gray-700">Phase:</div>
              <div className="font-semibold text-gray-800">
                {details?.phases} phase
              </div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="text-gray-700">Created by:</div>
              <div className="font-semibold text-gray-800">
                {details?.registrar} phase
                {/* {moment(details?.datecreated).format("lll")} */}
              </div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="text-gray-700">Power status:</div>
              <div className="font-semibold text-gray-800">
                {details?.powerstatus}
                {/* {moment(details?.datecreated).format("lll")} */}
              </div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="text-gray-700">Load limit:</div>
              <div className="font-semibold text-gray-800">
                {details?.loadlimit} phase
                {/* {moment(details?.datecreated).format("lll")} */}
              </div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="text-gray-700">Load limit:</div>
              <div className="font-semibold text-gray-800">
                {moment(details?.dateregistered).format("lll")}
              </div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="text-gray-700">Date decommissioned:</div>
              <div className="font-semibold text-gray-800">
                {details?.datedecommissioned
                  ? moment(details?.datedecommissioned).format("lll")
                  : "-"}
              </div>
            </div>
          </div>

          {/* <div className="flex items-center justify-between mt-2">
            <div className="text-gray-700">:</div>
            <div className="font-semibold text-gray-800">
              {details?.energyorpower}units
            </div>
          </div> */}
        </div>
      </div>
    </Backdrop>
  );
};

export default ViewProjectMeter;

import ActiveDCUImg from "../../../assets/svg/activedcuimg.svg";
import RevenueImg from "../../../assets/svg/revenueimg.svg";
import CustomerImg from "../../../assets/svg/customerimg.svg";
import ProjectImg from "../../../assets/svg/projectimg.svg";
import "./styles.css";
import { themeColor as color } from "../../../constant/color";

const StatsCard = ({
  meters,
  meterTxt,
  projectManagers,
  customers,
  customerManagers,
  revenue,
}) => {
  return (
    <div className="w-full pt-2 py-3 px-2">
      <div className="font-bold">Project site stats</div>
      <div className="grid grid-cols-2 gap-4 mt-2">
        <div className="my-2">
          <div
            className="stats-cover p-3 flex items-center justify-center"
            style={{
              background: "#fff8ef",
            }}
          >
            <div className="stats-img_cover mx-3">
              <img
                alt={"img"}
                src={ActiveDCUImg}
                width={"100%"}
                height={"100%"}
              />
            </div>
            <div className="stats-details__cover px-2">
              <div className="stats-value text-center">{meters}</div>
              <div className="stats-caption" style={{}}>
                {meterTxt || "Active Meters"}
              </div>
            </div>
          </div>
        </div>
        <div className="my-2">
          <div
            className="stats-cover p-3 flex items-center justify-center"
            style={{
              background: "#FFF2FE",
              border: "1px solid #D93BCA",
            }}
          >
            <div className="stats-img_cover mx-3">
              <img
                alt={"img"}
                src={ProjectImg}
                width={"100%"}
                height={"100%"}
              />
            </div>
            <div className="stats-details__cover px-2">
              <div className="stats-value text-center">
                {customerManagers || customerManagers == 0
                  ? customerManagers
                  : projectManagers}
              </div>
              <div className="stats-caption" style={{}}>
                {customerManagers || customerManagers == 0
                  ? "Customer Reps..."
                  : "Project Managers"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 mt-2">
        <div className="my-2">
          <div
            className="stats-cover p-3 flex items-center justify-center"
            style={{
              background: "#F8F8F8",
              border: "1px solid #000000",
            }}
          >
            <div className="stats-img_cover mx-3">
              <img
                alt={"img"}
                src={CustomerImg}
                width={"100%"}
                height={"100%"}
              />
            </div>
            <div className="stats-details__cover px-2">
              <div className="stats-value text-center">{customers}</div>
              <div className="stats-caption" style={{}}>
                Customers Mng...
              </div>
            </div>
          </div>
        </div>
        <div className="my-2">
          <div
            className="stats-cover p-3 flex items-center justify-center"
            style={{
              background: "#F3FBFF",
              border: "1px solid #2CA3E5",
            }}
          >
            <div className="stats-img_cover mx-3">
              <img
                alt={"img"}
                src={RevenueImg}
                width={"100%"}
                height={"100%"}
              />
            </div>
            <div className="stats-details__cover px-2">
              <div className="stats-value text-center">${revenue}</div>
              <div className="stats-caption" style={{}}>
                Revenue
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsCard;

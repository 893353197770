import { GiTakeMyMoney, GiPayMoney, GiReceiveMoney } from "react-icons/gi";
import { themeColor } from "../../../../constant/color";

const WalletCard = ({ value, title, type }) => {
  return (
    <div className="p-3 border rounded-md border-r-[1px]">
      <div className="flex items-center justify-between mt-3">
        <div className="">
          <div className="text-gray-600 font-light">{title}</div>
          <div className="text-gray-700 font-semibold font-kanit text-[24px] pt-1">
            ₦
            {value.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        </div>
        <div className="">
          {type == "balance" ? (
            <GiTakeMyMoney size={30} color={themeColor.secondary} />
          ) : type == "spent" ? (
            <GiReceiveMoney size={30} color={themeColor.secondary} />
          ) : (
            <GiPayMoney size={30} color={themeColor.secondary} />
          )}
        </div>
      </div>
    </div>
  );
};

export default WalletCard;

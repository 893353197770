import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import { useState, useEffect, useRef } from "react";

const MeterLineChartRecords = () => {
  const chartRef = useRef();
  const [gradientValue, setGradientValue] = useState("");
  useEffect(() => {
    const ctx = chartRef.current.canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, "#84d1fea7");
    gradient.addColorStop(0.7, "#ffffff00");
    setGradientValue((prev) => gradient);
  }, []);
  return (
    <Line
      ref={chartRef}
      data={{
        labels: Array.from(Array(10)).map(
          (i) => Math.floor(Math.random() * 50) + 1
        ), // x axis
        datasets: [
          {
            type: "bar",
            label: "",
            fill: true,
            backgroundColor: "#0183d0",
            data: Array.from(Array(10)).map(
              (i) => Math.floor(Math.random() * 50) + 1
            ),
            borderColor: "#0183d0",
            radius: 2,
            barThickness: 30,
            borderWidth: 1,
            pointBorderWidth: 1,
            tension: 0.4,
          },
        ],
      }}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            position: "bottom",
            display: false,
          },
          title: {
            display: false,
          },
          tooltip: {
            // callbacks: {
            //   label: function (tooltipItem, data) {
            //     return numFormtter(parseInt(tooltipItem.parsed.y));
            //   },
            // },
          },
        },
        scales: {
          x: {
            display: false,
            grid: {
              display: false,
            },
          },
          y: {
            display: true,
            grid: {
              display: true,
              borderDash: [10],
              borderDashOffset: 20,
              borderWidth: 0,
              color: "#eee",
            },
          },
        },
      }}
    />
  );
};

export default MeterLineChartRecords;

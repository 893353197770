import discountImg from "../../../assets/svg/customerimg.svg";

export const controlfields = {
  vending: {
    title: "Vending",
    meta: "Minimum vending value:",
    url: "/settings/getminimumvending",
    img: "",
    format: (data) => data.minimumvend,
  },
  discount: {
    title: "Agent discount",
    meta: "Sales agent discount:",
    url: "/settings/getsalesagentdiscount",
    img: discountImg,
    format: (data) => data.discount,
  },
  load: {
    title: "Load control",
    meta: "Meter load control",
    url: "/settings/getzonemeterloadcontrol",
    img: "",
    withMeter: true,
    format: (data) => (data.response ? "YES" : "NO"),
  },
  power: {
    title: "Power control",
    meta: "Meter power control",
    url: "/settings/getzonemeterpowercontrol",
    img: "",
    withMeter: true,
    format: (data) => (data.response ? "ON" : "OFF"),
  },
};

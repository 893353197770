export const userController = (res) => {
  if (res.data.response == "invalid session") {
    return {
      message: "Invalid session",
      type: "error",
    };
  } else if (res.data.response == true) {
    return {
      massage: res.data.response,
      type: "success",
    };
  } else if (res.data.response == false) {
    return {
      massage: "Operation failed. Please try again",
      type: "error",
    };
  } else {
    return {
      type: "success",
      message: res.data,
    };
  }
};

import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import BackBtn from "../../../../components/BackBtn";
import CardTitle from "../../../../components/CardTitle";
import PowerQuality from "../../../../components/Dashboards/PowerQuality";
import Revenue from "../../../../components/Dashboards/Revenue";
import StatsCard from "../../../../components/Dashboards/StatsCard";
import Layout from "../../../../components/Layout";
import MoreBtn from "../../../../components/MoreBtn";
import ProjectManagerCard from "../../../../components/Roles/Card/ProjectManagerCard";
import RolesCard from "../../../../components/Roles/Card/RolesCard";
import TopCard from "../../../../components/TopCard";
import TopNav from "../../../../components/TopNav";
import axios from "../../../../utils/axios";
import toast from "react-hot-toast";
import moment from "moment";
import DateRangePicker from "../../../../components/DateRangePicker";
import { Empty, Select } from "antd";
import TopNavTitle from "../../../../components/TopNav/TopNavTitle";
import Rule from "../../../../components/Rule/Index";
const ProjectSiteDashboard = () => {
  let { sitename } = useParams();
  const controller = new AbortController();
  sitename = decodeURI(sitename);
  const [projectSite, setProjectSite] = useState({});
  const [startDate, setStartDate] = useState(
    moment(Date.now()).subtract("1", "week").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );
  const [refresh, setRefresh] = useState(false);
  const [projectManagers, setProjectManagers] = useState([]);
  const [customerManagers, setCustomerManagers] = useState([]);
  const [meters, setMeters] = useState(0);
  const [customers, setCustomers] = useState(0);
  const [paymentHistory, setPaymetHistory] = useState([]);
  const [revenue, setRevenue] = useState(0);

  useEffect(() => {
    try {
      axios
        .post("/sitemanagement/getspecificsitebysitename", { sitename })
        .then((response) => {
          setProjectSite(response.data);
        });
      axios
        .post("/roles/getallusersbyzoneandrole", {
          role: "Project Manager",
          projectzone: sitename,
        })
        .then((response) => {
          setProjectManagers(response.data.body);
        });
      axios
        .post("/roles/getallusersbyzoneandrole", {
          role: "Customer Representative",
          projectzone: sitename,
        })
        .then((response) => {
          setCustomerManagers(response.data.body);
        });
      axios
        .post("/roles/getallusersbyzoneandrole", {
          role: "Customers Representative",
          projectzone: sitename,
        })
        .then((response) => {
          setCustomers(response.data.meta.count);
        });
      axios
        .post("/meter/getallmetersinzone", { zone: sitename })
        .then((response) => {
          setMeters(response.data.meta.count);
        });
      return () => {
        controller.abort();
      };
    } catch (err) {
      toast(err.message, "Meter error");
    }
  }, []);

  useEffect(() => {
    axios
      .post("/salesanalytics/getsaleshistoryperzone", {
        projectzone: sitename,
        assettype: "",
        startdate: startDate,
        enddate: endDate,
        paymentoperation: "Vending",
        limit: 30,
        offset: 0,
      })
      .then((response) => {
        // console.log(response);
        setPaymetHistory(response.data.body);
        setRevenue(response.data.body.reduce((p, c) => (p += c.amount), 0));
      })
      .catch((err) => toast(err.message, "Sales per zone"));
    return () => {
      controller.abort();
    };
  }, [refresh]);
  useEffect(() => {
    axios
      .post("/salesanalytics/getsaleshistoryperzone", {
        projectzone: sitename,
        assettype: "",
        startdate: startDate,
        enddate: endDate,
        paymentoperation: "Vending",
        limit: 30,
        offset: 0,
      })
      .then((response) => {
        setPaymetHistory(response.data.body);
        setRevenue(response.data.body.reduce((p, c) => (p += c.amount), 0));
      })
      .catch((err) => toast(err.message, "Sales per zone"));
    return () => {
      controller.abort();
    };
  }, [refresh]);

  return (
    <Layout>
      <div className="">
        <div className="container-fluid mt-2">
          <BackBtn text={"Go back"} />
          <div className="hidden mt-3 justify-content-between">
            <div className="col-sm-12 col-md-7 col-lg-4 col-xl-4 col-xxl-4  my-1">
              <TopCard
                title={"Project Site"}
                value={sitename}
                fontSize={"17px"}
                caption={projectSite.sitetype}
              />
            </div>
            <div className="col-sm-12 hidden col-md-5 col-lg-4 col-xl-4 col-xxl-4 my-1">
              <DateRangePicker
                setEndDate={setEndDate}
                setStartDate={setStartDate}
                refreshBtn={setRefresh}
              />
            </div>
          </div>
          {/* stats and Project site */}
          <div className="grid grid-cols-2 gap-4">
            <div className="col-span-1 p-2 mt-2 w-full bg-white shadow-md rounded-md">
              <div className="font-semibold">Project site details</div>
              <div className="font-bold text-[30px] mt-1">{sitename}</div>
              <Rule />
              <div className="py-2 flex items-center justify-between">
                <div className="">Date created:</div>
                <div className="font-semibold">
                  {projectSite ? projectSite?.sitetype : ""}
                </div>
              </div>
              <div className="py-2 flex items-center justify-between">
                <div className="">GPS:</div>
                <div className="font-semibold">
                  {projectSite ? projectSite?.gps : ""}
                </div>
              </div>
              <div className="py-2 flex items-center justify-between">
                <div className="">Date created:</div>
                <div className="font-semibold">
                  {projectSite
                    ? moment(projectSite?.datecreated).format("lll")
                    : ""}
                </div>
              </div>
              {/* <StatsCard
                meters={meters}
                meterTxt="Total meters"
                projectManagers={projectManagers.length}
                customers={customers}
                revenue={revenue}
              /> */}
            </div>
            <div className="col-span-1 p-2 mt-2 w-full bg-white shadow-md rounded-md">
              <StatsCard
                meters={meters}
                meterTxt="Total meters"
                projectManagers={projectManagers.length}
                customers={customers}
                revenue={revenue}
              />
            </div>
          </div>
          {/* Revenue Charts */}
          <div className="row mt-4 w-full bg-white rounded-md shadow-sm p-2">
            <Revenue paymentHistory={paymentHistory} revenue={revenue} />
          </div>
          {/* Roles */}
          <div className="grid grid-cols-3 gap-4 mt-4">
            <div className="col-span-1 h-[400px] bg-white shadow-sm rounded-1 p-2">
              <div className="w-full flex items-center justify-between">
                <CardTitle title={"Customer Managers."} />
                <Link
                  to=""
                  className="d-flex align-items-center justify-content-center more-btn"
                >
                  View all
                </Link>
              </div>
              {customerManagers.length ? (
                <ProjectManagerCard
                  entities={customerManagers}
                  role={"Customer Manager"}
                />
              ) : (
                <div className="flex items-center justify-center">
                  <Empty style={{ height: "100%", paddingTop: "20%" }} />
                </div>
              )}
            </div>
            {/* Project Managers  */}
            <div className="h-[400px] bg-white p-2  shadow-sm rounded-md">
              <div className="w-full flex items-center justify-between">
                <div className="">
                  <CardTitle title={"Project Managers."} />
                </div>
                <Link
                  to=""
                  className="d-flex align-items-center justify-content-center more-btn"
                >
                  View all
                </Link>
              </div>
              {projectManagers.length ? (
                <div className="pt-2">
                  {projectManagers.map((projectManager, i) => (
                    <RolesCard
                      key={i}
                      role={"Project Manager"}
                      entity={projectManager}
                    />
                  ))}
                </div>
              ) : (
                <Empty style={{ height: "100%", paddingTop: "20%" }} />
              )}
            </div>
            {/* Sales Managers  */}
            <div className="h-[400px] bg-white p-2  shadow-sm rounded-md">
              <div className="w-full flex items-center justify-between">
                <div className="">
                  <CardTitle title={"Sales Agents."} />
                </div>
                <Link
                  to=""
                  className="d-flex align-items-center justify-content-center more-btn"
                >
                  View all
                </Link>
              </div>
              {projectManagers.length ? (
                <div className="pt-2">
                  {projectManagers.map((projectManager, i) => (
                    <RolesCard
                      key={i}
                      role={"Project Manager"}
                      entity={projectManager}
                    />
                  ))}
                </div>
              ) : (
                <Empty style={{ height: "100%", paddingTop: "20%" }} />
              )}
            </div>
          </div>

          {/* Power Quality and Roles */}
          <div className="grid grid-cols-1 mt-4 mb-[200px] bg-white rounded-md shadow-md p-2">
            <div className="col-span-1">
              <div className="flex items-center justify-between ">
                <CardTitle
                  title={"Power Quality"}
                  caption={"Monitor power quality"}
                />
                <div className="">
                  <Select
                    defaultValue={"today"}
                    className="w-[200px]"
                    options={[
                      { value: "today", label: "Today" },
                      { value: "week", label: "This week" },
                      { value: "month", label: "This month" },
                      { value: "year", label: "This year" },
                    ]}
                  />
                </div>
              </div>
              <div className="h-[500px]">
                <PowerQuality />
              </div>
            </div>
          </div>
        </div>
        <div className="h-[200px]"></div>
      </div>
    </Layout>
  );
};

export default ProjectSiteDashboard;

import { Select } from "antd";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { meterController } from "../../../../../controllers/MeterController";
import axios from "../../../../../utils/axios";
import { Button } from "../../../../Button";
import { themeColor as color } from "../../../../../constant/color";

const SetLoadPolicy = ({ meterId, closeModal }) => {
  const { Option } = Select;
  const [permission, setpermission] = useState("");
  const [loading, setloading] = useState(false);
  const handleLoad = async () => {
    try {
      setloading(true);
      let res = await axios.post("/meter/setpermeterloadcontrolpolicy", {
        meternumbers: meterId,
        permission,
      });
      let result = meterController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setloading(false);
        return;
      }
      //   console.log(result.message, "/set");
      toast.success(
        result.message.response
          ? "Load control policy set successfully"
          : "Operation"
      );
      setloading(false);
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <div className="backdrop">
      <div className="addrole-card px-3 py-2 animate__animated animate__fadeInUp align-items-center rounded-2 shadow-sm bg-white  shadow-sm rounded-1">
        <div className="">
          <div className="d-flex align-items-start justify-content-between">
            <div>
              <div className="font-title text-start">Permission</div>
              <div
                className="font-caption"
                style={{
                  fontSize: 14,
                }}
              >
                Set load control on meter
              </div>
            </div>
            <div
              className="fw-lighter text-dark cursor"
              onClick={() => closeModal(false)}
              style={{
                fontSize: 13,
              }}
            >
              close
            </div>
          </div>

          <div
            className="w-100 mt-3 overflow-hidden rounded"
            style={{
              border: `1px solid ${color.baseColor}`,
            }}
          >
            <Select
              size="large"
              style={{
                width: "100%",
                height: 43,
                fontSize: "13px",
              }}
              bordered={false}
              placeholder={"Select address"}
              onChange={(e) => setpermission(e)}
            >
              {["allow", "disallow"].map((item) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <div className="w-100 mt-4" onClick={handleLoad}>
          <Button
            text={"Update"}
            color={color.whiteColor}
            bg={color.baseColor}
          />
        </div>
      </div>
    </div>
  );
};

export default SetLoadPolicy;

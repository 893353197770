import moment from "moment";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import BackBtn from "../../../../../components/BackBtn";
import DateRangePicker from "../../../../../components/DateRangePicker";
import Layout from "../../../../../components/Layout";
import EnergyRecords from "../../../../../components/RecordsCharts/EnergyRecords";
import DCURecordTable from "../../../../../components/Table/DCURecordTable";
import TableTop from "../../../../../components/TableTop";
import TopDateFilter from "../../../../../components/TopDateFilter";
import TopNav from "../../../../../components/TopNav";
// import TopNavTitle from "../../../../../components/TopNav/TopNavTitle";
import { themeColor as color } from "../../../../../constant/color";

const SiteEnergyRecords = () => {
  const [startDate, setStartDate] = useState(
    moment(Date.now()).startOf("month").format("YYYY-MM-DD HH:mm:ss")
  );

  const { projectsite, id } = useParams();

  const [endDate, setEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
  );
  const [refresh, setRefresh] = useState(false);
  return (
    <Layout>
      <div className=" mt-4">
        <div className="container p-0 m-0  mb-3">
          <div className="flex items-center justify-between">
            <div className="sm:col-span-1 pt-1 md:col-span-1 lg:col-span-1 xl:col-span-1 2xl:col-span-1">
              <BackBtn text={"Go back"} />
            </div>
            <div className="sm:col-span-6 md:col-span-5 lg:col-span-3 xl:col-span-4 2xl:col-span-4 pt-1">
              <DateRangePicker
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                refreshBtn={setRefresh}
              />
            </div>
          </div>
        </div>
        <div className="container ">
          <div className="flex items-center justify-between shadow-sm px-2 pb-2 bg-white">
            <div className="sm:col-span-12 md:col-span-12 lg:col-span-6 xl:col-span-6 2xl:col-span-6  py-1">
              <div>
                Energy generated by <span className="zone-name">{id} </span> on{" "}
                <span className="zone-name">{projectsite}</span> (project site)
              </div>
            </div>
            <div className="sm:col-span-12 hidden md:col-span-12 lg:col-span-4 py-1 mb-2">
              <div className="flex items-center justify-center">
                <TopDateFilter />
              </div>
            </div>
          </div>
          {/* Line charts */}
          <div className="bg-white h-[90vh] shadow-sm py-3 px-3">
            <EnergyRecords />
          </div>
        </div>

        <div
          className="col-span-12 hidden bg-white shadow-sm mt-3 rounded-md"
          style={{
            marginBottom: 200,
          }}
        >
          <TableTop title={"Energy"} />
          <DCURecordTable />
        </div>
      </div>
    </Layout>
  );
};

export default SiteEnergyRecords;

import { BiUserPlus } from "react-icons/bi";
import "./styles.css";
// import AddSiteImg from "../../assets/svg/dcuimg.svg";
import { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { Empty } from "antd";
import { Button } from "../../../Button";

const ViewModal = ({ title, meta, value, img, closeModal }) => {
  return (
    <div className="backdrop">
      <div className="bg-white shadow-md rounded-md w-[95%] md:w-[450px] px-3 py-4 animate__animated animate__fadeInUp">
        <div className="flex items-start justify-between">
          <div className="">
            <div className=" mb-1 font-bold ">{title}</div>
            <div className="cardcaption ">{meta}</div>
          </div>
          <div
            className="text-secondary cursor-pointer"
            onClick={() => closeModal(false)}
          >
            Close
          </div>
        </div>

        <div style={{ fontSize: "large", padding: 10 }}>
          {value || value < 1 ? value : <Empty />}
        </div>
        <div className="flex items-center mt-3 justify-between w-full  pt-3">
          {/*   */}
          <div className="w-full">
            <Button
              text="Done"
              height={"40px"}
              onClick={() => closeModal(false)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewModal;

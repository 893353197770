import axios from "../../../utils/axios";
import { useState, useEffect } from "react";
import Layout from "../../../components/Layout";
import PageTitle from "../../../components/PageTitle";
import { toast } from "react-hot-toast";
import { channelController } from "../../../controllers/channelController";
import { Select } from "antd";
import TableSkimmer from "../../../components/TableSkimmer";
import AllCustomerTable from "../../../components/SalesAgent/Table/AllCustomerTable";
import { customerFilterData } from "./data";
import { LoadingIndicator } from "@syncfusion/ej2-react-grids";
import CustomerMeterDetails from "../../../components/SalesAgent/Modals/CustomerMeterDetails";

const SalesAgentCustomer = () => {
  const [data, setData] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState("");
  const [columnInput, setColumnInput] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modal, setmodal] = useState(false);
  const [details, setdetails] = useState(null);
  const { Option } = Select;
  const controller = new AbortController();
  useEffect(() => {
    (async () => {
      try {
        // 7654
        // if (!selectedColumn && !columnInput) return null;
        setLoading(true);
        let res = await axios.post("/customer/getallmetercustomers", {
          column: selectedColumn,
          columnparameter: columnInput,
        });
        let result = channelController(res);
        if (result.type !== "success") {
          toast.error(result.message);
          setLoading(false);
          return;
        }
        console.log(result, "result of query");
        setData(result.message.body);
        setLoading(false);
        // 3456
      } catch (err) {
        toast.error(err.message);
        setLoading(false);
        // 3456
      }
    })();

    return () => {
      controller.abort();
    };
  }, [refresh]);

  return (
    <Layout>
      {modal && (
        <CustomerMeterDetails
          open={modal}
          setopen={setmodal}
          refreshBtn={setRefresh}
          details={details}
        />
      )}
      <div className="bg-white rounded-lg drop-shadow-md p-3 h-[80vh] mb-30 mt-6">
        <div className="flex items-center justify-between mb-4">
          <div className="">
            <PageTitle title={"All customers"} caption="Description" />
          </div>
          <div className="">
            <div className="text-end font-semibold">
              Advanced search filter.
            </div>
            <div className="flex items-center justify-between border-[1px] rounded-md overflow-hidden border-gray-600">
              <div className="border">
                <Select
                  style={{
                    height: "36px",
                    width: "130px",
                  }}
                  onChange={(e) => setSelectedColumn(e)}
                  options={customerFilterData}
                  bordered={false}
                  placeholder={"Select field."}
                />
              </div>
              <div className="flex bg-white items-center w-100 h-full">
                <input
                  required
                  value={columnInput}
                  onChange={(e) => setColumnInput(e.target.value)}
                  type={"text"}
                  placeholder={"Enter name."}
                  className="px-2 placeholder:text-sm placeholder:font-thin w-full h-[35px] outline-none border-none"
                />
              </div>
              <div className="border bg-gray-800 rounded-md">
                {loading ? (
                  <button disabled={loading} className="px-4 py-2 text-gray-50">
                    Search...
                  </button>
                ) : (
                  <button
                    onClick={() => setRefresh((prev) => !prev)}
                    className="px-4 py-2 text-gray-50 cursor-pointer"
                  >
                    Search
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="">
          {loading ? (
            <TableSkimmer entries={10} col={7} />
          ) : (
            <AllCustomerTable
              data={data}
              setdetails={setdetails}
              setmodal={setmodal}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default SalesAgentCustomer;

import CardTitle from "../../CardTitle";
import RevenueCharts from "../Charts/RevenueCharts";
import { BsArrowDownShort } from "react-icons/bs";
import { themeColor as color } from "../../../constant/color";
import "./styles.css";
import { useState } from "react";
import { Empty, Select } from "antd";
import DateRangePicker from "../../DateRangePicker";
import moment from "moment";

const Revenue = ({ paymentHistory, revenue, dateRange }) => {
  const [tabstate, setTabState] = useState(1);

  const setDate = (tab, num) => {
    dateRange.setEndDate(moment(Date.now()).format("YYYY-MM-DD"));
    dateRange.setStartDate(
      moment(Date.now()).subtract("1", tab).format("YYYY-MM-DD")
    );
    dateRange.setRefresh(!dateRange.refresh);
    setTabState(num);
  };
  return (
    <div className="w-full">
      <div className=" w-full flex items-center justify-between ">
        <CardTitle title={"Revenue Balance"} />
        <div className="flex items-center justify-end">
          <Select
            defaultValue={"today"}
            className="w-[200px]"
            options={[
              { value: "today", label: "Today" },
              { value: "week", label: "This week" },
              { value: "month", label: "This month" },
              { value: "year", label: "This year" },
            ]}
          />
          {/* <div
            className="revenue-date__cover text-center px-4 py-2 mx-1"
            style={{
              background: tabstate == 1 ? color.baseColor : "#F5EDFF",
              color: tabstate == 1 ? color.whiteColor : color.baseColor,
              cursor: "pointer",
            }}
            onClick={() => setDate("day", 1)}
          >
            Today
          </div>
          <div
            className="revenue-date__cover text-center px-4 py-2 mx-1"
            style={{
              background: tabstate == 2 ? color.baseColor : "#F5EDFF",
              color: tabstate == 2 ? color.whiteColor : color.baseColor,
              cursor: "pointer",
            }}
            onClick={() => setDate("week", 2)}
          >
            Week
          </div>
          <div
            className="revenue-date__cover text-center px-4 py-2 mx-1"
            style={{
              background: tabstate == 3 ? color.baseColor : "#F5EDFF",
              color: tabstate == 3 ? color.whiteColor : color.baseColor,
              cursor: "pointer",
            }}
            onClick={() => setDate("month", 3)}
          >
            Month
          </div> */}
        </div>
      </div>

      <div className="text-[20px] font-bold">${revenue}</div>

      <div className="w-full h-[400px]">
        <div className="p-2 flex justify-center items-center">
          {paymentHistory.length ? (
            <RevenueCharts paymentHistory={paymentHistory} />
          ) : (
            <Empty />
          )}
        </div>
      </div>
    </div>
  );
};

export default Revenue;

{
  /* <div className="revenue-date__cover" style={{ width: 230 }}> */
}
{
  /* {dateRange.useCalendar ? (
  <DateRangePicker
    setEndDate={dateRange.setEndDate}
    setStartDate={dateRange.setStartDate}
    refreshBtn={dateRange.setRefresh}
  />
) : null} */
}
{
  /* </div>; */
}

export const pageTitle = (link) => {
  if (link == "/dashboard/systemadmin/projectsite") {
    return "All project sites";
  } else if (link == "/dashboard/customers") {
    return "Search for meter";
  } else if (link.includes("/dashboard")) {
    return "Dashboard";
  } else if (link == "/allchannels") {
    return "Channels";
  } else if (link.includes("/users")) {
    return "Customer profile";
  } else if (link == "/manage-meter") {
    return "Customer Meters";
  } else if (link == "/wallet") {
    return "Wallet";
  } else if (link.includes("/roles")) {
    return "Roles";
  } else if (link == "/allmeters" || link == "/usermeters") {
  } else if (link == "/meterclass") {
    return "Meter class";
  }

  // case "Revenue":
  //   return <IoWallet size={21} color={"#6c6c6c"} />;
  // case "Meter Settings":
  //   return (
  //     <RiSettings5Fill
  //       size={21}
  //       color={location.pathname.includes("/settings") ? "#fff" : "#6c6c6c"}
  //     />
  //   );
  // case "Settings":
  //   return (
  //     <RiSettings5Fill
  //       size={21}
  //       color={location.pathname.includes("/settings") ? "#fff" : "#6c6c6c"}
  //     />
  //   );
  // case "Sales Analytics":
  //   return (
  //     <RiWallet3Fill
  //       size={21}
  //       color={
  //         location.pathname.includes("/salesanalytics/") ? "#fff" : "#6c6c6c"
  //       }
  //     />
  //   );
  // case "Recharge History":
  //   return (
  //     <RiWallet3Fill
  //       size={21}
  //       color={
  //         location.pathname.includes("/recharge-history") ? "#fff" : "#6c6c6c"
  //       }
  //     />
  //   );
  // case "Meter Activities":
  //   return (
  //     <RiWallet3Fill
  //       size={21}
  //       color={
  //         location.pathname.includes("/customer-meter/meteractivities")
  //           ? "#fff"
  //           : "#6c6c6c"
  //       }
  //     />
  //   );
  // case "Transactions":
  //   return (
  //     <GiWallet
  //       size={21}
  //       color={
  //         location.pathname.includes("/allcustomer-transactions")
  //           ? "#fff"
  //           : "#6c6c6c"
  //       }
  //     />
  //   );
  // case "Log out":
  //   return <IoLogOut size={21} color={"#6c6c6c"} />;
  // default:
  //   return null;
  //   }
};

import axios from "../../../../utils/axios";
import moment from "moment";
import { useState } from "react";
import { toast } from "react-hot-toast";
import Backdrop from "../../../Backdrop";
import CloseButton from "../../../CloseButton";
import Rule from "../../../Rule/Index";
import { Button } from "../../../Button";
import { MdMoney } from "react-icons/md";
import { useParams } from "react-router-dom";
import { channelController } from "../../../../controllers/channelController";

const TopUpModal = ({ open, setopen, details, refreshBtn }) => {
  const [loading, setloading] = useState(false);
  const { meterid } = useParams();
  const [amount, setamount] = useState("");
  const handleTopUp = async () => {
    try {
      if (!amount) return toast.error("Please enter a amount");
      setloading(true);
      let res = await axios.post("/transaction/vendwithwallet", {
        meterid,
        amount,
      });
      let result = channelController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setloading(false);
        return;
      }
      // refreshBtn((prev) => !prev);
      toast.success("Unit added successfully.");
      setloading(false);
      setopen(false);
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.response);
        setloading(false);
        return;
      }
      toast.error(err.message);
      setloading(false);
    }
  };
  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[400px] bg-white drop-shadow-md rounded-sm p-3">
          <div className="flex items-start w-full justify-between">
            <div className="">
              <div className="font-Kanit font-semibold text-[17px]">
                Top up meter
              </div>
              <div className=" hidden w-[80%] text-gray-700 font-light">
                {/* Enter amount  <br /> */}
              </div>
            </div>
            <div className=" w-[200px] flex justify-end">
              <CloseButton closeBtn={setopen} />
            </div>
          </div>
          <Rule />

          <div className="pb-2">
            <div className="mt-4 mb-8">
              <label className="text-primary font-normal text-sm">
                Enter amount.
              </label>
              <div className="flex bg-white w-full items-center w-100 border-[1px] rounded-md border-gray-600 mt-2 h-[40px]">
                <div className="d-inline flex items-center justify-center px-2">
                  <MdMoney
                    size={20}
                    color="text-primary"
                    className="text-primary"
                  />
                </div>
                <div className="ml-2">
                  <input
                    required
                    value={amount}
                    onChange={(e) => setamount(e.target.value)}
                    type={"number"}
                    placeholder={"How much would you like to recharge."}
                    className="placeholder:text-sm placeholder:font-thin w-full outline-none border-[0px]"
                  />
                </div>
              </div>
            </div>
            <Button
              text={"Recharge"}
              height={"40px"}
              loading={loading}
              onClick={() => handleTopUp()}
            />
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default TopUpModal;

import { useParams } from "react-router-dom";
import SalesLineChart from "../../../components/Dashboards/SalesAgent/Charts/SalesLineChart";
import Layout from "../../../components/Layout";
import PageTitle from "../../../components/PageTitle";
import { useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-hot-toast";
import axios from "../../..//utils/axios";
import DateRangePicker from "../../../components/DateRangePicker";
import { getToken } from "../../../utils/token";

const SalesAnalytics = () => {
  // const { site } = useParams();
  const [startDate, setStartDate] = useState(
    moment(Date.now()).subtract("1", "week").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );
  const [refresh, setRefresh] = useState(false);
  const [paymentHistory, setPaymetHistory] = useState([]);
  const [revenue, setRevenue] = useState(0);
  const [sitename, setsitename] = useState("");
  useEffect(() => {
    (async () => {
      try {
        let site = await getToken("spiral_site");
        setsitename(site);
        let res = await axios
          .post("/salesanalytics/getsaleshistoryperzone", {
            projectzone: site,
            assettype: "[]",
            startdate: startDate,
            enddate: endDate,
            limit: 30,
            offset: 0,
            paymentoperation: JSON.stringify(["Vending", "Connection fee"]),
          })
          .then((response) => {
            // console.log(response, "///result");
            setPaymetHistory(response.data.body);
            setRevenue(response.data.body.reduce((p, c) => (p += c.amount), 0));
          })
          .catch((err) => toast(err.message));
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, [refresh]);

  return (
    <Layout>
      <div className="bg-white rounde-lg mb-10 mt-6 drop-shadow-md p-3">
        <div className="flex items-center justify-between">
          <PageTitle
            title={"Sales Analytics of " + sitename}
            caption={"Sales analytics chart"}
          />
          <div className="">
            <DateRangePicker
              refreshBtn={setRefresh}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              desc={"Date picker"}
            />
          </div>
        </div>
        <div className="h-[600px]">
          <SalesLineChart data={paymentHistory} />
        </div>
      </div>
    </Layout>
  );
};

export default SalesAnalytics;

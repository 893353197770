import moment from "moment";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import Rule from "../../components/Rule/Index";
import { IoNotificationsOutline } from "react-icons/io5";
import { FcCalendar } from "react-icons/fc";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { NotificationFormatter } from "../../helperFuncs";
import NotiImg from "../../assets/svg/2246841_bell_notification_one notification_twitter_icon.svg";
import axios from "../../utils/axios";
import { errorBlock } from "../../controllers/errorBlock";
import BackBtn from "../../components/BackBtn";
import RequestDetails from "../../components/Request/Modal/RequestDetails";

const IncomingRequest = () => {
  const [refresh, setrefresh] = useState(false);
  const [loading, setloading] = useState(false);
  const [modal, setmodal] = useState(false);
  const [details, setdetails] = useState({});
  const [request, setrequest] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        setloading(true);
        let res = await axios.post("/submeter/getreceivedchanneladdrequests", {
          limit: 100,
          offset: 0,
        });

        setrequest(res?.data.body);
        // setusers();
        setloading(false);
      } catch (err) {
        errorBlock(err);
      }
    })();
    return () => {};
  }, [refresh]);

  return (
    <Layout>
      <>
        {modal && (
          <RequestDetails
            open={modal}
            setopen={setmodal}
            details={details}
            setdetails={setdetails}
            refreshBtn={setrefresh}
          />
        )}
        <div className="bg-white drop-shadow-md rounded-md mt-6 mb-10">
          <div className="px-4">
            <BackBtn />
            <PageTitle
              title={"Incoming request"}
              caption={"All your notifications"}
            />
          </div>
          <Rule />
          <div className="px-4 py-2">
            <div className="border-[1px] border-gray-300 h-[90vh] rounded-md p-3">
              {!request ? (
                <div className="flex items-center justify-center h-full">
                  <div className="">
                    <div className="flex items-center justify-center">
                      <img src={NotiImg} alt="" className="w-[70px] h-[70px]" />
                    </div>
                    <div className="font-Kanit mt-3 font-semibold ">
                      All your incoming request
                    </div>
                  </div>
                </div>
              ) : (
                <table>
                  <thead>
                    <th className="text-start">Date sent</th>
                    <th className="text-center">Sender</th>
                    <th className="text-center">Note</th>
                    <th className="text-center">Access given</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">Action</th>
                  </thead>
                  <tbody>
                    {request.map((i, index) => (
                      <tr
                        key={index}
                        className="cursor-pointer even:bg-gray-50 hover:bg-gray-50"
                      >
                        <td className="text-start">
                          <div className="flex justify-start items-center">
                            <div className="">
                              <FcCalendar size={21} />
                            </div>
                            <div className="pl-2">
                              {moment(i.datesent).format("lll")}
                            </div>
                          </div>
                        </td>
                        <td className="text-center">{i.sender}</td>
                        <td className="text-center">{i.note}</td>
                        <td className="text-center">
                          {i.accesscontrol == "single" ? "Single" : "Shared"}
                        </td>
                        <td className="text-center">{i.status}</td>
                        <td className="text-start flex justify-center items-center">
                          <div
                            onClick={() => {
                              setdetails(i);
                              setmodal(true);
                            }}
                            className="border-[1px] text-secondary mr-3 text-[13px] border-secondary px-4 py-1 rounded-md w-[fit-content]"
                          >
                            View
                          </div>
                          {/* <div className="border-[1px] text-red-500 mr-3 text-[13px] border-red-500 p-1 rounded-md w-[fit-content]">
                            Decline
                          </div>
                          <div className="border-[1px] text-secondary text-[13px] border-secondary p-1 rounded-md w-[fit-content]">
                            Accept
                          </div> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        <div className="pb-[50px]"></div>
      </>
    </Layout>
  );
};

export default IncomingRequest;

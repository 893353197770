import "./style.css";
const Skimmer = ({ width, heigth, bg }) => {
  return (
    <div
      className="skimmer"
      style={{
        width: width || "100%",
        height: heigth || "30px",
        backgroundColor: bg || "#eee",
      }}
    >
      <div className="skimmer-container"></div>
    </div>
  );
};

export default Skimmer;

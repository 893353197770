import EmptyState from "../../../EmptyState";
import { HiUserGroup } from "react-icons/hi";
const ProjectSiteTable = ({ data, loading }) => {
  return (
    <div>
      {!data.length ? (
        <EmptyState>
          <div className="h-[70vh] w-full">
            <div className="flex items-center justify-center mt-[200px]">
              <HiUserGroup size={30} />
            </div>
            <div className="font-normal text-[15px] text-center">
              No data to show
            </div>
          </div>
        </EmptyState>
      ) : (
        <table className="w-full border-collapse">
          <thead className="h-[50px] drop-shadow-md border-b-[0.5px] border-gray-800">
            <th className="font-semibold text-gray-800">S/N</th>
            <th className="font-semibold text-gray-800">Meter Number </th>
            <th className="font-semibold text-gray-800">Customer Name</th>
            <th className="font-semibold text-gray-800">Customer Type </th>
            <th className="font-semibold text-gray-800">Meter type</th>
          </thead>
          <tbody>
            {data.map((i, index) => (
              <tr
                className="bg-white hover:bg-gray-50 cursor-pointer h-[50px] even:bg-gray-50"
                key={index}
              >
                <td className="text-center">{index + 1}</td>
                <td className="text-center">{i?.meternumber}</td>
                <td className="text-center">{i.customerid}</td>
                <td className="text-center">{i.customertype}</td>
                <td className="text-center">{i?.section}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ProjectSiteTable;

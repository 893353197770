import { BiUserPlus } from "react-icons/bi";
import "./styles.css";
import AddSiteImg from "../../../assets/svg/siteimg.svg";
import { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { Button } from "../../Button";
import axios from "../../../utils/axios";
import toast from "react-hot-toast";
import { Select } from "antd";

const { Option } = Select;
const SetLoadControl = ({ zones, closeModal, meterTypes }) => {
  const [zone, setZone] = useState(null);
  const [value, setValue] = useState(null);
  const [metertype, setMetertype] = useState(null);
  const [loading, setLoading] = useState(false);

  const submitLoadControl = () => {
    if (!zone) return toast.error("Please select a zone");
    if (!metertype) return toast("Please select a meter type");
    if (!value) return toast("Please select a load control value");
    setLoading(true);
    axios
      .post("/settings/setzonemeterloadcontrol", {
        zone,
        value: Boolean(value),
        metertype,
      })
      .then((response) => {
        toast("Load control has been set");
        setLoading(false);
        closeModal();
      })
      .catch((err) => {
        setLoading(false);
        toast(err.message);
      });
  };

  return (
    <div className="backdrop">
      <div className="bg-white shadow-md rounded-md w-[95%] md:w-[450px] px-3 py-4 animate__animated animate__fadeInUp">
        <div className="flex items-start justify-between">
          <div className="">
            <div className="font-bold">Set Load control</div>
            <div className="">
              Request for the load control of a meter type in a project site
            </div>
          </div>
          <div
            className="text-secondary cursor-pointer"
            onClick={() => closeModal(false)}
          >
            Close
          </div>
        </div>

        <div className="input-entry w-100 mt-2">
          <div>Select project site</div>
          <Select
            style={{ width: "100%", fontSize: "1rem" }}
            allowClear
            size="large"
            placeholder="Select project site"
            value={zone}
            onChange={(val) => setZone(val)}
          >
            {zones.map((zone) => (
              <Option value={zone} key={zone}>
                {zone}
              </Option>
            ))}
          </Select>
        </div>

        <div className="input-entry w-100 mt-2">
          <div>Select meter type</div>
          <Select
            style={{ width: "100%", fontSize: "1rem" }}
            allowClear
            size="large"
            placeholder="Select meter type"
            value={metertype}
            onChange={(val) => setMetertype(val)}
          >
            {meterTypes.map((type) => (
              <Option value={type} key={type}>
                {type}
              </Option>
            ))}
          </Select>
        </div>
        <div className="input-entry w-full my-3">
          <div>Select load control option</div>
          <Select
            style={{ width: "100%", fontSize: "1rem" }}
            allowClear
            size="large"
            placeholder="Select meter type"
            value={value}
            onChange={(val) => setValue(val)}
          >
            <Option value={"true"}>YES</Option>
            <Option value={"false"}>NO</Option>
          </Select>
        </div>
        <div className=" w-full mt-2">
          <Button
            text="Done"
            height={"40px"}
            status={loading}
            onClick={() => submitLoadControl()}
          />
        </div>
      </div>
    </div>
  );
};

export default SetLoadControl;

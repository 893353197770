import { v4 as uuidv4 } from "uuid";

export const customerFilterData = [
  {
    id: `${uuidv4()}`,
    label: "Username",
    value: "customerid",
  },
  {
    id: `${uuidv4()}`,
    label: "Agent Name",
    value: "agent",
  },
  {
    id: `${uuidv4()}`,
    label: "User role",
    value: "role",
  },
  {
    id: `${uuidv4()}`,
    label: "Pole Number.",
    value: "pole",
  },
  {
    id: `${uuidv4()}`,
    label: "Unique Section.",
    value: "section",
  },
  {
    id: `${uuidv4()}`,
    label: "Project zone.",
    value: "zone",
  },
  //   {
  //     id: `${uuidv4()}`,
  //     label: "Date created.",
  //     value: "datecreated",
  //   },
  {
    id: `${uuidv4()}`,
    label: "Meter Number.",
    value: "meter",
  },
  {
    id: `${uuidv4()}`,
    label: "Connection Balance.",
    value: "connectionbalance",
  },
  {
    id: `${uuidv4()}`,
    label: "Connection Fee.",
    value: "connectionfee",
  },
];

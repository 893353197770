import axios from "../../utils/axios";
import { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import Rule from "../../components/Rule/Index";
import TableSkimmer from "../../components/TableSkimmer";
import WalletCard from "../../components/Wallet/Cards/WalletCards";
import WalletHistoryTable from "../../components/Wallet/Table/WalletHistoryTable";
import { channelController } from "../../controllers/channelController";
import { errorBlock } from "../../controllers/errorBlock";
import { getToken } from "../../utils/token";
import DateRangePicker from "../../components/DateRangePicker";
import moment from "moment";
import { toast } from "react-hot-toast";
import { billingController } from "../../controllers/BillingController";
import Skimmer from "../../components/Loader/Skimmer";
const Wallet = () => {
  const [loading, setloading] = useState({
    history: false,
    balance: true,
  });

  const [history, sethistory] = useState([]);
  const [balance, setbalance] = useState("");
  const [details, setdetails] = useState(null);
  const [refresh, setrefresh] = useState(false);
  const [startdate, setstartdate] = useState(
    moment(Date.now()).subtract(1, "month").format("YYYY-MM-DD HH:mm:ss")
  );
  const [enddate, setenddate] = useState(
    moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
  );
  const [limit, setlimit] = useState(12);
  const [offset, setoffset] = useState(0);

  const [modal, setmodal] = useState(false);
  //   history;
  useEffect(() => {
    (async () => {
      try {
        setloading((prev) => {
          return {
            ...prev,
            history: true,
          };
        });
        let username = await getToken("spiral_username");
        let res = await axios.post("/transaction/getwallettransactionhistory", {
          username,
          startdate,
          enddate,
          limit,
          offset,
        });
        let result = channelController(res);
        if (result.type !== "success") {
          toast[result.type](result.message);
          setloading((prev) => {
            return {
              ...prev,
              history: false,
            };
          });
          return;
        }

        // console.log(result, "///response from object");
        setloading((prev) => {
          return {
            ...prev,
            history: false,
          };
        });
      } catch (err) {
        errorBlock(err);
        setloading((prev) => {
          return {
            ...prev,
            history: false,
          };
        });
      }
    })();
  }, [refresh, limit, offset]);
  //   balance
  useEffect(() => {
    (async () => {
      try {
        setloading((prev) => {
          return {
            ...prev,
            balance: true,
          };
        });
        let username = await getToken("spiral_username");
        let res = await axios.post("/transaction/getwalletbalance", {
          username,
        });
        let result = billingController(res);
        if (result.type !== "success") {
          toast[result.type](result.message);
          setloading((prev) => {
            return {
              ...prev,
              balance: false,
            };
          });
          return;
        }
        setbalance(result.message.response);

        setloading((prev) => {
          return {
            ...prev,
            balance: false,
          };
        });
      } catch (err) {
        errorBlock(err);
        setloading((prev) => {
          return {
            ...prev,
            balance: false,
          };
        });
      }
    })();
  }, [refresh, limit, offset]);
  return (
    <Layout>
      <div className="bg-white rounded-md drop-shadow-md p-3 mt-6 mb-10">
        <div className="flex mb-3 justify-end items-center">
          <DateRangePicker
            refreshBtn={setrefresh}
            setEndDate={setenddate}
            setStartDate={setstartdate}
            desc
          />
        </div>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
          {/* {loading.balance ? (
            <div className="h-[120px]">
              <Skimmer heigth={"100%"} />
            </div>
          ) : (
            <WalletCard value={balance} />
          )} */}
          <WalletCard
            value={balance}
            title={"Wallet Balance"}
            type={"balance"}
          />
          <WalletCard value={balance} title={"Money in"} />
          <WalletCard value={balance} title={"Money out"} type={"spent"} />
        </div>
        <div className="mt-3">
          <div className="">
            <div className="grid grid-cols-1 md:grid-cols-2">
              <PageTitle
                title={"Wallet history"}
                caption={"View wallet transaction history"}
              />
              <div className="hidden">
                <DateRangePicker
                  refreshBtn={setrefresh}
                  setEndDate={setenddate}
                  setStartDate={setstartdate}
                  desc
                />
              </div>
            </div>
          </div>

          <Rule />
          <div className="">
            {loading.history ? (
              <TableSkimmer entries={12} col={7} />
            ) : (
              <WalletHistoryTable
                letHistoryTable
                setdetails={setdetails}
                setmodal={setmodal}
                data={history}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Wallet;

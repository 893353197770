import { BiUserPlus } from "react-icons/bi";
import "./styles.css";
import AddSiteImg from "../../../assets/svg/siteimg.svg";
import { useEffect, useState } from "react";
import "antd/dist/antd.css";

import { Button } from "../../Button";
import axios from "../../../utils/axios";
import toast from "react-hot-toast";
import { Select } from "antd";

const { Option } = Select;
const SetAgentDiscount = ({ zones, closeModal }) => {
  const [zone, setZone] = useState(null);
  const [discount, setDiscount] = useState("");
  const [loading, setLoading] = useState(false);

  const submitDiscount = () => {
    if (!zone) return toast.error("Please select a zone");
    if (!discount) return toast.error("Please enter a value for minimum vend");
    if (isNaN(Number(discount)))
      return toast.error("Minimum vend must be a number");
    setLoading(true);
    axios
      .post("/settings/setsalesagentdiscount", {
        zone,
        discountpercentage: Number(discount),
      })
      .then((response) => {
        toast.success("Discount has been issued");
        setLoading(false);
        closeModal();
      })
      .catch((err) => {
        setLoading(false);
        toast(err.message);
      });
  };

  return (
    <div className="backdrop">
      <div className="bg-white shadow-md rounded-md w-[95%] md:w-[450px] px-3 py-4 animate__animated animate__fadeInUp">
        <div className="flex items-start justify-between">
          <div className="">
            <div className="font-bold">Set agent discount</div>
            <div className="">
              Fill in the fields below to set the discount for a sales agent on
              a project site
            </div>
          </div>
          <div
            className="text-secondary cursor-pointer"
            onClick={() => closeModal(false)}
          >
            Close
          </div>
        </div>

        <div className="input-entry w-100 mt-2">
          <div>Select project site</div>
          <Select
            style={{ width: "100%", fontSize: "1rem" }}
            allowClear
            size="large"
            placeholder="Select project site"
            value={zone}
            onChange={(val) => setZone(val)}
          >
            {zones.map((zone) => (
              <Option value={zone} key={zone}>
                {zone}
              </Option>
            ))}
          </Select>
        </div>
        <div className="input-entry w-full my-3">
          <div>Enter discount</div>
          <input
            type="text"
            className="w-full px-2 input-entry h-[40px] border-[1px] border-gray-400 rounded-1"
            placeholder={"Discount."}
            onChange={(e) => setDiscount(e.target.value)}
            value={discount}
          />
        </div>
        <div className="w-full">
          <Button
            text="Done"
            height={"40px"}
            status={loading}
            onClick={() => submitDiscount()}
          />
        </div>
      </div>
    </div>
  );
};

export default SetAgentDiscount;

import axios from "../../../../utils/axios";
import moment from "moment";
import { useState } from "react";
import { toast } from "react-hot-toast";
import Backdrop from "../../../Backdrop";
import CloseButton from "../../../CloseButton";
import Rule from "../../../Rule/Index";
import { Button } from "../../../Button";
import { touFormatter } from "../../../../helperFuncs";
const ViewMeterClass = ({ open, setopen, details, refreshBtn }) => {
  const [loading, setloading] = useState(false);

  const formattime = (item) =>
    `${item.substring(0, 2)}:${item.substring(2, 4)}`;
  const handleDelete = async (item) => {
    try {
      setloading(true);
      let res = await axios.post("/meterclass/deletesavedmeterclasses", {
        meterclasscode: item,
      });
      refreshBtn((prev) => !prev);
      toast.success("Meterclass deleted successfully.");
      setloading(false);
      setopen(false);
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.response);
        setloading(false);
        return;
      }
      toast.error(err.message);
    }
  };
  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[95%] md:w-[550px] bg-white drop-shadow-md rounded-sm p-3">
          <div className="flex items-start  w-full justify-between">
            <div className="">
              <div className="font-Kanit font-semibold text-[17px]">
                Meterclass details
              </div>
              <div className=" hidden w-[80%] text-gray-700 font-light">
                More info on the meterclass you have created <br />
              </div>
            </div>
            <div className=" w-[200px] flex justify-end">
              <CloseButton closeBtn={setopen} />
            </div>
          </div>
          <Rule />
          <div className="">
            <div className="flex items-center justify-between mt-2">
              <div className="text-gray-700">Meterclass name:</div>
              <div className="font-semibold text-gray-800">
                {details?.meterclass}
              </div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="text-gray-700">Description:</div>
              <div className="font-semibold text-gray-800">
                {details?.description}
              </div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="text-gray-700">Date created:</div>
              <div className="font-semibold text-gray-800">
                {moment(details?.dateentered).format("lll")}
              </div>
            </div>
            <div className="mt-2">
              <div className="text-gray-700">Active time of use</div>
              <div className="font-semibold text-gray-800 w-ful p-2  flex flex-wrap items-start">
                {touFormatter(details.tou).map(({ pt, st, et }, index) => (
                  <div
                    className="w-[150px] mx-2 bg-white border drop-shadow-md  p-2 rounded-md"
                    key={index}
                  >
                    <div className="flex justify-between">
                      <div className="font-light text-gray-600 text-[13px]">
                        Start time:
                      </div>
                      <div className="text-gray-700 text-[13px]">{st}</div>
                    </div>
                    <div className="flex justify-between">
                      <div className="font-light text-gray-600 text-[13px]">
                        End time:
                      </div>
                      <div className="text-gray-700 text-[13px]">{et}</div>
                    </div>
                    <div className="flex justify-between">
                      <div className="font-light text-gray-600 text-[13px]q">
                        Bill %:
                      </div>
                      <div className="text-gray-700 text-[13px]">{pt}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Rule />
          <div className="flex items-center justify-end my-4">
            <div className="w-[130px]">
              <Button
                text={"Delete Meterclass"}
                bg={"white"}
                height={"35px"}
                border="1px solid tomato"
                loaderColor={"tomato"}
                onClick={() => handleDelete(details?.meterclass)}
                loading={loading}
                color={"tomato"}
              />
            </div>
          </div>

          {/* <div className="flex items-center justify-between mt-2">
            <div className="text-gray-700">:</div>
            <div className="font-semibold text-gray-800">
              {details?.energyorpower}units
            </div>
          </div> */}
        </div>
      </div>
    </Backdrop>
  );
};

export default ViewMeterClass;

export const siteFields = {
  newsitename: {
    url: "/sitemanagement/updatesitenamebysitename",
    friendlyName: "Site name",
    getError: (value) => {
      if (!value) return "Please enter a name for the site";
      return null;
    },
  },
  sitetype: {
    url: "/sitemanagement/updatesitetype",
    friendlyName: "Site type",
    getError: (value) => {
      if (!value) return "Please select a site type";
      return null;
    },
  },
  gps: {
    url: "/sitemanagement/updategpsbysitename",
    friendlyName: "GPS",
    getError: (value) => {
      if (!value) return "Please enter a GPS value";
      if (!/^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/.test(value))
        return "Invalid GPS format.";
      return null;
    },
  },
};

import Chart from "chart.js/auto";
import { Line, Bar } from "react-chartjs-2";
import { useState, useEffect, useRef } from "react";
import { themeColor as color } from "../../../../constant/color";
import moment from "moment";

const RevenueCharts = ({ paymentHistory }) => {
  const chartRef = useRef();
  const [gradientValue, setGradientValue] = useState("");
  const [data, setData] = useState("");
  const [labels, setLabels] = useState("");
  useEffect(() => {
    const ctx = chartRef.current.canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, "#84d1fea7");
    gradient.addColorStop(1, "#ffffff00");
    setGradientValue((prev) => gradient);
  }, []);

  useEffect(() => {
    let labelsTemp = [],
      dataTemp = [];
    paymentHistory.forEach((payment) => {
      labelsTemp.push(
        moment(new Date(payment.datelogged)).format("MMM DD, h:mm A z")
      );
      dataTemp.push(payment.amount);
    });
    setLabels(labelsTemp);
    setData(dataTemp);
  }, []);

  // moment(Date.now()).format('MMM DD, h:mm A z')
  return (
    <Bar
      ref={chartRef}
      data={{
        labels, // x axis
        datasets: [
          {
            type: "bar",
            label: "",
            fill: true,
            backgroundColor: color.baseColor,
            data,
            // borderColor: "#0183d0",
            // radius: 1,
            // borderWidth: 1,
            pointBorderWidth: 1,
            tension: 0.4,
          },
        ],
      }}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: false,
          title: {
            display: false,
          },
          tooltip: {
            // callbacks: {
            //   label: function (tooltipItem, data) {
            //     return numFormtter(parseInt(tooltipItem.parsed.y));
            //   },
            // },
          },
        },
        scales: {
          x: {
            display: false,
            grid: {
              display: false,
            },
          },
          y: {
            display: true,
            grid: {
              display: true,
              borderDash: [10],
              borderDashOffset: 20,
              borderWidth: 0,
              color: "#eee",
            },
          },
        },
      }}
    />
  );
};

export default RevenueCharts;

import axios from "../../../../utils/axios";
import moment from "moment";
import { useState } from "react";
import { toast } from "react-hot-toast";
import Backdrop from "../../../Backdrop";
import CloseButton from "../../../CloseButton";
import Rule from "../../../Rule/Index";
import { Button } from "../../../Button";
import { MdMoney } from "react-icons/md";
import { useParams } from "react-router-dom";
import { channelController } from "../../../../controllers/channelController";

const CustomerMeterDetails = ({ open, setopen, details, refreshBtn }) => {
  const [loading, setloading] = useState(false);
  const [amount, setamount] = useState("");
  const [edit, setedit] = useState(false);

  const handleUpdate = async () => {
    try {
      if (!amount) return toast.error("Please enter an amount.");
      setloading(true);
      let res = await axios.post("/meter/updatemeterconnectionfee", {
        meternumber: details?.meter,
        amount: +amount,
        paymentsource: "card",
      });

      let result = channelController(res);
      if (result.type !== "success") {
        toast.error(result.message);
        setloading(false);
        return;
      }
      // refreshBtn((prev) => !prev);
      toast.success("Fee updated successfully");
      setloading(false);
      setopen(false);
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message);
        setloading(false);
        return;
      }
      toast.error(err.message);
      setloading(false);
    }
  };
  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[550px] bg-white drop-shadow-md rounded-sm p-3">
          <div className="flex items-start  w-full justify-between">
            <div className="">
              <div className="font-Kanit font-semibold text-[17px]">
                Customer meter details
              </div>
              <div className=" hidden w-[80%] text-gray-700 font-light">
                Send a request to user to grant him/her access to your s to the
                channel <br />
              </div>
            </div>
            <div className=" w-[200px] flex justify-end">
              <CloseButton closeBtn={setopen} />
            </div>
          </div>
          <Rule />

          <div className="pb-10">
            <div className="flex items-center justify-between mt-2">
              <div className="text-gray-700">Customer name:</div>
              <div className="font-semibold text-gray-800">
                {details?.customerid}
              </div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="text-gray-700">Meter no:</div>
              <div className="font-semibold text-gray-800">
                {details?.meter}
              </div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="text-gray-700">Address:</div>
              <div className="font-semibold text-gray-800">
                {details?.address}
              </div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="text-gray-700">Agent:</div>
              <div className="font-semibold text-gray-800">
                {details?.agent}
              </div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="text-gray-700">Pole number</div>
              <div className="font-semibold text-gray-800">
                {details.polenumber}
                {/* {moment(details?.datecreated).format("lll")} */}
              </div>
            </div>
            <div className="flex items-start justify-between mt-2">
              <div className="text-gray-700">Section:</div>
              <div className="font-semibold w-[60%] text-gray-80 text-right">
                {details.section}
              </div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="text-gray-700">Zone:</div>
              <div className="font-semibold text-gray-800">{details?.zone}</div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="text-gray-700">Meter type:</div>
              <div className="font-semibold text-gray-800">
                {details?.metertype}
              </div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="text-gray-700">Date created:</div>
              <div className="font-semibold text-gray-800">
                {moment(details?.datecreated).format("lll")}
              </div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="text-gray-700">
                <div className="">
                  <div className="">Connection fee:</div>
                  <div className="">
                    <span
                      className="cursor-pointer italic text-secondary text-[14px] underline"
                      onClick={() => setedit(!edit)}
                    >
                      {!edit ? "Edit" : "Close"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="font-semibold text-gray-800">
                {details?.connectionbalance}
              </div>
            </div>
            {edit && (
              <div className="border-gray-400 p-1 rounded-md border-[1px] my-3">
                <label className="text-primary font-normal text-sm">
                  Update connection fee
                </label>
                <div className="flex w-[400px] p-[2px] bg-white items-center justify-between w-100 border-[1px] rounded-md border-gray-600 mt-2 h-[45px]">
                  <div className="flex items-center justify-center w-[50px]">
                    <MdMoney
                      size={20}
                      color="text-primary"
                      className="text-primary"
                    />
                  </div>
                  <div className="">
                    <input
                      required
                      value={amount}
                      onChange={(e) => setamount(e.target.value)}
                      type={"number"}
                      placeholder={"Enter a value"}
                      className=" placeholder:text-sm placeholder:font-thin w-full outline-none border-[0px]"
                    />
                  </div>
                  <div className="w-[100px] p-[1px]">
                    <Button
                      text={"Update"}
                      loading={loading}
                      height={"38px"}
                      onClick={() => handleUpdate()}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="flex items-center justify-between mt-2">
              <div className="text-gray-700">
                <div className="">Connection balance:</div>
                <div className="">
                  <span className="italic text-secondary text-[15px] underline">
                    view history
                  </span>
                </div>
              </div>
              <div className="font-semibold text-gray-800">
                {details?.connectionbalance}
              </div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="text-gray-700">Max periodic charge:</div>
              <div className="font-semibold text-gray-800">
                {details?.maxperiodiccharge}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default CustomerMeterDetails;

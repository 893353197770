import { Button } from "../../../Button";
import AllMetersTable from "../../Table/AllMetersTable";

const AllMetersTab = ({ data, setdetails, setopen, open }) => {
  return (
    <div className="">
      <div className="">{/* <div className="">Search</div> */}</div>
      <div className="">
        <AllMetersTable
          data={data}
          setdetails={setdetails}
          setmodal={setopen}
        />
      </div>
    </div>
  );
};

export default AllMetersTab;

import moment from "moment";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Layout from "../../components/Layout";
// import MeterTable from "../../components/Meters/Table/MeterTable";
import TableTop from "../../components/TableTop";
import TopNav from "../../components/TopNav";
import axios from "../../utils/axios";
// import { meterController } from "../../controllers/MeterController";
// import MeterInfoModal from "../../components/Meters/Modals/MeterInfoModal";

import BackBtn from "../../components/BackBtn";
// import MeterTopCard from "../../components/Meters/MeterTopCard";
import DateRangePicker from "../../components/DateRangePicker";
// import MeterCountModal from "../../components/Meters/MeterCountModal";
// import AllMeterTable from "../../components/Meters/Table/AllMeterTable";
import AddCustomer from "../../components/Customer/AddCustomer";
// import AssignMeter from "../../components/Meters/AssignMeter";
import { getToken } from "../../utils/token";
import AssignMeter from "../../components/Meter/modals/AssignMeter";
import AllMeterTable from "../../components/Meter/Table/AllMeterTable";
import MeterCountModal from "../../components/Meter/modals/MeterCountModal";
import { meterController } from "../../controllers/MeterController";
import MeterInfoModal from "../../components/Meter/modals/MeterInfoModal";
const Meters = () => {
  // register  == getallmeters
  // register  == assigned by utilit
  // decommission  == removed
  // inservice  == actively
  const [count, setCount] = useState([]);
  const [startDate, setStartDate] = useState(
    moment(Date.now()).subtract("1", "year").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );
  const [decommissionedModal, setDecommissionModal] = useState(false);
  const [commissionedModal, setCommissionModal] = useState(false);
  const [registeredModal, setRegisteredModal] = useState(false);
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const controller = new AbortController();
  const [addMeterModal, setAddMeterModal] = useState(false);
  const [userRole, setUserRole] = useState("");
  // const [loading, setloading] = useState();
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/meter/getallmeters", {
          limit: 30,
          offset: 0,
          startdate: startDate,
          enddate: endDate,
        });
        let result = meterController(res);
        if (result.type !== "success") {
          toast[result.type](result.message);
          return;
        }
        setData(result.message.body);
      } catch (err) {
        toast.error(err.message);
      }
    })();

    return () => {
      controller.abort();
    };
  }, []);
  useEffect(() => {
    Promise.all([
      axios.post("/meter/countalldecommisionedmeters", {
        startdate: startDate,
        enddate: endDate,
      }),
      axios.post("/meter/countallmetersinservice", {
        startdate: startDate,
        enddate: endDate,
      }),
      axios.post("/meter/countallregisteredmeters", {
        startdate: startDate,
        enddate: endDate,
      }),
    ])
      .then((res) => {
        let response = res.map((item) => meterController(item));
        setCount(response);
      })
      .catch((err) => {
        console.log(err);
      });

    // let res = await axios.post("/meter/countalldecommisionedmeters", {
    //   startdate: startDate,
    //   enddate: endDate,
    // });
    // let result = meterController(res);
    // if (result.type !== "success") {
    //   toast[result.type](result.message);
    //   return;
    // }
    // console.log(result.message);
    return () => {
      controller.abort();
    };
  }, [refresh]);

  return (
    <Layout>
      {/* {decommissionedModal && (
        <MeterCountModal
          closeModal={setDecommissionModal}
          title={"Decommissioned Meter Count"}
          caption={"decommissioned"}
          type={"dm"}
        />
      )}
      {commissionedModal && (
        <MeterCountModal
          closeModal={setCommissionModal}
          title={"Commissioned Meter Count"}
          caption={"commissioned"}
          type={"cm"}
        />
      )}
      {registeredModal && (
        <MeterCountModal
          closeModal={setRegisteredModal}
          title={"Registered Meter Count"}
          caption={"registered"}
          type={"rm"}
        />
      )} */}

      {addMeterModal && <AssignMeter closeModal={setAddMeterModal} />}

      <div
        className=" mt-4"
        style={{
          marginBottom: 200,
        }}
      >
        <div className="w-full shadow-md rounded-md bg-white">
          <div className="p-1 flex items-center justify-between mb-3">
            <div className="flex items-center ">
              <div className=" w-[15px] h-[25px] bg-[#45e4df7b] rounded-sm"></div>
              <div className="font-semibold ml-2">All meters </div>
            </div>
            <div className="">
              <DateRangePicker
                setEndDate={setEndDate}
                setStartDate={setStartDate}
                refreshBtn={setRefresh}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 p-2  md:grid-cols-4 lg:grid-cols-3 my-1 gap-4">
            {/* <TopCard title={"All registered users"} value={count} /> */}
            <div
              className={`h-[180px] p-2 border-[1px] border-gray-200 shadow-sm rounded-md`}
            >
              <div className="h-[50px] w-[50px] rounded-full bg-gray-100"></div>
              <div className="pt-1 font-light">
                Get count of all registered meters.
              </div>
              <div className="pt-2 font-bold">Registered Meter.</div>
              <div className="pt-2 font-bold text-3xl">
                {count[2]?.message.count}
              </div>
            </div>
            <div
              className={`h-[180px] p-2 border-[1px] border-gray-200 shadow-sm rounded-md`}
            >
              <div className="h-[50px] w-[50px] rounded-full bg-gray-100"></div>
              <div className="pt-2 font-light">
                {" "}
                Get count of all commissioned meters
              </div>
              <div className="pt-2 font-bold">Commission Meter</div>
              <div className="pt-1 font-bold text-3xl">
                {count[1]?.message.count}
              </div>
            </div>
            <div
              className={`h-[180px] p-2 border-[1px] border-gray-200 shadow-sm rounded-md`}
            >
              <div className="h-[50px] w-[50px] rounded-full bg-gray-100"></div>
              <div className="pt-2 font-light">
                Get count of all decommissioned meters
              </div>
              <div className="pt-1 font-bold">Decommissioned Meter</div>
              <div className="pt-2 font-bold text-3xl">
                {count[0]?.message.count}
              </div>
            </div>
          </div>
          {/* <div className="row mb-3">
            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 my-2">
              <MeterTopCard
                title={""}
                value={""}
                
                showModal={setDecommissionModal}
              />
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 my-2">
              <MeterTopCard
                title={""}
                value={""}
               
                showModal={setCommissionModal}
              />
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 my-2">
              <MeterTopCard
                title={"Registered Meter"}
                value={""}
               
                showModal={setRegisteredModal}
              />
            </div>
          </div> */}
          <div className="row bg-white shadow-sm rounded-md mt-4">
            <TableTop
              title={"All meters"}
              showAddMeter={true}
              setAddMeterModal={setAddMeterModal}
            />
            <div className="h-[500px]">
              {/* <MeterTable data={data} /> */}

              <AllMeterTable data={data} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Meters;

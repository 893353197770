import {
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Group,
  Inject,
  Page,
  Search,
  Toolbar,
  ExcelExport,
  Sort,
} from "@syncfusion/ej2-react-grids";
import moment from "moment";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { useState, useContext } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaUser, FaUsersCog } from "react-icons/fa";
import { themeColor as color } from "../../../../../constant/color";
import { setToken } from "../../../../../utils/token";
import { Button } from "../../../../Button";
import RoleInfoModal from "../../../RoleInfoModal";
import { FcCalendar } from "react-icons/fc";
import DataTable, { TableColumn } from "react-data-table-component";

const AllRolesTable = ({ data }) => {
  const [modal, setModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [roleId, setRoleId] = useState("");
  const [username, setUsername] = useState("");
  let grid = useRef();
  let num = 1;
  let final = data.map((i) => {
    return {
      num: num++,
      ...i,
    };
  });

  const columns = [
    {
      name: "Role Id",
      center: true,
      selector: (row) => row.roleid,
      grow: 1,
    },
    {
      name: "Creator",
      selector: (row) => row.creator,
      center: true,
      sortable: true,
      grow: 2,
      // cell: (item) => {
      //   return (
      //     <div className="flex items-center">
      //       <div>{item.firstname + item.lastname}</div>
      //     </div>
      //   );
      // },
    },
    {
      name: "Role",
      selector: (row) => row.role,
      center: true,
      sortable: true,
      grow: 2,
    },
    {
      name: "Project zone",
      selector: (row) => row.projectzone,
      center: true,
      grow: 2,
    },
    {
      name: "username",
      selector: (row) => row.username,
      center: true,
      grow: 1,
    },

    // {
    //   name: "Duration",
    //   center: true,
    //   selector: (row) => row.sales,
    //   sortable: true,
    //   grow: 2,
    // },
    {
      name: "Date created",
      center: true,
      selector: (row) => row.datecreated,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="px-1">
              <FcCalendar size={18} />
            </div>
            <div className="text-[11px]">
              {moment(item.datecreated).format("lll")}
            </div>
          </div>
        );
      },
      sortable: true,
    },
    // {
    //   name: "Action",
    //   selector: (row) => row.deviceid,
    //   grow: 3,
    //   cell: (item) => {
    //     return (
    //       <div className="flex relative rounded-md items-center justify-between">
    //         <div
    //           onClick={() => {}}
    //           className="border-[1px] cursor-pointer rounded border-gray-400 text-[12px] font-light px-4 py-2"
    //         >
    //           Details
    //         </div>
    //         {item.activestatus == "active" ? (
    //           <div
    //             onClick={() => handleDeactivate(item)}
    //             className="border-[1px] cursor-pointer ml-2 rounded border-red-400 text-[12px] text-red-400 font-light px-4 py-2"
    //           >
    //             Deactivate
    //           </div>
    //         ) : (
    //           <div
    //             onClick={() => handleActivate(item)}
    //             className="border-[1px] cursor-pointer ml-2 rounded border-green-600 text-[12px] text-green-600 font-light px-4 py-2"
    //           >
    //             Activate
    //           </div>
    //         )}
    //       </div>
    //     );
    //   },
    // },
  ];

  const Sample = (item, { selected = selectedRow }) => {
    return (
      <div className="">
        <div
          className="w-75 px-1 d-flex align-items-center justify-content-center rounded-1 no-underline"
          style={{
            border: `1px solid ${color.baseColor}`,
          }}
          onClick={async () => {
            try {
              setRoleId(item?.roleid);
              setModal(true);
              setUsername(item?.username);
            } catch (err) {
              console.log(err.message);
            }
          }}
        >
          <div>
            <FaUsersCog size={18} color={color.baseColor} />
          </div>
          <div
            style={{
              position: "relative",
              cursor: "pointer",
              width: "100px",
            }}
            className="text-center "
            onClick={() => setSelectedRow(item?.id)}
          >
            <Button
              border={`1px solid ${color.baseColor}`}
              text={"View Role"}
              color={color.baseColor}
              fontSize={"11px"}
              fontWeight={"400"}
              height={"30px"}
              bg={color.whiteColor}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {modal && (
        <RoleInfoModal
          closeModal={setModal}
          roleId={roleId}
          username={username}
        />
      )}
      <DataTable
        style={{
          height: "100%",
        }}
        columns={columns}
        data={data}
        pagination
        paginationPerPage={10}
      />
    </div>
  );
};

export default AllRolesTable;

{
  /* <GridComponent
ref={(g) => (grid.current = g)}
id="grid"
style={{
  display: "block",
}}
allowPaging={true}
allowSorting={true}
dataSource={final}
pageSettings={{
  pageSize: 15,
}}
allowExcelExport={false}
toolbar={["Search"]}
width={"100%"}
height={"100%"}
// searchSettings={searchOptions}
>
<ColumnsDirective>
  <ColumnDirective
    field="roleid"
    width="100"
    textAlign="Center"
    headerText="PMS"
    headerTextAlign="Center"
    headerTemplate={() => {
      return (
        <div
          className="st-header"
          style={{
            color: color.darkColor,
            fontSize: "12px",
          }}
        >
          Role Id
        </div>
      );
    }}
  />
  {/* <ColumnDirective
    field="creator"
    width="100"
    textAlign="Center"
    headerText="PMS"
    headerTextAlign="Center"
    headerTemplate={() => {
      return (
        <div
          className="st-header"
          style={{
            color: color.darkColor,
            fontSize: "12px",
          }}
        >
          Creator
        </div>
      );
    }}
  /> */
}
// <ColumnDirective
//   field="role"
//   width="100"
//   textAlign="Center"
//   headerText="PMS"
//   headerTextAlign="Center"
//   headerTemplate={() => {
//     return (
//       <div
//         className="st-header"
//         style={{
//           color: color.darkColor,
//           fontSize: "12px",
//         }}
//       >
//         Role
//       </div>
//     );
//   }}
//   template={(item) => {
//     return (
//       <div
//         style={{
//           color: color.baseColor,
//           fontSize: "13px",
//         }}
//         className="text-center"
//       >
//         {!item?.role ? "nill" : item?.role}
//       </div>
//     );
//   }}
// />
// <ColumnDirective
//   field="projectzone"
//   width="100"
//   textAlign="Center"
//   headerText="PMS"
//   headerTextAlign="Center"
//   headerTemplate={() => {
//     return (
//       <div
//         className="st-header"
//         style={{
//           color: color.darkColor,
//           fontSize: "12px",
//         }}
//       >
//         Project zone
//       </div>
//     );
//   }}
//   template={(item) => {
//     return (
//       <div
//         style={{
//           color: color.darkColor,
//           fontSize: "13px",
//         }}
//         className="text-center"
//       >
//         {!item?.projectzone ? "nill" : item?.projectzone}
//       </div>
//     );
//   }}
// />
// <ColumnDirective
//   field="username"
//   width="150"
//   textAlign="Center"
//   headerText="PMS"
//   headerTextAlign="Center"
//   headerTemplate={() => {
//     return (
//       <div
//         className="st-header"
//         style={{
//           color: color.darkColor,
//           fontSize: "12px",
//         }}
//       >
//         Username
//       </div>
//     );
//   }}
//   template={(item) => {
//     return (
//       <div
//         style={{
//           color: color.darkColor,
//           fontSize: "13px",
//         }}
//         className="text-center"
//       >
//         {!item?.username ? "nill" : item?.username}
//       </div>
//     );
//   }}
// />
// <ColumnDirective
//   field="datecreated"
//   width="100"
//   textAlign="Center"
//   headerText="PMS"
//   headerTextAlign="Center"
//   headerTemplate={() => {
//     return (
//       <div
//         className="st-header"
//         style={{
//           color: color.darkColor,
//           fontSize: "12px",
//         }}
//       >
//         Date created
//       </div>
//     );
//   }}
//   template={(item) => {
//     return (
//       <div
//         style={{
//           fontSize: "13px",
//         }}
//         className="text-center"
//         onClick={() => console.log(selectedRow)}
//       >
//         {!item?.datecreated
//           ? "nill"
//           : moment(item?.datecreated).format("lll")}
//       </div>
//     );
//   }}
// />
// <ColumnDirective
//   field="id"
//   width="100"
//   textAlign="Center"
//   headerText="PMS"
//     headerTextAlign="Center"
//     headerTemplate={() => {
//       return (
//         <div
//           className="st-header"
//           style={{
//             color: color.darkColor,
//             fontSize: "12px",
//           }}
//         >
//           Action
//         </div>
//       );
//     }}
//     template={Sample}
//   />
// </ColumnsDirective>
// <Inject
//   services={[Page, Sort, Filter, Group, Toolbar, Search, ExcelExport]}
// />
// </GridComponent>

import Layout from "../../../components/Layout";
// import UserRolesTable from "../../../components/Roles/Tables";
import TableTop from "../../../components/TableTop";
import TopNav from "../../../components/TopNav";
import { useState, useEffect } from "react";
import AddRoles from "../../../components/Roles/AddRole";
import toast from "react-hot-toast";
import axios from "../../../utils/axios";
import { getToken } from "../../../utils/token";
// import { rolesController } from "../../../controllers/RolesController";
import "./styles.css";
import { themeColor as color } from "../../../constant/color";
// import AllRolesTable from "../../../components/Roles/AllRolesTable";
import { commandController } from "../../../controllers/CommandController";
import AllRolesTable from "../../../components/Roles/Tables/Tables/AllRolesTable";
import UserCreatesRoles from "../../../components/Roles/Tables/Tables/UserCreatesRoles";
const AllRoles = () => {
  const [modal, setModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/roles/getallroles", {
          targetusername: "sysadmin",
        });
        let result = commandController(res);
        if (result.type !== "success") {
          toast.error(result.message);
          return;
        }
        setData(result.message.body);
      } catch (err) {
        if (err.response) {
          return toast.error(err.response.data.response);
        }
        toast.error(err.message);
      }
    })();
  }, [refresh]);

  return (
    <Layout>
      {modal && <AddRoles closeModal={setModal} refreshBtn={setRefresh} />}
      <div className="">
        <div
          className="bg-white h-[90vh] mt-5 rounded-md shadow-md"
          style={{
            marginBottom: "200px",
          }}
        >
          <TableTop
            title={"All users and their roles."}
            showAddRoleBtn={true}
            setAddRoleBtn={setModal}
          />
          <div className="">
            <AllRolesTable data={data} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AllRoles;

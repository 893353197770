import moment from "moment";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { BsThreeDotsVertical, BsInfoCircle } from "react-icons/bs";
import { Button } from "../../../Button";
import { themeColor as color } from "../../../../constant/color";
import { FcCalendar } from "react-icons/fc";
import DataTable, { TableColumn } from "react-data-table-component";

const CustomersTable = ({ data }) => {
  const [selectedRow, setSelectedRow] = useState(null);
  let grid = useRef();
  let num = 1;

  const [selectedId, setSelectedId] = useState(null);
  const [modal, setModal] = useState(false);

  const Sample = (item) => {
    return (
      <div className="dropend">
        <button
          type="button"
          className="btn btn-sm dropdown-toggle menu-button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <BsThreeDotsVertical />
        </button>

        <div
          className="dropdown-menu dropdown-menu-end"
          style={{ padding: 0, fontSize: "12px !important", minWidth: "unset" }}
        >
          <Link
            className="dropdown-item table-dropdown-item"
            to={
              "/customer/" +
              encodeURI(item.personalinfo.username) +
              "/meterdetails/" +
              encodeURI(item.number)
            }
          >
            <BsInfoCircle /> View
          </Link>
        </div>
      </div>
    );
  };

  const columns = [
    {
      name: "customerid",
      center: true,
      selector: (row) => row.customerid,
      grow: 1,
    },
    {
      name: "agent",
      center: true,
      selector: (row) => row.agent,
      grow: 1,
    },

    {
      name: "customertype",
      center: true,
      selector: (row) => row.customertype,
      grow: 1,
    },
    {
      name: "polenumber",
      center: true,
      selector: (row) => row.polenumber,
      grow: 1,
    },
    {
      name: "section",
      center: true,
      selector: (row) => row.customertype,
      grow: 1,
    },
    {
      name: "gps",
      center: true,
      selector: (row) => row.gps,
      grow: 1,
    },
    {
      name: "Meter",
      center: true,
      selector: (row) => row.meter,
      grow: 1,
    },
    {
      name: "zone",
      center: true,
      selector: (row) => row.zone,
      grow: 1,
    },
    {
      name: "Full name",
      // selector: (row) => row.,
      center: true,
      sortable: true,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div>{item.firstname + item.lastname}</div>
          </div>
        );
      },
    },

    {
      name: "Date registered",
      center: true,
      selector: (row) => row.datecreated,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="px-1">
              <FcCalendar size={18} />
            </div>
            <div className="text-[11px]">
              {moment(item.datecreated).format("lll")}
            </div>
          </div>
        );
      },
      sortable: true,
    },
    // {
    //   name: "Action",
    //   selector: (row) => row.deviceid,
    //   grow: 3,
    //   cell: (item) => {
    //     return (
    //       <div className="flex relative rounded-md items-center justify-between">
    //         <div
    //           onClick={() => {}}
    //           className="border-[1px] cursor-pointer rounded border-gray-400 text-[12px] font-light px-4 py-2"
    //         >
    //           Details
    //         </div>
    //         {item.activestatus == "active" ? (
    //           <div
    //             // onClick={() => handleDeactivate(item)}
    //             className="border-[1px] cursor-pointer ml-2 rounded border-red-400 text-[12px] text-red-400 font-light px-4 py-2"
    //           >
    //             Deactivate
    //           </div>
    //         ) : (
    //           <div
    //             // onClick={() => handleActivate(item)}
    //             className="border-[1px] cursor-pointer ml-2 rounded border-green-600 text-[12px] text-green-600 font-light px-4 py-2"
    //           >
    //             Activate
    //           </div>
    //         )}
    //       </div>
    //     );
    //   },
    // },
  ];

  return (
    <DataTable
      style={{
        height: "100%",
      }}
      columns={columns}
      data={data}
      pagination
      paginationPerPage={10}
    />
    // <GridComponent
    //   ref={(g) => (grid.current = g)}
    //   id="grid"
    //   style={{
    //     display: "block",
    //   }}
    //   allowPaging={true}
    //   allowSorting={true}
    //   dataSource={data}
    //   pageSettings={{
    //     pageSize: 15,
    //   }}
    //   enableHover={false}
    //   // frozenColumns={1}
    //   // frozenRows={1}
    //   allowExcelExport={false}
    //   toolbar={["Search"]}
    //   width={"100%"}
    //   height={700}
    //   // searchSettings={searchOptions}
    // >
    //   <ColumnsDirective>
    //     <ColumnDirective
    //       field="customerid"
    //       width="130"
    //       textAlign="Center"
    //       headerText="PMS"
    //       headerTextAlign="Center"
    //       headerTemplate={() => {
    //         return (
    //           <div
    //             className="st-header"
    //             style={{
    //               color: color.darkColor,
    //               fontSize: "12px",
    //             }}
    //           >
    //             Customer id
    //           </div>
    //         );
    //       }}
    //       template={(item) => {
    //         return (
    //           <div
    //             style={{
    //               color: color.baseColor,
    //               fontSize: "13px",
    //             }}
    //             className="text-center"
    //           >
    //             {!item?.customerid ? "No Customer id" : item?.customerid}
    //           </div>
    //         );
    //       }}
    //     />
    //     <ColumnDirective
    //       field="agent"
    //       width="180"
    //       textAlign="Center"
    //       headerText="PMS"
    //       headerTextAlign="Center"
    //       headerTemplate={() => {
    //         return (
    //           <div
    //             className="st-header"
    //             style={{
    //               color: color.darkColor,
    //               fontSize: "12px",
    //             }}
    //           >
    //             Agent
    //           </div>
    //         );
    //       }}
    //       template={(item) => {
    //         return (
    //           <div
    //             style={{
    //               color: color.baseColor,
    //               fontSize: "13px",
    //             }}
    //             className="text-center"
    //           >
    //             {!item?.agent ? "No agent" : item?.agent}
    //           </div>
    //         );
    //       }}
    //     />
    //     <ColumnDirective
    //       field="customertype"
    //       width="150"
    //       textAlign="Center"
    //       headerText="PMS"
    //       headerTextAlign="Center"
    //       headerTemplate={() => {
    //         return (
    //           <div
    //             className="st-header"
    //             style={{
    //               color: color.darkColor,
    //               fontSize: "12px",
    //             }}
    //           >
    //             Customer type
    //           </div>
    //         );
    //       }}
    //       template={(item) => {
    //         return (
    //           <div
    //             style={{
    //               color: color.baseColor,
    //               fontSize: "13px",
    //             }}
    //             className="text-center"
    //           >
    //             {!item?.customertype ? "No customer type" : item?.customertype}
    //           </div>
    //         );
    //       }}
    //     />
    //     <ColumnDirective
    //       field="polenumber"
    //       width="180"
    //       textAlign="Center"
    //       headerText="PMS"
    //       headerTextAlign="Center"
    //       headerTemplate={() => {
    //         return (
    //           <div
    //             className="st-header"
    //             style={{
    //               color: color.darkColor,
    //               fontSize: "12px",
    //             }}
    //           >
    //             Pole number
    //           </div>
    //         );
    //       }}
    //       template={(item) => {
    //         return (
    //           <div
    //             style={{
    //               color: "#575757",
    //               fontSize: "13px",
    //             }}
    //             className="text-center"
    //           >
    //             {!item?.polenumber ? "No pole number" : item?.polenumber}
    //           </div>
    //         );
    //       }}
    //     />
    //     <ColumnDirective
    //       field="section"
    //       width="180"
    //       textAlign="Center"
    //       headerText="PMS"
    //       headerTextAlign="Center"
    //       headerTemplate={() => {
    //         return (
    //           <div
    //             className="st-header"
    //             style={{
    //               color: color.darkColor,
    //               fontSize: "12px",
    //             }}
    //           >
    //             section
    //           </div>
    //         );
    //       }}
    //       template={(item) => {
    //         return (
    //           <div
    //             style={{
    //               color: "#575757",
    //               fontSize: "13px",
    //             }}
    //             className="text-center"
    //           >
    //             {!item?.section ? "No section" : item?.section}
    //           </div>
    //         );
    //       }}
    //     />
    //     <ColumnDirective
    //       field="gps"
    //       width="180"
    //       textAlign="Center"
    //       headerText="PMS"
    //       headerTextAlign="Center"
    //       headerTemplate={() => {
    //         return (
    //           <div
    //             className="st-header"
    //             style={{
    //               color: color.darkColor,
    //               fontSize: "12px",
    //             }}
    //           >
    //             GPS
    //           </div>
    //         );
    //       }}
    //       template={(item) => {
    //         return (
    //           <div
    //             style={{
    //               color: "#575757",
    //               fontSize: "13px",
    //             }}
    //             className="text-center"
    //           >
    //             {!item?.gps ? "No gps assigned" : item?.gps}
    //           </div>
    //         );
    //       }}
    //     />

    //     <ColumnDirective
    //       field="metertype"
    //       width="180"
    //       textAlign="Center"
    //       headerText="PMS"
    //       headerTextAlign="Center"
    //       headerTemplate={() => {
    //         return (
    //           <div
    //             className="st-header"
    //             style={{
    //               color: color.darkColor,
    //               fontSize: "12px",
    //             }}
    //           >
    //             Meter type
    //           </div>
    //         );
    //       }}
    //       template={(item) => {
    //         return (
    //           <div
    //             style={{
    //               color: "#575757",
    //               fontSize: "13px",
    //             }}
    //             className="text-center"
    //           >
    //             {!item?.metertype ? "No meter type" : item?.metertype}
    //           </div>
    //         );
    //       }}
    //     />
    //     <ColumnDirective
    //       field="dumbmeterfee"
    //       width="180"
    //       textAlign="Center"
    //       headerText="PMS"
    //       headerTextAlign="Center"
    //       headerTemplate={() => {
    //         return (
    //           <div
    //             className="st-header"
    //             style={{
    //               color: color.darkColor,
    //               fontSize: "12px",
    //             }}
    //           >
    //             Dumb meterfee
    //           </div>
    //         );
    //       }}
    //       template={(item) => {
    //         return (
    //           <div
    //             style={{
    //               color: "#575757",
    //               fontSize: "13px",
    //             }}
    //             className="text-center"
    //           >
    //             {!item?.dumbmeterfee ? "No dumbfee" : item?.dumbmeterfee}
    //           </div>
    //         );
    //       }}
    //     />
    //     <ColumnDirective
    //       field="connectionbalance"
    //       width="180"
    //       textAlign="Center"
    //       headerText="PMS"
    //       headerTextAlign="Center"
    //       headerTemplate={() => {
    //         return (
    //           <div
    //             className="st-header"
    //             style={{
    //               color: color.darkColor,
    //               fontSize: "12px",
    //             }}
    //           >
    //             Connectionbalance
    //           </div>
    //         );
    //       }}
    //       template={(item) => {
    //         return (
    //           <div
    //             style={{
    //               color: "#575757",
    //               fontSize: "13px",
    //             }}
    //             className="text-center"
    //           >
    //             {!item?.connectionbalance
    //               ? "No connection balance "
    //               : item?.connectionbalance}
    //           </div>
    //         );
    //       }}
    //     />
    //     <ColumnDirective
    //       field="connectionfee"
    //       width="180"
    //       textAlign="Center"
    //       headerText="PMS"
    //       headerTextAlign="Center"
    //       headerTemplate={() => {
    //         return (
    //           <div
    //             className="st-header"
    //             style={{
    //               color: color.darkColor,
    //               fontSize: "12px",
    //             }}
    //           >
    //             Connection fee
    //           </div>
    //         );
    //       }}
    //       template={(item) => {
    //         return (
    //           <div
    //             style={{
    //               color: "#575757",
    //               fontSize: "13px",
    //             }}
    //             className="text-center"
    //           >
    //             {!item?.connectionfee
    //               ? "No connection fee "
    //               : item?.connectionfee}
    //           </div>
    //         );
    //       }}
    //     />
    //     <ColumnDirective
    //       field="maxperiodiccharge"
    //       width="180"
    //       textAlign="Center"
    //       headerText="PMS"
    //       headerTextAlign="Center"
    //       headerTemplate={() => {
    //         return (
    //           <div
    //             className="st-header"
    //             style={{
    //               color: color.darkColor,
    //               fontSize: "12px",
    //             }}
    //           >
    //             Max periodiccharge
    //           </div>
    //         );
    //       }}
    //       template={(item) => {
    //         return (
    //           <div
    //             style={{
    //               color: "#575757",
    //               fontSize: "13px",
    //             }}
    //             className="text-center"
    //           >
    //             {!item?.maxperiodiccharge
    //               ? "No periodic charge "
    //               : item?.maxperiodiccharge}
    //           </div>
    //         );
    //       }}
    //     />
    //     <ColumnDirective
    //       field="meter"
    //       width="180"
    //       textAlign="Center"
    //       headerText="PMS"
    //       headerTextAlign="Center"
    //       headerTemplate={() => {
    //         return (
    //           <div
    //             className="st-header"
    //             style={{
    //               color: color.darkColor,
    //               fontSize: "12px",
    //             }}
    //           >
    //             Meter
    //           </div>
    //         );
    //       }}
    //       template={(item) => {
    //         return (
    //           <div
    //             style={{
    //               color: "#575757",
    //               fontSize: "13px",
    //             }}
    //             className="text-center"
    //           >
    //             {!item?.meter ? "No meter number" : item?.meter}
    //           </div>
    //         );
    //       }}
    //     />
    //     <ColumnDirective
    //       field="zone"
    //       width="180"
    //       textAlign="Center"
    //       headerText="PMS"
    //       headerTextAlign="Center"
    //       headerTemplate={() => {
    //         return (
    //           <div
    //             className="st-header"
    //             style={{
    //               color: color.darkColor,
    //               fontSize: "12px",
    //             }}
    //           >
    //             zone
    //           </div>
    //         );
    //       }}
    //       template={(item) => {
    //         return (
    //           <div
    //             style={{
    //               color: "#575757",
    //               fontSize: "13px",
    //             }}
    //             className="text-center"
    //           >
    //             {!item?.zone ? "No zone available" : item?.zone}
    //           </div>
    //         );
    //       }}
    //     />
    //     <ColumnDirective
    //       field="datecreated"
    //       width="180"
    //       textAlign="Center"
    //       headerText="PMS"
    //       headerTextAlign="Center"
    //       headerTemplate={() => {
    //         return (
    //           <div
    //             className="st-header"
    //             style={{
    //               color: color.darkColor,
    //               fontSize: "12px",
    //             }}
    //           >
    //             Date created
    //           </div>
    //         );
    //       }}
    //       template={(item) => {
    //         return (
    //           <div
    //             style={{
    //               fontSize: "13px",
    //             }}
    //             className="text-center"
    //             onClick={() => console.log(selectedRow)}
    //           >
    //             {moment(item?.datecreated).format("DD-MM-YYYY HH:mm")}
    //           </div>
    //         );
    //       }}
    //     />
    //     <ColumnDirective
    //       field="datedecommissioned"
    //       width="180"
    //       textAlign="Center"
    //       headerText="PMS"
    //       headerTextAlign="Center"
    //       headerTemplate={() => {
    //         return (
    //           <div
    //             className="st-header"
    //             style={{
    //               color: color.darkColor,
    //               fontSize: "12px",
    //             }}
    //           >
    //             Dated decommissioned
    //           </div>
    //         );
    //       }}
    //       template={(item) => {
    //         return (
    //           <div
    //             style={{
    //               fontSize: "13px",
    //             }}
    //             className="text-center"
    //             onClick={() => console.log(selectedRow)}
    //           >
    //             {moment(item?.datedecommissioned).format("DD-MM-YYYY HH:mm")}
    //           </div>
    //         );
    //       }}
    //     />
    //     <ColumnDirective
    //       field="number"
    //       width="200"
    //       textAlign="Center"
    //       headerText="PMS"
    //       headerTextAlign="Center"
    //       headerTemplate={() => {
    //         return (
    //           <div
    //             className="st-header"
    //             style={{
    //               color: color.darkColor,
    //               fontSize: "12px",
    //             }}
    //           >
    //             Action
    //           </div>
    //         );
    //       }}
    //       template={Sample}
    //     />
    //     {/* <ColumnDirective
    //       field="id"
    //       width="200"
    //       textAlign="Center"
    //       headerText="PMS"
    //       headerTextAlign="Center"
    //       headerTemplate={() => {
    //         return (
    //           <div
    //             className="st-header"
    //             style={{
    //               color: color.darkColor,
    //               fontSize: "12px",
    //             }}
    //           >
    //             Action
    //           </div>
    //         );
    //       }}
    //       template={Sample}
    //     /> */}
    //   </ColumnsDirective>
    //   <Inject
    //     services={[
    //       Page,
    //       Sort,
    //       Filter,
    //       Group,
    //       Toolbar,
    //       Search,
    //       ExcelExport,
    //       Freeze,
    //     ]}
    //   />
    // </GridComponent>
  );
};

export default CustomersTable;

{
  /* <div className="d-flex align-items-center justify-content-center">
<div
  className="d-flex mx-2 align-items-center justify-content-center rounded-1 text-decoration-none"
  style={{
    border: `1px solid ${color.baseColor}`,
    width: "80px",
  }}
>
  <div
    style={{
      position: "relative",
      cursor: "pointer",
    }}
    className="text-center "
    onClick={() => {
      setModal(true);
    }}
  >
    <Button
      border={`1px solid ${color.baseColor}`}
      text={"View Info"}
      color={color.baseColor}
      fontSize={"11px"}
      fontWeight={"400"}
      height={"30px"}
      bg={color.whiteColor}
    />
  </div>
</div>
<Link
  to="/dashboard/systemadmin/meters/:id"
  className=" d-flex align-items-center justify-content-center rounded-1 text-decoration-none"
  style={{
    border: `1px solid ${color.baseColor}`,
    width: "80px",
  }}
>
  <div
    style={{
      position: "relative",
      cursor: "pointer",
    }}
    className="text-center "
    onClick={() => {
      setModal(true);
    }}
  >
    <Button
      border={`1px solid ${color.baseColor}`}
      text={"View settings"}
      color={color.baseColor}
      fontSize={"11px"}
      fontWeight={"400"}
      height={"30px"}
      bg={color.whiteColor}
    />
  </div>
</Link>
</div> */
}

import Layout from "../../components/Layout";
import TopNav from "../../components/TopNav";
import UserTable from "../../components/Users/Tables/UserTable";
import TableTop from "../../components/TableTop";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "../../utils/axios";
import { userController } from "../../controllers/UserController";
import TopCard from "../../components/TopCard";
import React from "react";
import { Button } from "../../components/Button";

/** AntD Table */
import AntDTable from "../../components/Table/AntdTable";
import { CamelCaseToTitleCase } from "../../../src/utils/helpers";
import { themeColor as color } from "../../constant/color";
import TableSkimmer from "../../components/TableSkimmer";
import { getToken } from "../../utils/token";
import { Select } from "antd";

const Users = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [domain, setdomain] = useState("");
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      try {
        // const storedDomain = getToken("spiral_domain");
        // setdomain(storedDomain);
        setLoading(true);
        let res = await axios.post(
          "/auth/customeraccount/getallregisteredusers",
          {
            limit: 100,
            offset: 0,
          }
        );
        let result = userController(res);
        if (result.type !== "success") {
          toast[result.type](result.message);
          setLoading(false);
          return;
        }
        setLoading(false);
        setData(result.message.body);
        setCount(result.message.meta.count);
        return () => {
          controller.abort();
        };
      } catch (err) {
        toast.error(err.message);
        setLoading(false);
      }
    })();
  }, [refresh]);

  return (
    <Layout>
      <div
        style={{
          marginBottom: 200,
        }}
      >
        <div className=" ">
          <div className="mt-6">
            <div className=" bg-white p-2 rounded-md shadow-md">
              <div className="h-[50px]">
                <div className="flex items-center ">
                  <div className=" w-[15px] h-[25px] bg-[#e0c5ff] rounded-sm"></div>
                  <div className="font-semibold ml-2">
                    Total registered user in {domain}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-3 my-1 gap-4">
                {/* <TopCard title={"All registered users"} value={count} /> */}
                <div
                  className={`h-[150px] p-2 border-[1px] border-gray-200 shadow-sm rounded-md`}
                >
                  <div className="h-[50px] w-[50px] rounded-full bg-gray-100"></div>
                  <div className="pt-2 font-light">Total Registered Users</div>
                  <div className="pt-2 font-bold text-3xl">{count}</div>
                </div>
                <div
                  className={`h-[150px] p-2 border-[1px] border-gray-200 shadow-sm rounded-md`}
                >
                  <div className="h-[50px] w-[50px] rounded-full bg-gray-100"></div>
                  <div className="pt-2 font-light">Total Active Users</div>
                  <div className="pt-2 font-bold text-3xl">0</div>
                </div>
                <div
                  className={`h-[150px] p-2 border-[1px] border-gray-200 shadow-sm rounded-md`}
                >
                  <div className="h-[50px] w-[50px] rounded-full bg-gray-100"></div>
                  <div className="pt-2 font-light">Total Suspended Users</div>
                  <div className="pt-2 font-bold text-3xl">0</div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 bg-white  rounded-md px-2 shadow-md ">
            <div
              className="grid grid-cols-1 h-[80vh] md:grid-cols-1 lg:grid-cols-1"
              style={{}}
            >
              <div className="">
                {loading ? (
                  <TableSkimmer entries={12} />
                ) : (
                  <>
                    <div className="flex items-center pt-4 justify-end">
                      <Select
                        style={{
                          width: 150,
                        }}
                        defaultValue={"all"}
                        className="w-[150px]"
                        options={[
                          { value: "all", label: "All users" },
                          { value: "active", label: "Active users" },
                          { value: "inactive", label: "Inactive users" },
                        ]}
                      />
                    </div>

                    <UserTable
                      columns={columns}
                      data={data}
                      refreshBtn={setRefresh}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Users;

// const result = React.useMemo(() => {
//   if (data.length) {
//     const columnsData = Object.keys(data[0]).map((column) => {
//       const columnObj = {
//         title: camelCaseToTitleCase(column),
//         dataIndex: column,
//         key: column,
//       };
//       if (column === "activestatus") {
//         columnObj.render = (activeStatus) => {
//           return (
//             <div
//               style={{
//                 color: `${activeStatus === "active" ? "green" : "orange"}`,
//               }}
//             >
//               {activeStatus}
//             </div>
//           );
//         };
//       }
//       if (column === "walletbalance") {
//         columnObj.render = (walletbalance) => {
//           return (
//             <div
//               style={{
//                 color: `${color.baseColor}`,
//               }}
//             >
//               {walletbalance}
//             </div>
//           );
//         };
//       }
//       return columnObj;
//     });
//     columnsData[columnsData.length] = {
//       title: "Action",
//       dataIndex: "action",
//       key: "action",
//       render: (_, { activestatus }) => {
//         return (
//           <div
//             className="w-75 flex items-center justify-center rounded-sm no-underline"
//             style={{
//               border: `1px solid ${
//                 activestatus === "active" ? "red" : color.baseColor
//               }`,
//             }}
//           >
//             <div
//               style={{
//                 position: "relative",
//                 cursor: "pointer",
//                 width: "80px",
//               }}
//               className="text-center"
//             >
//               <Button
//                 border={`1px solid ${
//                   activestatus === "active" ? "red" : color.baseColor
//                 }`}
//                 text={`${
//                   activestatus === "active" ? "Deactivate" : "Activate"
//                 }`}
//                 color={`${
//                   activestatus === "active" ? "red" : color.baseColor
//                 }`}
//                 fontSize={"11px"}
//                 fontWeight={"400"}
//                 height={"30px"}
//                 bg={"transparent"}
//               />
//             </div>
//           </div>
//         );
//       },
//     };
//     setColumns(columnsData);
//     return data.map((item) => {
//       item.firstname = item.firstname || "N/A";
//       item.lastname = item.firstname || "N/A";
//       item.email = item.email || "N/A";
//       item.username = item.username || "N/A";
//       return {
//         ...item,
//       };
//     });
//   }
// }, [data]);

import { IoCloseOutline } from "react-icons/io5";
const CloseButton = ({ closeBtn }) => {
  return (
    <div
      className="flex items-center justify-center pt-2 text-secondary cursor-pointer font-light text-[13px]"
      onClick={() => closeBtn(false)}
    >
      {/* <IoCloseOutline size={21} /> */}
      Close
    </div>
  );
};

export default CloseButton;

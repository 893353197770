import moment from "moment";
import { NotificationFormatter } from "../../../../helperFuncs";
import { FcCalendar } from "react-icons/fc";

const MeterClassCard = ({ data, setdetails, setmodal }) => {
  return (
    <div
      className="my-2 border-b-[1px] py-2 cursor-pointer"
      onClick={() => {
        setdetails(data);
        setmodal(true);
      }}
    >
      <div className="flex items-center justify-between ">
        <div className="">{data?.meterclass}</div>
        <div className="flex items-center text-[13px] text-right font-light">
          <div className="flex items-center justify-center">
            <FcCalendar />
          </div>
          <div className="">{moment(data?.datentered).format("lll")}</div>
        </div>
      </div>
      <div className="flex items-end mt-2 justify-between w-full">
        <div className="">
          <div className="text-[13px] font-light">Description</div>
          <div className="font-semibold">{data?.description}...</div>
        </div>
        <div className="text-primary text-[14px] font-normal">
          <div className="text-[13px] font-lighter text-right">
            Time of use:
          </div>
          <div className="font-semibold text-right">
            {data?.tou.split("#").splice(1).length}
          </div>
          {/* <span className="underline text-[14px] text-black font-light">
            (View plan)
          </span> */}
        </div>
      </div>
    </div>
  );
};

export default MeterClassCard;

import { useState } from "react";
import { themeColor as color } from "../../../constant/color";
import CardTitle from "../../CardTitle";
import LineCharts from "../Charts/LineCharts";
import "./styles.css";
import { Select } from "antd";

const PowerQuality = () => {
  return <LineCharts />;
};

export default PowerQuality;

// <div className="flex items-center justify-between ">
//         <CardTitle title={"Power Quality"} caption={"Monitor power quality"} />
//         <div className="">
//           <Select
//             defaultValue={"today"}
//             className="w-[200px]"
//             options={[
//               { value: "today", label: "Today" },
//               { value: "week", label: "This week" },
//             ]}
//           />
//           <div className="hidden align-items-center justify-content-between my-2 justify-content-sm-center ">
//             <div
//               className="pqdfbtn p-2 text-center"
//               style={{
//                 background: tabState == 1 ? "#6418c2" : "#f5edff",
//                 color: tabState == 1 ? color.whiteColor : color.baseColor,
//               }}
//               onClick={() => setTabState(1)}
//             >
//               Month
//             </div>
//             <div
//               className="pqdfbtn p-2 text-center"
//               onClick={() => setTabState(2)}
//               style={{
//                 background: tabState == 2 ? "#6418c2" : "#f5edff",
//                 color: tabState == 2 ? color.whiteColor : color.baseColor,
//               }}
//             >
//               Week
//             </div>
//             <div
//               className="pqdfbtn p-2 text-center "
//               onClick={() => setTabState(3)}
//               style={{
//                 background: tabState == 3 ? "#6418c2" : "#f5edff",
//                 color: tabState == 3 ? color.whiteColor : color.baseColor,
//               }}
//             >
//               Today
//             </div>
//           </div>
//         </div>
//       </div>

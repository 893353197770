import Layout from "../../../components/Layout";
import PageTitle from "../../../components/PageTitle";
import moment from "moment";
import { useState } from "react";

import SalesLineChart from "../../../components/Dashboards/SalesAgent/Charts/SalesLineChart";
import DateRangePicker from "../../../components/DateRangePicker";
import CustomerTable from "../../../components/Dashboards/SalesAgent/Table/CustomerTable";
import { HiBuildingLibrary } from "react-icons/hi2";
import { GiPayMoney, GiWallet } from "react-icons/gi";
import { themeColor } from "../../../constant/color";
import { useParams } from "react-router-dom";

const SalesAgentDashboard = () => {
  const percentage = 66;
  const { id } = useParams();
  const [startDate, setStartDate] = useState(
    moment(Date.now()).startOf("day").format("lll")
  );
  const [endDate, setsetendDate] = useState(moment(Date.now()).format("lll"));

  const AgentTopCard = ({ title, value, caption, icon }) => {
    return (
      <div className="bg-white h-[120px] rounded drop-shadow-md p-4 flex items-center justify-between">
        <div className="">
          <div className="font-normal text-1xl text-gray-600">{title}</div>
          <div className="font-light text-[13px] text-gray-500 w-[70%]">
            {caption}
          </div>
          <div className="font-semibold text-[20px] mt-2 text-gray-800">
            {value}
          </div>
        </div>
        <div className="flex items-center justify-center w-[100px] h-[80px]">
          {icon == "balance" ? (
            <GiWallet size={45} color={"#6b48ff"} />
          ) : icon == "credit" ? (
            <GiPayMoney size={45} color={"#6b48ff"} />
          ) : (
            <HiBuildingLibrary size={45} color={"#6b48ff"} />
          )}
        </div>
      </div>
    );
  };
  return (
    <Layout>
      <div className="flex items-center justify-between">
        <PageTitle
          title={"Welcome to Sales Agent dashboard"}
          caption={"Insight of sales you have made"}
        />
        <div className="">
          <DateRangePicker />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 lg:grid-cols-3 mt-6">
        <AgentTopCard
          title={"Your wallet balance"}
          value={"₦4,635.00"}
          caption={`from ${startDate} to ${endDate}`}
          icon={"balance"}
        />
        <AgentTopCard
          title={"Total credit made "}
          value={"₦3,478.00"}
          caption={`from ${startDate} to ${endDate}`}
          icon={"credit"}
        />
        <AgentTopCard title={"Site Managed"} value={id} />
      </div>
      <div className="bg-white h-[460px] rounded drop-shadow-md my-6 p-4">
        <div className="flex items-start">
          <div className="font-semibold">
            Wallet balance and Credit logs analytics
          </div>
        </div>
        <div className="h-[400px]">
          <SalesLineChart />
        </div>
      </div>
      <div className="bg-white h-[700px] rounded drop-shadow-md pt-4 my-6 hidden">
        <div className="flex items-start justify-between px-6">
          <div className="font-semibold">Customer in project site</div>
          <div className=""></div>
        </div>
        {/* <div className="">
          <CustomerTable />
        </div> */}
      </div>
    </Layout>
  );
};

export default SalesAgentDashboard;

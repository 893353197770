import moment from "moment";
import { FcCalendar } from "react-icons/fc";
import { Link } from "react-router-dom";
import { HiUserGroup } from "react-icons/hi";
import { useState } from "react";
import EmptyState from "../../../EmptyState";
const WalletHistoryTable = ({ data = [], setdetails, setmodal }) => {
  return (
    <div>
      {!data.length ? (
        <EmptyState>
          <div className="h-[70vh] w-full">
            <div className="flex items-center justify-center mt-[200px]">
              <HiUserGroup size={30} />
            </div>
            <div className="font-normal text-[15px] text-center">
              You have not performed any wallet transaction
            </div>
          </div>
        </EmptyState>
      ) : (
        <table className="w-full border-collapse">
          <thead className="h-[50px] drop-shadow-md border-b-[0.5px] border-gray-800">
            <th className="font-semibold text-gray-800">S/N</th>
            <th className="font-semibold text-gray-800">Amount</th>

            <th className="font-semibold text-gray-800">Date created</th>
            {/* <th className="font-semibold text-gray-800">Time range</th> */}
            <th className="font-semibold text-gray-800">Action</th>
          </thead>
          <tbody>
            {data.map((i, index) => (
              <tr
                className="bg-white hover:bg-gray-50 cursor-pointer h-[50px] even:bg-gray-50"
                key={i?.roleid}
              >
                <td className="text-center">{index + 1}</td>
                <td className="text-center">{i?.amount}</td>

                <td className="text-center">
                  <div className="flex items-center justify-center">
                    <div className="pl-2">{moment(i?.date).format("lll")}</div>
                  </div>{" "}
                </td>

                <td className="text-center flex justify-between">
                  <div className="border-[1px] rounded border-secondary text-[12px] text-secondary font-light px-4 py-2">
                    View
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default WalletHistoryTable;

import { useEffect, useState } from "react";
import Layout from "../../../components/Layout";
import AddDropdown from "../../../components/Settings/AddDropdown";
import DropdownTable from "../../../components/Settings/DropdownTable";
import TableTop from "../../../components/TableTop";
import TopNav from "../../../components/TopNav";
import axios from "../../../utils/axios";
import toast from "react-hot-toast";
import { dropdownData } from "./data";
import TopNavTitle from "../../../components/TopNav/TopNavTitle";

const DropDownSettings = () => {
  const [modal, setModal] = useState(false);
  const [dropdowns, setDropdowns] = useState(dropdownData);
  const [loading, setloading] = useState(false);

  const getDropdownItems = () => {
    let count = 1;
    for (let dropdown of dropdownData) {
      axios
        .post("/settings/getdropdown", { comboname: dropdown.name })
        .then((response) => {
          setloading(true);
          // console.log(response);
          // setDropDownList(response.data);
          setDropdowns((curr) => {
            let newDropDowns = [...curr];
            let newDropDown = newDropDowns.find((e) => e.name == dropdown.name);
            newDropDown.items = !response.data.list ? "" : response.data.list;
            newDropDown.date = !response.data.date ? "-" : response.data.date;
            // console.log(newDropDowns);
            return newDropDowns;
          });
          setloading(false);
        })
        .then((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    getDropdownItems();
  }, []);

  return (
    <>
      <Layout>
        {modal && (
          <AddDropdown
            closeModal={setModal}
            dropdowns={dropdowns}
            refresh={getDropdownItems}
          />
        )}
        <div className="bg-white rounded-md shadow-md mt-4 h-[90vh]">
          <div className=" ">
            <TableTop
              title={"Create a dropdown list."}
              showAddSite
              setAddSiteBtn={setModal}
              addSiteBtnText="Set dropdown"
            />
          </div>
          <div className="">
            <DropdownTable dropdowns={dropdownData} loading={loading} />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default DropDownSettings;

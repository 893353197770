import moment from "moment";

export const sampleData = [
  {
    number: "3478",
    type: "Meter",
    phases: "three",
    registrar: "Kunle",
    powerstatus: "active",
    loadlimit: 320,
    dateregistered: moment(Date.now()).subtract("1", "week"),
    datedecommissioned: Date.now(),
  },
];

import { BiUserPlus } from "react-icons/bi";
import "./styles.css";
import AddSiteImg from "../../../assets/svg/siteimg.svg";
import { useEffect, useState } from "react";
import { Select } from "antd";
import "antd/dist/antd.css";
import { themeColor as color, themeColor } from "../../../constant/color";
import { Button } from "../../Button";
import axios from "../../../utils/axios";
import toast from "react-hot-toast";
import { siteFields } from "./siteFields";
import { BsPen } from "react-icons/bs";
import { channelController } from "../../../controllers/channelController";

const EditSite = ({ closeModal, siteTypes, site, refresh }) => {
  const [loading, setLoading] = useState({
    newsitename: false,
    sitetype: false,
    gps: false,
  });
  const [sitename, setSitename] = useState("");
  const [newsitename, setNewSitename] = useState("");
  const [sitetype, setSitetype] = useState(null);
  const [gps, setGps] = useState("");

  const { Option } = Select;

  const editProjectSite = (field, value) => {
    let siteField = siteFields[field];
    let error = siteField.getError(value);
    if (error) return toast(error);

    setLoading((curr) => {
      let newLoading = { ...curr };
      newLoading[field] = true;
      return newLoading;
    });

    let body = { sitename };
    body[field] = value;
    // console.log(body, "body", site);
    axios
      .post(siteField.url, body)
      .then((response) => {
        setLoading((curr) => {
          let newLoading = { ...curr };
          newLoading[field] = false;
          return newLoading;
        });

        let result = channelController(response);
        if (result.type !== "success") {
          toast[result.type](result.message);
          return;
        }

        toast.success(siteField.friendlyName + " has been updated");
        refresh();
        // closeModal(null);
      })
      .catch((err) => {
        setLoading((curr) => {
          let newLoading = { ...curr };
          newLoading[field] = false;
          return newLoading;
        });
        toast(err.message);
      });
  };

  useEffect(() => {
    setSitename(site.sitename);
    setNewSitename(site.sitename);
    setSitetype(site.sitetype);
    setGps(site.gps);
  }, []);

  return (
    <div className="backdrop">
      <div className="bg-white w-[95%] md:w-[450px] p-4 rounded-md shadow-md animate__animated animate__fadeInUp ">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <BsPen size={14} className="" />
            <div className=" ml-2 font-semibold">
              Edit Project site {site?.sitename}
            </div>
          </div>
          <di
            className="text-secondary cursor-pointer"
            onClick={() => closeModal(null)}
          >
            Close
          </di>
        </div>
        <div className="">You can updated any of the following field</div>
        <div className="w-100 mt-5 flex justify-between border-[1px] border-gray-500 p-[1px] rounded-sm">
          <input
            type="text"
            className="w-full px-1"
            placeholder={"Enter site name."}
            value={newsitename}
            onChange={(e) => setNewSitename(e.target.value)}
          />
          <div
            style={{
              width: 80,
            }}
          >
            <Button
              bg={themeColor.secondary}
              text="Update"
              height={"30px"}
              status={loading.newsitename}
              onClick={() => editProjectSite("newsitename", newsitename)}
            />
          </div>
        </div>
        <div className=" w-100 mt-3 flex justify-between">
          <Select
            style={{ width: "100%", fontSize: "1rem" }}
            allowClear
            size="large"
            placeholder="Select site type."
            value={sitetype}
            onChange={(val) => setSitetype(val)}
          >
            {siteTypes.map((type) => (
              <Option value={type} key={type}>
                {type}
              </Option>
            ))}
          </Select>
          <div className="rounded-1" style={{ width: 80 }}>
            <Button
              text="Update"
              height={"35px"}
              bg={themeColor.secondary}
              status={loading.sitetype}
              onClick={() => editProjectSite("sitetype", sitetype)}
            />
          </div>
        </div>
        <div className="w-100 mt-5 flex justify-between border-[1px] border-gray-500 p-[1px] rounded-sm">
          <input
            type="text"
            className="w-full px-1 "
            placeholder={"Enter GPS location."}
            value={gps}
            onChange={(e) => setGps(e.target.value)}
          />
          <div
            className=""
            style={{
              width: 80,
            }}
          >
            <Button
              text="Update"
              height={"35px"}
              onClick={() => editProjectSite("gps", gps)}
              status={loading.gps}
              bg={themeColor.secondary}
            />
          </div>
        </div>
        <div className=" mt-2">
          {/* <div
              className="addrole-btn rounded-1"
              onClick={() => closeModal(null)}
            >
              <Button
                text="Cancel"
                bg={color.btnFadeColor}
                color={color.darkColor}
                height={"40px"}
              />
            </div> */}
          <div className="w-full" onClick={() => closeModal(null)}>
            <Button text="Done" height={"40px"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSite;

import Backdrop from "../../Backdrop";
import CloseButton from "../../CloseButton";
import Rule from "../../Rule/Index";
import { useForm } from "react-hook-form";
import ErrorText from "../../Errors/ErrorText";
import { useState, useEffect } from "react";
import {
  AiFillEye,
  AiOutlineEye,
  AiOutlineKey,
  AiOutlinePhone,
  AiOutlineUser,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import { Button } from "../../Button";
import { toast } from "react-hot-toast";
import { channelController } from "../../../controllers/channelController";
import axios from "../../../utils/axios";
import { Select } from "antd";
import { userController } from "../../../controllers/UserController";
import { getToken } from "../../../utils/token";
import { meterController } from "../../../controllers/MeterController";

const AddCustomer = ({ open, setopen }) => {
  const [eye, seteye] = useState(false);
  const [tabstate, settabstate] = useState(1);
  const [password, setpassword] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [showInput, setShowInput] = useState(false);
  const [tabState, setTabState] = useState(1);
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [fullName, setfullName] = useState(null);
  const [selectedSite, setSelectedSite] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedMeterType, setselectedMeterType] = useState("");
  const [metertype, setMeterType] = useState([]);
  const [uniquesections, setUniquesections] = useState([]);
  const [customerType, setCustomerType] = useState([]);
  const { Option } = Select;
  const [site, setSite] = useState([]);
  const [poleNumber, setpoleNumber] = useState("");
  const [customerId, setcustomerId] = useState("");
  const [meterId, setMeterId] = useState("");
  const [address, setAddress] = useState("");
  const [gps, setgps] = useState("");
  const [connectionfee, setConnectionfee] = useState("");
  const [selectedCustomerType, setselectedCustomerType] = useState("");
  const [selectedAddress, setselectedAddress] = useState("");
  const [userloading, setuserloading] = useState(false);
  const [userObj, setuserObj] = useState(null);

  const [name, setname] = useState("");

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       let res = await axios.post("/sitemanagement/getallsitenames", {});
  //       setSite(res.data.body);
  //       // console.log(res.data.body, "site names");
  //     } catch (err) {
  //       toast.error(err.message);
  //     }
  //   })();
  // }, []);

  useEffect(() => {
    (async () => {
      try {
        let username = await getToken("spiral_username");
        let activesite = await getToken("spiral_site");
        setSelectedSite(activesite);
        let res = Promise.all([
          axios.post("/meter/getuniquemetertypes", {}),
          axios.post("/meter/getuniquesections", {
            projectzone: JSON.stringify([activesite]),
            subdomain: "IKJ",
          }),
          axios.post("/settings/getdropdown", {
            comboname: "customertype",
          }),
        ]);

        res
          .then((res) => {
            setMeterType(res[0].data?.body);
            setUniquesections(res[1].data?.body);
            setCustomerType(
              !res[2].data?.list ? [] : res[2].data?.list.split(", ")
            );
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, []);

  const handleAddCustomer = async () => {
    try {
      console.log(
        "customerid:",
        name,
        "address:",
        address,
        "customertype:",
        selectedCustomerType,
        "gps:",
        gps,
        "pole number:",
        poleNumber,
        "site:",
        selectedSite
      );
      if (
        !(
          name &&
          address &&
          selectedCustomerType &&
          gps &&
          poleNumber &&
          selectedSite
        )
      )
        return toast.error("All values are required");
      setLoading(true);
      let res = await axios.post("/customer/registercustomeraddress", {
        customerid: name,
        // meternumber: meterId,
        address: address,
        customertype: selectedCustomerType,
        section: selectedSection,
        gps: gps,
        polenumber: poleNumber,
        projectzone: selectedSite,
        // metertype: selectedMeterType,
      });
      let result = meterController(res);

      if (result.type !== "success") {
        toast[result.type](result.message);
        setLoading(false);
        return;
      }
      setLoading(false);
      toast.success("Meter issued to customer successfully");
      setopen(false);
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  };

  const handleUserName = async (data) => {
    try {
      if (!name) return toast.error("Please enter a username");
      setuserloading(true);
      let res = await axios.post("/auth/useraccount/getfirstandlastname", {
        username: name,
      });

      let result = userController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setuserloading(false);
        return;
      }

      if (!result.message.firstname && !result.message.lastname) {
        toast.error("You can only assign roles to verified account.");
        setuserloading(false);
        return;
      }
      setuserloading(false);
      setuserObj(result.message);
      settabstate(2);
    } catch (err) {
      toast.error(err.message);
      setuserloading(false);
    }
  };
  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[400px] bg-white drop-shadow-md rounded-sm p-4">
          <div className="flex items-start w-full justify-between">
            <div className="">
              <div className="font-Kanit font-semibold text-[17px]">
                Assign meter
              </div>
              <div className=" w-[80%] text-gray-700 font-light">
                Fill the fields below to assign a meter to a customer.
              </div>
            </div>
            <div className=" w-[200px] flex justify-end">
              <CloseButton closeBtn={setopen} />
            </div>
          </div>
          <Rule />
          <div>
            {tabstate == 1 && (
              <div className="mt-4">
                <label className="text-primary font-normal text-sm">
                  Enter customer username.
                </label>
                <div className="flex bg-gray-100 items-center w-100 border-[1px] rounded-md border-gray-500 mt-2 h-[45px]">
                  <div className="d-inline flex items-center justify-center px-2">
                    <AiOutlineUser
                      size={20}
                      color="text-primary"
                      className="text-primary"
                    />
                  </div>
                  <div className="ml-2">
                    <input
                      required
                      value={name}
                      onChange={(e) => setname(e.target.value)}
                      // {...register("username", { required: true })}
                      type={"text"}
                      placeholder={"username of customer."}
                      className="bg-gray-100 placeholder:text-sm placeholder:font-thin w-full outline-none border-[0px]"
                    />
                  </div>
                </div>
                {errors.exampleRequired && (
                  <ErrorText text={"Please enter a username"} />
                )}
              </div>
            )}
            {tabstate == 2 && (
              <>
                {/* full name */}
                <div className="mt-4">
                  <label className="text-primary font-normal text-sm">
                    Full Name
                  </label>
                  <div className="flex bg-gray-100 items-center w-100 border-[1px] rounded-md border-gray-500 mt-2 h-[45px]">
                    <div className="d-inline flex items-center justify-center px-2">
                      <AiOutlineUser
                        size={20}
                        color="text-primary"
                        className="text-primary"
                      />
                    </div>
                    <div className="ml-2">
                      <input
                        required
                        defaultValue={`${userObj?.firstname} ${userObj.lastname}`}
                        type={"text"}
                        disabled
                        placeholder={"username of customer."}
                        className="bg-gray-100 placeholder:text-sm placeholder:font-thin w-full outline-none border-[0px]"
                      />
                    </div>
                  </div>
                  {errors.exampleRequired && (
                    <ErrorText text={"Please enter a username"} />
                  )}
                </div>
                <div className="mt-4">
                  <label className="text-primary font-normal text-sm">
                    Customer type
                  </label>
                  <div className="flex bg-gray-100 items-center w-100 border-[1px] rounded-md border-gray-500 mt-2 h-[45px]">
                    <Select
                      size="large"
                      style={{
                        width: "100%",
                        height: 45,
                        fontSize: "13px",
                      }}
                      bordered={false}
                      placeholder={"Select a customer type"}
                      onChange={(e) => setselectedCustomerType(e)}
                    >
                      {customerType.map((item) => (
                        <Option value={item} key={item.trim()}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  {errors.exampleRequired && (
                    <ErrorText text={"Phone is required."} />
                  )}
                </div>

                <div className="mt-4">
                  <label className="text-primary font-normal text-sm">
                    Address
                  </label>
                  <div className="flex items-center justify-between w-100 border-[1px] rounded-md bg-gray-100 border-gray-600 mt-2 h-[70px]">
                    <textarea
                      style={{
                        height: "100%",
                        resize: "none",
                        fontSize: "13px",
                        background: "transparent",
                      }}
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      // {...register("address", { required: true })}
                      className="w-full input-box px-2 rounded-1  border-none outline-none"
                      placeholder={"Enter address."}
                    />
                  </div>
                  {errors.address && (
                    <ErrorText text={"Please enter a username"} />
                  )}
                </div>
              </>
            )}

            {tabstate == 3 && (
              <>
                <div className="mt-4">
                  <label className="text-primary font-normal text-sm">
                    Enter GPS
                  </label>
                  <div className="flex bg-gray-100 items-center w-100 border-[1px] rounded-md border-gray-500 mt-2 h-[45px]">
                    <div className="ml-2">
                      <input
                        required
                        value={gps}
                        onChange={(e) => setgps(e.target.value)}
                        type={"text"}
                        placeholder={"enter gps."}
                        className="bg-gray-100 placeholder:text-sm placeholder:font-thin w-full outline-none border-[0px]"
                      />
                    </div>
                  </div>
                  {errors.exampleRequired && (
                    <ErrorText text={"Please enter a username"} />
                  )}
                </div>
                <div className="mt-4">
                  <label className="text-primary font-normal text-sm">
                    Unique sections
                  </label>
                  <div className="flex bg-gray-100 items-center w-100 border-[1px] rounded-md border-gray-500 mt-2 h-[45px]">
                    <Select
                      size="large"
                      style={{
                        width: "100%",
                        height: 45,
                        fontSize: "13px",
                      }}
                      bordered={false}
                      placeholder={"Select section"}
                      onChange={(e) => setSelectedSection(e)}
                    >
                      {uniquesections.map((item) => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  {errors.exampleRequired && (
                    <ErrorText text={"Select unique section."} />
                  )}
                  <div className="mt-4">
                    <label className="text-primary font-normal text-sm">
                      Enter pole number
                    </label>
                    <div className="flex bg-gray-100 items-center w-100 border-[1px] rounded-md border-gray-500 mt-2 h-[45px]">
                      <div className="ml-2">
                        <input
                          required
                          value={poleNumber}
                          onChange={(e) => setpoleNumber(e.target.value)}
                          type={"text"}
                          placeholder={"enter pole number."}
                          className="bg-gray-100 placeholder:text-sm placeholder:font-thin w-full outline-none border-[0px]"
                        />
                      </div>
                    </div>
                    {errors.exampleRequired && (
                      <ErrorText text={"Please enter a username"} />
                    )}
                  </div>
                </div>
              </>
            )}
            <Rule />

            {tabstate == 1 && (
              <div className=" w-100 mt-4 ">
                <Button
                  text={"Search for customer"}
                  loading={userloading}
                  onClick={() => handleUserName()}
                />
              </div>
            )}
            {tabstate != 1 && (
              <div className=" w-100 mt-4 grid grid-cols-2 gap-4">
                <Button
                  text={"Back"}
                  loading={userloading}
                  bg={"#fff"}
                  onClick={() => settabstate(tabstate == 3 ? 2 : 1)}
                  color={"black"}
                  border={`1px solid black`}
                />

                <Button
                  text={tabstate == 3 ? "Add Customer" : "Next"}
                  loading={userloading}
                  onClick={() =>
                    tabstate == 3 ? handleAddCustomer() : settabstate(3)
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default AddCustomer;

import {
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Group,
  Inject,
  Page,
  Search,
  Toolbar,
  ExcelExport,
  Sort,
} from "@syncfusion/ej2-react-grids";
import moment from "moment";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { useState, useContext } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaUser, FaUsersCog } from "react-icons/fa";
import { themeColor as color } from "../../../../constant/color";
import { setToken } from "../../../../utils/token";
import { Button } from "../../../Button";
import ProcessingLoader from "../../../ProcessingLoader";
import toast from "react-hot-toast";
import axios from "../../../../utils/axios";
import "./style.css";
import { meterController } from "../../../../controllers/MeterController";
import { Space, Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import * as uuid from "uuid";
import { RiDeleteBin6Line } from "react-icons/ri";

const MeterClassTable = ({ data, refreshBtn }) => {
  const [modal, setModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [roleId, setRoleId] = useState("");
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  let grid = useRef();

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      let res = await axios.post("/settings/deletecustomerclass", {
        customerclass: id,
      });

      let result = meterController(res);

      if (result.type !== "success") {
        toast[result.type](result.message);
        setLoading(false);
        console.log(result.message);
        return;
      }
      // toast.success("Meter class deleted successfully");
      setLoading(false);
      refreshBtn((prev) => !prev);
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
  };

  const Sample = (item) => {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <div
          className="d-flex mx-1 p-1 align-items-center justify-content-center rounded-1 text-decoration-none"
          style={
            {
              // border: `1px solid ${color.redColor}`,
            }
          }
        >
          <div
            style={{
              position: "relative",
              cursor: "pointer",
            }}
            className="text-center"
            onClick={() => handleDelete(item)}
          >
            <RiDeleteBin6Line size={20} color={color.redColor} />
          </div>
        </div>
        <Link
          to={`/addcustomer/${item}`}
          className="px-1  d-flex align-items-center justify-content-center rounded-1 text-decoration-none"
          style={{
            border: `1px solid ${"#575757"}`,
            width: "80px",
          }}
        >
          <div
            style={{
              position: "relative",
              cursor: "pointer",
            }}
            className="text-center "
            onClick={() => {
              setModal(true);
            }}
          >
            <Button
              border={`1px solid ${"#575757"}`}
              text={"details"}
              color={"#575757"}
              fontSize={"11px"}
              fontWeight={"400"}
              height={"30px"}
              bg={color.whiteColor}
            />
          </div>
        </Link>
      </div>
    );
  };

  // const data = [
  //   {
  //     adminid: "sysadmin",
  //     amountperkwh: 2344,
  //     classdescription: "wedd",
  //     customerclass: "qws",
  //     dateentered: "2021-10-21 12:10:55",
  //     tou: "02:39",
  //     key: 1,
  //   },
  // ];
  return (
    <div
      style={{
        borderTop: `1px solid #eee`,
      }}
    >
      {loading && <ProcessingLoader msg={"Deleting meter class"} />}
      <div className="table-container" style={{}}>
        <Table
          pagination={{
            pageSize: 10,
          }}
          scroll={{
            y: 500,
            x: true,
          }}
          columns={[
            {
              title: "AdminId",
              dataIndex: "adminid",
              key: "key",
              render: (text) => <a>{text}</a>,
              fixed: "left",
            },
            {
              title: "Amount per kWh",
              dataIndex: "amountperkwh",
              key: "key",
              width: 150,
              render: (item) => (
                <div
                  className=""
                  style={{
                    fontSize: "13px",
                    color: color.baseColor,
                  }}
                >
                  {item}
                </div>
              ),
              // defaultSortOrder: "descend",
              // sorter: (a, b) => a.adminid - b.adminid,
            },
            {
              title: "Class Description",
              dataIndex: "classdescription",
              key: "classdescription",
              width: 200,
            },
            {
              title: "Time of use",
              dataIndex: "tou",
              key: "key",
            },
            {
              title: "Date entered",
              dataIndex: "dateentered",
              key: "key",
              render: (item) => (
                <div
                  className=""
                  style={{
                    color: "#575757",
                    fontSize: "13px",
                  }}
                >
                  {moment(item).format("lll")}
                </div>
              ),
            },
            {
              title: "Customer class",
              key: "key",
              width: 150,
              dataIndex: "customerclass",
              // render: (_, { tags }) => (
              //   <>
              //     {tags.map((tag) => {
              //       let color = tag.length > 5 ? "geekblue" : "green";
              //       if (tag === "loser") {
              //         color = "volcano";
              //       }
              //       return (
              //         <Tag color={color} key={tag}>
              //           {tag.toUpperCase()}
              //         </Tag>
              //       );
              //     })}
              //   </>
              // ),
            },
            {
              title: "Action",
              dataIndex: "customerclass",
              key: "key",
              render: Sample,
              width: 200,
            },
          ]}
          dataSource={data}
        />
      </div>
    </div>
  );
};

export default MeterClassTable;

{
  /* <GridComponent
ref={(g) => (grid.current = g)}
id="grid"
style={{
  display: "block",
}}
allowPaging={true}
allowSorting={true}
dataSource={data}
pageSettings={{
  pageSize: 12,
}}
allowExcelExport={false}
toolbar={["Search"]}
width={"100%"}
height={"100%"} */
}
// searchSettings={searchOptions}
// >
{
  /* <ColumnsDirective>
  <ColumnDirective
    field="adminid"
    width="150"
    textAlign="Center"
    headerText="Admin Id"
    headerTextAlign="Center"
    headerTemplate={() => {
      return (
        <div
          className="st-header"
          style={{
            color: color.darkColor,
            fontSize: "12px",
          }}
        >
          Admin Id
        </div>
      );
    }}
    template={(item) => {
      return (
        <div
          style={{
            color: "#575757",
            fontSize: "13px",
          }}
          className="text-center"
        >
          {!item?.adminid ? "No id" : item?.adminid}
        </div>
      );
    }}
  />
  <ColumnDirective
    field="amountperkwh"
    width="150"
    textAlign="Center"
    headerText="Amountperkwh"
    headerTextAlign="Center"
    headerTemplate={() => {
      return (
        <div
          className="st-header"
          style={{
            color: color.darkColor,
            fontSize: "12px",
          }}
        >
          Amount per kWh
        </div>
      );
    }}
    template={(item) => {
      return (
        <div
          style={{
            color: "#575757",
            fontSize: "13px",
          }}
          className="text-center"
        >
          {!item?.amountperkwh
            ? "Not price given"
            : item?.amountperkwh}
        </div>
      );
    }}
  />
  <ColumnDirective
    field="customerclass"
    width="150"
    textAlign="Center"
    headerText="PMS"
    headerTextAlign="Center"
    headerTemplate={() => {
      return (
        <div
          className="st-header"
          style={{
            color: color.darkColor,
            fontSize: "12px",
          }}
        >
          Meterclass Name
        </div>
      );
    }}
    template={(item) => {
      return (
        <div
          style={{
            color: color.baseColor,
            fontSize: "13px",
          }}
          className="text-center"
        >
          {!item?.customerclass
            ? "Name not assigned "
            : item?.customerclass}
        </div>
      );
    }}
  />
  <ColumnDirective
    field="classdescription"
    width="200"
    textAlign="Center"
    headerText="PMS"
    headerTextAlign="Center"
    headerTemplate={() => {
      return (
        <div
          className="st-header"
          style={{
            color: color.darkColor,
            fontSize: "12px",
          }}
        >
          Class description
        </div>
      );
    }}
    template={(item) => {
      return (
        <div
          style={{
            color: "#575757",
            fontSize: "13px",
          }}
          className="text-center"
        >
          {!item?.classdescription
            ? "No description given"
            : item?.classdescription}
        </div>
      );
    }}
  />
  <ColumnDirective
    field="tou"
    width="150"
    textAlign="Center"
    headerText="Time of use"
    headerTextAlign="Center"
    headerTemplate={() => {
      return (
        <div
          className="st-header"
          style={{
            color: color.darkColor,
            fontSize: "12px",
          }}
        >
          Time of use
        </div>
      );
    }}
    template={(item) => {
      return (
        <div
          style={{
            color: "#575757",
            fontSize: "13px",
          }}
          className="text-center"
        >
          {!item?.tou ? "No time of use" : item?.tou}
        </div>
      );
    }}
  />
  <ColumnDirective
    field="dateentered"
    width="150"
    textAlign="Center"
    headerText="Date entered"
    headerTextAlign="Center"
    headerTemplate={() => {
      return (
        <div
          className="st-header"
          style={{
            color: color.darkColor,
            fontSize: "12px",
          }}
        >
          Date entered
        </div>
      );
    }}
    template={(item) => {
      return (
        <div
          style={{
            fontSize: "13px",
          }}
          className="text-center"
          onClick={() => console.log(selectedRow)}
        >
          {!item?.date
            ? "No date "
            : moment(item?.date).format("DD-MM-YYYY HH:mm")}
        </div>
      );
    }}
  />
  <ColumnDirective
    field="tou"
    width="200"
    textAlign="Center"
    headerText="PMS"
    headerTextAlign="Center"
    headerTemplate={() => {
      return (
        <div
          className="st-header"
          style={{
            color: color.darkColor,
            fontSize: "12px",
          }}
        >
          Action
        </div>
      );
    }}
    template={Sample}
  />
</ColumnsDirective>
<Inject
  services={[Page, Sort, Filter, Group, Toolbar, Search, ExcelExport]}
/>
</GridComponent> */
}

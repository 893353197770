import Backdrop from "../../../Backdrop";
import CloseButton from "../../../CloseButton";
import Rule from "../../../Rule/Index";
const PlanType = ({ open, setopen, setselectedplantype, opendrawer }) => {
  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center animate__animated animate__fadeInUp">
        <div className="w-[400px] bg-white drop-shadow-md rounded-sm p-3">
          <div className="flex items-start justify-content-between">
            <div className="">
              <div className="font-Kanit font-semibold text-[17px]">
                Add Plan
              </div>
              <div className="w-[80%] text-gray-700 font-light">
                Select a payment plan type you would like to add.
                <br />
              </div>
            </div>
            <div className=" w-[200px] flex justify-end items-start">
              <CloseButton closeBtn={setopen} />
            </div>
          </div>
          <Rule />
          <div className="">
            <div
              className="even:bg-gray-50 hover:bg-gray-100 px-1 py-3 cursor-pointer my-2 "
              onClick={() => {
                setselectedplantype("powerlimit");
                opendrawer(true);
                setopen(false);
              }}
            >
              <div className="">Daily Power limit</div>
            </div>
            <div
              className="even:bg-gray-50 hover:bg-gray-100 px-1 py-3 cursor-pointer my-2 "
              onClick={() => {
                setselectedplantype("energylimit");
                opendrawer(true);
                setopen(false);
              }}
            >
              <div className="">Daily Energy limit</div>
            </div>
            <div
              className="even:bg-gray-50 hover:bg-gray-100 px-1 py-3 cursor-pointer my-2 "
              onClick={() => {
                setselectedplantype("cummulativelimit");
                opendrawer(true);
                setopen(false);
              }}
            >
              <div className="">Cummulative Power limit</div>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default PlanType;

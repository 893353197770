import moment from "moment";
import { FcCalendar } from "react-icons/fc";
import { Link } from "react-router-dom";
import { HiUserGroup } from "react-icons/hi";
import { useState } from "react";
import { Select } from "antd";
import EmptyState from "../../../EmptyState";
import { VscCopy } from "react-icons/vsc";

const AllMetersTable = ({ data = [], setdetails, setmodal }) => {
  return !data.length ? (
    <EmptyState>
      <div className="h-[70vh] w-full">
        <div className="flex items-center justify-center mt-[200px]">
          <HiUserGroup size={30} />
        </div>
        <div className="font-normal text-[15px] text-center">
          No meter has been added on the platform
        </div>
      </div>
    </EmptyState>
  ) : (
    //     number	"K-A2-PL00000002"
    // type	"CAS-A20"
    // phases	"single"
    // registrar	"system"
    // powerstatus	"OFF"
    // 	"jess"
    // loadlimit	302
    // dateregistered	"2022-08-22 22:15:04.0"
    // datedecommissioned	""
    <div>
      <table className="w-full border-collapse">
        <thead className="h-[50px] drop-shadow-md border-b-[0.5px] border-gray-800">
          <th className="font-semibold text-gray-800">S/N</th>
          <th className="font-semibold text-gray-800">Meter name</th>
          <th className="font-semibold text-gray-800">Phase</th>
          {/* <th className="font-semibold text-gray-800">Created by.</th> */}
          <th className="font-semibold text-gray-800">Power status</th>
          <th className="font-semibold text-gray-800">Assigned to</th>
          <th className="font-semibold text-gray-800">Load limit</th>
          <th className="font-semibold text-gray-800">Date added</th>
          <th className="font-semibold text-gray-800">Date decommissioned</th>
          {/* <th className="font-semibold text-gray-800">Time range</th> */}
          <th className="font-semibold text-gray-800">Action</th>
        </thead>
        <tbody>
          {data.map((i, index) => (
            <tr
              className="bg-white hover:bg-gray-50 cursor-pointer h-[50px] even:bg-gray-50"
              key={i?.roleid}
            >
              <td className="text-center">{index + 1}</td>
              <td className="rounded-lg text-center">
                <div className="flex items-center justify-center">
                  <div className="text-secondary">{i?.number}</div>
                  <div className="">
                    <VscCopy />
                  </div>
                </div>
              </td>
              <td className="text-center ">{i.phases}</td>
              {/* <td className="rounded-lg text-center">
                <div className="flex items-center justify-center">
                  <div className="text-secondary">{i?.registrar}</div>
                </div>
              </td> */}
              <td className="rounded-lg text-center">{i?.powerstatus}</td>
              <td className="rounded-lg text-center">{i?.assignedto}</td>
              <td className="rounded-lg text-center">{i?.loadlimit}</td>
              <td className="text-center">
                <div className="flex items-center justify-center">
                  <div className="pl-2">
                    {moment(i?.dateregistered).format("lll")}
                  </div>
                </div>{" "}
              </td>
              <td className="text-center">
                <div className="flex items-center justify-center">
                  <div className="pl-2">
                    {i?.datedecommissioned
                      ? moment(i?.datedecommissioned).format("lll")
                      : "-"}
                  </div>
                </div>{" "}
              </td>

              <td className="text-center flex justify-center">
                <div
                  onClick={() => {
                    setdetails(i);
                    setmodal(true);
                  }}
                  className="border-[1px] rounded border-orange-600 text-[12px] text-orange-600 font-light px-4 py-2"
                >
                  Details
                </div>
                {/* <Link className="ml-2 border-[1px] rounded border-secondary text-[12px] text-secondary font-light px-4 py-2">
                  View Recharge history
                </Link> */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllMetersTable;

import axios from "../../../utils/axios";
import { useEffect, useState } from "react";
import BackBtn from "../../../components/BackBtn";
import Layout from "../../../components/Layout";
import PageTitle from "../../../components/PageTitle";
import Skimmer from "../../../components/Loader/Skimmer";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import Rule from "../../../components/Rule/Index";
import moment from "moment";
import TableSkimmer from "../../../components/TableSkimmer";
import VendingHistoryTable from "../../../components/SalesAgent/Table/VendingHistoryTable";
import { Button } from "../../../components/Button";
import TopUpModal from "../../../components/SalesAgent/Modals/TopUpModal";
const CustomerMeterRecharge = () => {
  const { customer, meterid } = useParams();
  const [modal, setmodal] = useState(false);
  const [refresh, setrefresh] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [logData, setlogData] = useState([]);
  const [meterDetails, setMeterDetails] = useState({});
  const [rechargeHistory, setRechargeHistory] = useState([]);
  const [showTopup, setShowTopup] = useState(false);
  const [loading, setLoading] = useState({
    meter: true,
    profile: true,
    log: false,
  });
  const controller = new AbortController();
  useEffect(() => {
    axios
      .post("/auth/account/getuser", { username: customer })
      .then((response) => {
        setUserDetails(response.data);
        setLoading((prev) => {
          return {
            ...prev,
            profile: false,
          };
        });
      })
      .catch((err) => {
        toast(err.message);
        setLoading((prev) => {
          return {
            ...prev,
            profile: false,
          };
        });
      });

    axios
      .post("/meter/getmeter", { meternumber: meterid })
      .then((response) => {
        setMeterDetails(response.data);
        setLoading((prev) => {
          return {
            ...prev,
            meter: false,
          };
        });
      })
      .catch((err) => {
        toast(err.message);
        setLoading((prev) => {
          return {
            ...prev,
            meter: false,
          };
        });
      });

    // getRechargeHistory();
    return () => {
      controller.abort();
    };
  }, []);
  return (
    <Layout>
      {modal && (
        <TopUpModal open={modal} setopen={setmodal} refreshBtn={setrefresh} />
      )}
      <div className="mb-10">
        <BackBtn />
        <div className="grid grid-cols-2 bg-white rounded-md drop-shadow-md">
          <div className="p-3 border-r-[1px] border-gray-400">
            <div className="">
              <div className="font-semibold">User profile details</div>
            </div>
            <Rule />

            <div className="">
              {loading.profile ? (
                Array.from(Array(4)).map((i, index) => (
                  <div className="my-2" key={index}>
                    <Skimmer heigth={"30px"} />
                  </div>
                ))
              ) : (
                <>
                  <div className="flex items-center justify-between my-2">
                    <div className="text-gray-700">Full name:</div>
                    <div className="font-semibold text-gray-800">
                      {userDetails.firstname
                        ? userDetails.firstname + " " + userDetails.lastname
                        : userDetails.username}
                    </div>
                  </div>
                  <div className="flex items-center justify-between my-2">
                    <div className="text-gray-700">Username:</div>
                    <div className="font-semibold text-gray-800">
                      {customer}
                    </div>
                  </div>
                  <div className="flex items-center justify-between my-2">
                    <div className="text-gray-700">Email address:</div>
                    <div className="font-semibold text-gray-800">
                      {userDetails.email}
                    </div>
                  </div>
                  <div className="flex items-center justify-between my-2">
                    <div className="text-gray-700">Phone number:</div>
                    <div className="font-semibold text-gray-800">
                      {userDetails.phone}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="p-3">
            <div className="">
              <div className="font-semibold">Meter details</div>
            </div>
            <Rule />
            <div className="">
              {loading.meter ? (
                Array.from(Array(4)).map((i, index) => (
                  <div className="my-2" key={index}>
                    <Skimmer heigth={"30px"} />
                  </div>
                ))
              ) : (
                <>
                  <div className="flex items-center justify-between my-2">
                    <div className="text-gray-700">Load limit:</div>
                    <div className="font-semibold text-gray-800">
                      {meterDetails.loadlimit}
                    </div>
                  </div>
                  <div className="flex items-center justify-between my-2">
                    <div className="text-gray-700">Phase:</div>
                    <div className="font-semibold text-gray-800">
                      {meterDetails.phases} phase
                    </div>
                  </div>
                  <div className="flex items-center justify-between my-2">
                    <div className="text-gray-700">Power status:</div>
                    <div
                      className="font-semibol"
                      style={{
                        color:
                          meterDetails.powerstatus.toLowerCase() == "off"
                            ? "tomato"
                            : "green",
                      }}
                    >
                      {meterDetails.powerstatus}
                    </div>
                  </div>
                  <div className="flex items-center justify-between my-2">
                    <div className="text-gray-700">Date registered:</div>
                    <div className="font-semibold text-gray-800">
                      {moment(meterDetails.dateregistered).format("lll")}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg drop-shadow-md p-3 h-[80vh] mb-30 mt-6">
          <div className="flex items-center justify-between mb-3">
            <PageTitle
              title={"Recharge history"}
              caption={
                "All recharge history on " +
                customer[0].toUpperCase() +
                customer.substring(1, customer.length) +
                " meter"
              }
            />
            <div className="">
              <div className="w-[120px] ">
                <Button
                  text={"Top up meter"}
                  height={"40px"}
                  onClick={() => setmodal(true)}
                />
              </div>
            </div>
          </div>

          <div className="">
            {loading.log ? (
              <TableSkimmer entries={13} col={7} />
            ) : (
              <VendingHistoryTable
                data={logData}
                // setdetails={setdetails}
                setmodal={setmodal}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CustomerMeterRecharge;

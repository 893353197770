import * as uuid from "uuid";
import { useContext } from "react";
import { getToken } from "../../utils/token";

let site = localStorage.getItem("spiral_site");

console.log(site, "sitename");

export const data = {
  sysdmin: [
    {
      id: `${uuid.v4()}`,
      title: "Dashboard",
      icon: `<ion-icon name="desktop-outline"></ion-icon>`,
      children: [],
      link: "/dashboard/systemadmin",
    },
    {
      id: `${uuid.v4()}`,
      title: "Users",
      icon: `<ion-icon name="hardware-chip-outline"></ion-icon>`,
      children: [],
      link: "/users",
    },
    {
      id: `${uuid.v4()}`,
      title: "Customer Meters",
      icon: `<ion-icon name="speedometer"></ion-icon>`,
      children: [],
      link: "/dashboard/customers",
    },
    {
      id: `${uuid.v4()}`,
      title: "Project sites",
      icon: `<ion-icon name="git-pull-request"></ion-icon>`,
      children: [],
      link: "/dashboard/systemadmin/projectsite",
    },
    {
      id: `${uuid.v4()}`,
      title: "Meter class",
      icon: `<ion-icon name="git-pull-request"></ion-icon>`,
      children: [],
      link: "/meterclass",
    },
    {
      id: `${uuid.v4()}`,
      title: "Roles",
      icon: `<ion-icon name="people"></ion-icon>`,
      children: [
        {
          id: `${uuid.v4()}`,
          title: "Your roles",
          children: [],
          icon: "",
          link: "/userroles",
        },
        {
          id: `${uuid.v4()}`,
          title: "All user roles",
          children: [],
          icon: "",
          link: "/roles/alluserroles",
        },
      ],
      link: "",
    },
    {
      id: `${uuid.v4()}`,
      title: "Meters",
      icon: `<ion-icon name="paper-plane"></ion-icon>`,
      link: "",
      children: [
        {
          id: `${uuid.v4()}`,
          title: "All Meters",
          children: [],
          icon: "",
          link: "/allmeters",
        },
        // {
        //   id: `${uuid.v4()}`,
        //   title: "All Customer meters",
        //   children: [],
        //   icon: "",
        //   link: "/allcustomermeters",
        // },
        {
          id: `${uuid.v4()}`,
          title: "All Site meters.",
          children: [],
          link: "/allprojectsitemeters",
        },
      ],
    },
    // {
    //   id: `${uuid.v4()}`,
    //   title: "Revenue",
    //   icon: `<ion-icon name="git-pull-request"></ion-icon>`,
    //   children: [],
    //   link: "/revenue",
    // },
    {
      id: `${uuid.v4()}`,
      title: "Wallet",
      icon: `<ion-icon name="people"></ion-icon>`,
      children: [],
      link: "/wallet",
    },
    {
      id: `${uuid.v4()}`,
      title: "Settings",
      icon: `<ion-icon name="git-pull-request"></ion-icon>`,
      link: "",
      children: [
        {
          id: `${uuid.v4()}`,
          title: "Profile",
          children: [],
          icon: "",
          link: "/settings/profile",
        },
        {
          id: `${uuid.v4()}`,
          title: "Key Settings.",
          children: [],
          link: "/settings/dropdown",
        },
        {
          id: `${uuid.v4()}`,
          title: "Control Settings.",
          children: [],
          link: "/settings/controls",
        },
      ],
    },
  ],
  projectmanager: [
    {
      id: `${uuid.v4()}`,
      title: "Dashboard",
      icon: `<ion-icon name="desktop-outline"></ion-icon>`,
      children: [],
      link: `/dashboard/projectmanager?projectsite=${site}`,
    },
    {
      id: `${uuid.v4()}`,
      title: "Customer profile",
      icon: `<ion-icon name="hardware-chip-outline"></ion-icon>`,
      children: [],
      link: "/users",
    },
    {
      id: `${uuid.v4()}`,
      title: "Customer Meters",
      icon: `<ion-icon name="speedometer"></ion-icon>`,
      children: [],
      link: "/manage-meter",
    },

    {
      id: `${uuid.v4()}`,
      title: "Wallet",
      icon: `<ion-icon name="speedometer"></ion-icon>`,
      children: [],
      link: "/wallet",
    },
    {
      id: `${uuid.v4()}`,
      title: "Roles",
      icon: `<ion-icon name="people"></ion-icon>`,
      children: [
        {
          id: `${uuid.v4()}`,
          title: "Your roles",
          children: [],
          icon: "",
          link: "/userroles",
        },
        {
          id: `${uuid.v4()}`,
          title: "All user roles",
          children: [],
          icon: "",
          link: "/roles/alluserroles",
        },
      ],
      link: "",
    },
    {
      id: `${uuid.v4()}`,
      title: "Revenue",
      icon: `<ion-icon name="paper-plane"></ion-icon>`,
      link: "/revenue",
      children: [
        {
          id: `${uuid.v4()}`,
          title: "All Meters",
          children: [],
          icon: "",
          link: "/allmeters",
        },
        // {
        //   id: `${uuid.v4()}`,
        //   title: "All Customer meters",
        //   children: [],
        //   icon: "",
        //   link: "/allcustomermeters",
        // },
        {
          id: `${uuid.v4()}`,
          title: "All Site meters.",
          children: [],
          link: "/allprojectsitemeters",
        },
      ],
    },
    // {
    //   id: `${uuid.v4()}`,
    //   title: "Project sites",
    //   icon: `<ion-icon name="git-pull-request"></ion-icon>`,
    //   children: [],
    //   link: "/dashboard/systemadmin/projectsite",
    // },
    {
      id: `${uuid.v4()}`,
      title: "Meter class",
      icon: `<ion-icon name="git-pull-request"></ion-icon>`,
      children: [],
      link: "/meterclass",
    },
    {
      id: `${uuid.v4()}`,
      title: "Settings",
      icon: `<ion-icon name="git-pull-request"></ion-icon>`,
      link: "/settings",
      children: [
        {
          id: `${uuid.v4()}`,
          title: "Profile",
          children: [],
          icon: "",
          link: "/settings/profile",
        },
        {
          id: `${uuid.v4()}`,
          title: "Key Settings.",
          children: [],
          link: "/settings/dropdown",
        },
        {
          id: `${uuid.v4()}`,
          title: "Control Settings.",
          children: [],
          link: "/settings/controls",
        },
      ],
    },
  ],
  customerRep: [
    {
      id: `${uuid.v4()}`,
      title: "Dashboard",
      icon: `<ion-icon name="desktop-outline"></ion-icon>`,
      children: [],
      link: `/dashboard/customerrepresentative/${site}`,
    },
    {
      id: `${uuid.v4()}`,
      title: "Meters",
      children: [],
      icon: `<ion-icon name="paper-plane"></ion-icon>`,
      link: "/manage-meter",
    },
    {
      id: `${uuid.v4()}`,
      title: "Customer Profile",
      icon: `<ion-icon name="hardware-chip-outline"></ion-icon>`,
      children: [],
      link: "/users",
    },
    // {
    //   id: `${uuid.v4()}`,
    //   title: "Customer Meters",
    //   icon: `<ion-icon name="speedometer"></ion-icon>`,
    //   children: [],
    //   link: "/salesagent/customer",
    // },
    {
      id: `${uuid.v4()}`,
      title: "Roles",
      children: [],
      icon: `<ion-icon name="people"></ion-icon>`,
      link: "/userroles",
    },

    // {
    //   id: `${uuid.v4()}`,
    //   title: "Meters",
    //   icon: `<ion-icon name="card"></ion-icon>`,
    //   link: "/dashboard/systemadmin/meters",
    //   children: [],
    // },

    // {
    //   id: `${uuid.v4()}`,
    //   title: "Meter class",
    //   icon: `<ion-icon name="git-pull-request"></ion-icon>`,
    //   children: [],
    //   link: "/meterclass",
    // },
    {
      id: `${uuid.v4()}`,
      title: "Settings",
      icon: `<ion-icon name="git-pull-request"></ion-icon>`,
      link: "/settings",
      children: [
        {
          id: `${uuid.v4()}`,
          title: "Profile",
          children: [],
          icon: "",
          link: "/settings/profile",
        },
        {
          id: `${uuid.v4()}`,
          title: "Key Settings.",
          children: [],
          link: "/settings/dropdown",
        },
      ],
    },
  ],
  customerManager: [
    {
      id: `${uuid.v4()}`,
      title: "Dashboard",
      icon: `<ion-icon name="desktop-outline"></ion-icon>`,
      children: [],
      link: `/dashboard/customermanager/${site}`,
    },
    {
      id: `${uuid.v4()}`,
      title: "Meters",
      children: [],
      icon: `<ion-icon name="paper-plane"></ion-icon>`,
      link: "/manage-meter",
    },
    {
      id: `${uuid.v4()}`,
      title: "Customer Profile",
      icon: `<ion-icon name="hardware-chip-outline"></ion-icon>`,
      children: [],
      link: "/users",
    },
    // {
    //   id: `${uuid.v4()}`,
    //   title: "Customer Meters",
    //   icon: `<ion-icon name="speedometer"></ion-icon>`,
    //   children: [],
    //   link: "/salesagent/customer",
    // },
    {
      id: `${uuid.v4()}`,
      title: "Roles",
      children: [],
      icon: `<ion-icon name="people"></ion-icon>`,
      link: "/userroles",
    },

    // {
    //   id: `${uuid.v4()}`,
    //   title: "Meters",
    //   icon: `<ion-icon name="card"></ion-icon>`,
    //   link: "/dashboard/systemadmin/meters",
    //   children: [],
    // },

    // {
    //   id: `${uuid.v4()}`,
    //   title: "Meter class",
    //   icon: `<ion-icon name="git-pull-request"></ion-icon>`,
    //   children: [],
    //   link: "/meterclass",
    // },
    {
      id: `${uuid.v4()}`,
      title: "Settings",
      icon: `<ion-icon name="git-pull-request"></ion-icon>`,
      link: "/settings",
      children: [
        {
          id: `${uuid.v4()}`,
          title: "Profile",
          children: [],
          icon: "",
          link: "/settings/profile",
        },
        {
          id: `${uuid.v4()}`,
          title: "Key Settings.",
          children: [],
          link: "/settings/dropdown",
        },
      ],
    },
  ],
  salesagent: [
    {
      id: `${uuid.v4()}`,
      title: "Dashboard",
      icon: `<ion-icon name="desktop-outline"></ion-icon>`,
      children: [],
      link: `/dashboard/salesagent`,
    },
    {
      id: `${uuid.v4()}`,
      title: "Customers",
      icon: `<ion-icon name="speedometer"></ion-icon>`,
      children: [],
      link: "/salesagent/customer",
    },
    {
      id: `${uuid.v4()}`,
      title: "Sales Analytics",
      icon: `<ion-icon name="git-pull-request"></ion-icon>`,
      children: [],
      link: "/revenue",
    },
    {
      id: `${uuid.v4()}`,
      title: "Roles",
      icon: `<ion-icon name="people"></ion-icon>`,
      link: "/userroles",
    },
    {
      id: `${uuid.v4()}`,
      title: "Wallet",
      icon: `<ion-icon name="people"></ion-icon>`,
      link: "/wallet",
    },
    {
      id: `${uuid.v4()}`,
      title: "Settings",
      icon: `<ion-icon name="git-pull-request"></ion-icon>`,
      link: "/settings",
      children: [
        {
          id: `${uuid.v4()}`,
          title: "Profile",
          children: [],
          icon: "",
          link: "/settings/profile",
        },
        {
          id: `${uuid.v4()}`,
          title: "Key Settings.",
          children: [],
          link: "/settings/dropdown",
        },
      ],
    },
  ],
  customers: [
    {
      id: `${uuid.v4()}`,
      title: "Dashboard",
      icon: `<ion-icon name="desktop-outline"></ion-icon>`,
      children: [],
      link: `/dashboard`,
    },
    {
      id: `${uuid.v4()}`,
      title: "Meters",
      icon: `<ion-icon name="speedometer"></ion-icon>`,
      children: [],
      link: "/usermeters",
    },
    {
      id: `${uuid.v4()}`,
      title: "Recharge History",
      icon: `<ion-icon name="speedometer"></ion-icon>`,
      children: [],
      link: "/recharge-history",
    },
    {
      id: `${uuid.v4()}`,
      title: "Meter Activities",
      icon: `<ion-icon name="speedometer"></ion-icon>`,
      children: [],
      link: "/customer-meter/meteractivities",
    },
    {
      id: `${uuid.v4()}`,
      title: "Settings",
      icon: `<ion-icon name="git-pull-request"></ion-icon>`,
      link: "",
      children: [
        {
          id: `${uuid.v4()}`,
          title: "Profile",
          children: [],
          icon: "",
          link: "/settings/profile",
        },
      ],
    },
  ],
  user: [
    {
      id: `${uuid.v4()}`,
      title: "Dashboard",
      icon: `<ion-icon name="desktop-outline"></ion-icon>`,
      children: [],
      link: `/dashboard`,
    },
    {
      id: `${uuid.v4()}`,
      title: "Devices",
      icon: `<ion-icon name="speedometer"></ion-icon>`,
      children: [],
      link: "/allchannels",
    },
    {
      id: `${uuid.v4()}`,
      title: "Meters",
      icon: `<ion-icon name="speedometer"></ion-icon>`,
      children: [],
      link: "/allmeters",
    },

    {
      id: `${uuid.v4()}`,
      title: "Wallet",
      icon: `<ion-icon name="people"></ion-icon>`,
      link: "/wallet",
      children: [],
    },
    {
      id: `${uuid.v4()}`,
      title: "Meter Settings",
      icon: `<ion-icon name="git-pull-request"></ion-icon>`,
      link: "",
      children: [
        {
          id: `${uuid.v4()}`,
          title: "Payment plans",
          children: [],
          icon: "",
          link: "/allpaymentplans",
        },
        {
          id: `${uuid.v4()}`,
          title: "Meter class",
          children: [],
          icon: "",
          link: "/meterclass",
        },
      ],
    },
    {
      id: `${uuid.v4()}`,
      title: "Profile",
      icon: `<ion-icon name="git-pull-request"></ion-icon>`,
      link: "/settings",
      children: [],
    },
  ],
};

// {
//   id: `${uuid.v4()}`,
//   title: "Profile",
//   children: [],
//   icon: "",
//   link: "/settings/profile",
// },

// {
//   id: `${uuid.v4()}`,
//   title: "User Management",
//   icon: `<ion-icon name="speedometer"></ion-icon>`,
//   children: [],
//   link: "/channel/usermanagement",
// },
// {
//   id: `${uuid.v4()}`,
//   title: "Payment plans",
//   icon: `<ion-icon name="speedometer"></ion-icon>`,
//   children: [],
//   link: "/allpaymentplans",
// },
// {
//   id: `${uuid.v4()}`,
//   title: "Roles",
//   icon: `<ion-icon name="people"></ion-icon>`,
//   link: "/userroles",
// },
// {
//   id: `${uuid.v4()}`,
//   title: "Meter class",
//   icon: `<ion-icon name="speedometer"></ion-icon>`,
//   children: [],
//   link: "/meterclass",
// },

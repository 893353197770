import CardTitle from "../../CardTitle";
import "./styles.css";
import { Input } from "antd";
import { useState } from "react";
import { Button } from "../../Button";
import { themeColor as color } from "../../../constant/color";
import { data } from "./data";
import axios from "../../../utils/axios";
import toast from "react-hot-toast";
import { useEffect } from "react";
import { channelController } from "../../../controllers/channelController";
import { DatePicker, Select } from "antd";
import moment from "moment";

const AdvancedFitler = ({ closeModal, refreshBtn, setData }) => {
  const [tabState, setTabState] = useState("");
  const [page, setPage] = useState(false);
  const [pageNumer, setPageNumber] = useState(1);
  const [placeholder, setPlaceholder] = useState("");
  const [title, setTitle] = useState("");
  const [projectSites, setProjectSites] = useState([]);
  const controller = new AbortController();
  const [selectedColumn, setSelectedColumn] = useState("");
  const [columnInput, setColumnInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [date, setdate] = useState("");
  const { Option } = Select;

  const handleSearch = async () => {
    try {
      setLoading(true);
      let res = await axios.post("/customer/getallmetercustomers", {
        column: tabState,
        columnparameter: columnInput,
      });
      let result = channelController(res);
      if (result.type !== "success") {
        toast.error(result.message);
        setLoading(false);
        return;
      }
      setLoading(false);
      setData(result.message.body);
      if (!result.message.body.length) {
        return toast.success("Search completed. With no entry");
      }
      toast.success("Search completed");
      closeModal(false);
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.response);
        setLoading(false);
        return;
      }
      toast.error(err.message);
      setLoading(false);
    }
  };

  return (
    <div className="backdrop">
      <div className="advanced-filter__cover bg-white rounded-2 shadow-sm p-3">
        <div className="container mt-1">
          {!page ? (
            <>
              <div className="">
                <CardTitle
                  title={"Advanced Search"}
                  caption={
                    "Tap on any of the buttons to select the field you want to search with."
                  }
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                {data.map(({ id, title, tag }) => (
                  <div
                    key={id}
                    className="my-1 "
                    onClick={() => {
                      setTabState(tag);
                      // setColumn(tag);
                      setPlaceholder(title);
                      setTitle(title);
                    }}
                  >
                    <div
                      className="search-btn text-center px-3 py-2 rounded-md"
                      style={{
                        color:
                          tabState == tag ? color.whiteColor : color.baseColor,
                        background:
                          tabState == tag ? color.baseColor : color.whiteColor,
                        fontSize: "12px",
                        border:
                          tabState == tag ? "" : `1px solid ${color.baseColor}`,
                      }}
                    >
                      {title}
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className="mb-5 w-full">
              <div className="search-title py-3">{title}</div>

              {tabState === "datecreated" ? (
                <div className="w-full col-12 ">
                  <DatePicker
                    size="large"
                    className="w-full"
                    placeholder="select a date created"
                    style={{
                      width: "100%",
                    }}
                    showTime
                    onChange={(e) => {
                      setColumnInput(
                        moment(e._d).format("YYYY-MM-DD HH:mm:ss")
                      );
                    }}
                  />
                </div>
              ) : tabState === "role" ? (
                <div className="w-full col-span-12 ">
                  <Select
                    style={{
                      width: "100%",
                    }}
                    size="large"
                    onChange={(e) => setColumnInput(e)}
                    options={[
                      {
                        value: "Customer Representative",
                        label: "Customer Representative",
                      },
                      {
                        value: "Sales Agent",
                        label: "Sales Agent",
                      },
                    ]}
                  />
                </div>
              ) : (
                <div className="col-span-12">
                  <Input
                    placeholder={`Enter ${placeholder.toLowerCase()}`}
                    size="large"
                    onChange={(e) => setColumnInput(e.target.value)}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <div className=" py-2 mt-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="">
              <div
                className="rounded-md"
                style={{
                  border: `1px solid  ${color.baseColor}`,
                }}
                onClick={() => {
                  if (pageNumer == 2) {
                    setPageNumber(1);
                    setPage(false);
                  } else {
                    closeModal(false);
                  }
                }}
              >
                <Button
                  text={pageNumer == 2 ? "Back" : "Cancel"}
                  bg={color.whiteColor}
                  color={color.baseColor}
                  height={"35px"}
                  fontSize={"12px"}
                  border={`1px solid ${color.baseColor}`}
                />
              </div>
            </div>
            <div
              className=""
              onClick={() => {
                if (pageNumer == 1) {
                  setPageNumber(2);
                  setPage(true);
                } else {
                  return handleSearch();
                }
              }}
            >
              <Button
                text={pageNumer == 2 ? "Search" : "Next"}
                bg={color.baseColor}
                color={color.whiteColor}
                height={"35px"}
                fontSize={"12px"}
                status={loading}
                border={`1px solid ${color.baseColor}`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvancedFitler;

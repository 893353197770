import EmptyState from "../../../EmptyState";
import { HiUserGroup } from "react-icons/hi";
import moment from "moment";
import { toast } from "react-hot-toast";
import { channelController } from "../../../../controllers/channelController";
import { Button } from "../../../Button";
import axios from "../../../../utils/axios";
import { FcCalendar } from "react-icons/fc";
import DataTable, { TableColumn } from "react-data-table-component";

import { useState } from "react";
const UserTable = ({ data, refreshBtn }) => {
  const [username, setUsername] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");

  const handleActivate = async (item) => {
    try {
      if (!item?.username) {
        toast.error("Please select a username");
        return;
      }
      setLoading(true);
      let res = await axios.post("/auth/activateuseraccount", {
        username: item?.username,
      });
      let result = channelController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setLoading(false);
        return;
      }
      setMsg("Activating user account");
      toast.success("Account deactivated successfully.");
      setLoading(false);
      refreshBtn((prev) => !prev);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleDeactivate = async (item) => {
    try {
      if (!item?.username) {
        toast.error("Please select a username");
        return;
      }
      setLoading(true);
      let res = await axios.post("/auth/deactivateuseraccount", {
        username: item?.username,
      });
      let result = channelController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setLoading(false);
        return;
      }
      setMsg("Activating user account");
      toast.success("Account deactivated successfully.");
      setLoading(false);
      refreshBtn((prev) => !prev);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const columns = [
    {
      name: "Username",
      center: true,
      selector: (row) => row.username,
      grow: 1,
    },
    {
      name: "Full name",
      // selector: (row) => row.,
      center: true,
      sortable: true,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div>{item.firstname + item.lastname}</div>
          </div>
        );
      },
    },
    {
      name: "Email",
      selector: (row) => row.email,
      center: true,
      sortable: true,
      grow: 2,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      center: true,
      grow: 2,
    },
    {
      name: "Wallet balance",
      selector: (row) => row.walletbalance,
      center: true,
      grow: 1,
    },

    // {
    //   name: "Duration",
    //   center: true,
    //   selector: (row) => row.sales,
    //   sortable: true,
    //   grow: 2,
    // },
    {
      name: "Date registered",
      center: true,
      selector: (row) => row.dateregistered,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="px-1">
              <FcCalendar size={18} />
            </div>
            <div className="text-[11px]">
              {moment(item.dateregistered).format("lll")}
            </div>
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => row.deviceid,
      grow: 3,
      cell: (item) => {
        return (
          <div className="flex relative rounded-md items-center justify-between">
            <div
              onClick={() => {}}
              className="border-[1px] cursor-pointer rounded border-gray-400 text-[12px] font-light px-4 py-2"
            >
              Details
            </div>
            {item.activestatus == "active" ? (
              <div
                onClick={() => handleDeactivate(item)}
                className="border-[1px] cursor-pointer ml-2 rounded border-red-400 text-[12px] text-red-400 font-light px-4 py-2"
              >
                Deactivate
              </div>
            ) : (
              <div
                onClick={() => handleActivate(item)}
                className="border-[1px] cursor-pointer ml-2 rounded border-green-600 text-[12px] text-green-600 font-light px-4 py-2"
              >
                Activate
              </div>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div style={{}}>
      <div>
        {/* {!data.length ? (
          <EmptyState>
            <div className="h-[70vh] w-full">
              <div className="flex items-center justify-center mt-[200px]">
                <HiUserGroup size={30} />
              </div>
              <div className="font-normal text-[15px] text-center">
                You have not performed any wallet transaction
              </div>
            </div>
          </EmptyState>
        ) : ( */}
        <DataTable
          style={{
            height: "100%",
          }}
          columns={columns}
          data={data}
          pagination
          paginationPerPage={10}
        />
      </div>
    </div>
  );
};

export default UserTable;

{
  /* // <table className="w-full border-collapse">
          //   <thead className="h-[50px] drop-shadow-md border-b-[0.5px] border-gray-800">
          //     <th className="font-semibold text-gray-800">S/N</th>
          //     <th className="font-semibold text-gray-800">username</th>
          //     <th className="font-semibold text-gray-800">Name</th>
          //     <th className="font-semibold text-gray-800">Email </th>
          //     <th className="font-semibold text-gray-800">Phone</th>
          //     <th className="font-semibold text-gray-800">Active Status</th>
          //     <th className="font-semibold text-gray-800">Wallet balance </th>
          //     <th className="font-semibold text-gray-800">Date registered</th>
          //     {/* <th className="font-semibold text-gray-800">Time range</th> */
}
{
  /* //     <th className="font-semibold text-gray-800">Action</th>
          //   </thead>
          //   <tbody>
          //     {data.map((i, index) => ( */
}
//       <tr
//         className="bg-white hover:bg-gray-50 cursor-pointer h-[50px] even:bg-gray-50"
//         key={i?.roleid}
//       >
//         <td className="text-center">{index + 1}</td>
//         <td className="text-center">{i?.username}</td>
//         <td className="text-center">
//           {!(i.firstname && i.lastname)
//             ? "-"
//             : i?.firstname + " " + i.lastname}
//         </td>
//         <td className="text-center">{!i?.email ? "-" : i.email}</td>
//         <td className="text-center">{!i?.phone ? "-" : i.phone}</td>
//         <td
//           className={
//             i?.activestatus == "active"
//               ? "text-center text-green-500"
//               : "text-center text-red-500"
//           }
//         >
//           {i?.activestatus}
//         </td>
//         <td className="text-center">{i?.walletbalance}</td>
//         <td className="text-center">
//           <div className="flex items-center justify-center">
//             <div className="pl-2">
//               {moment(i?.dateregistered).format("lll")}
//             </div>
//           </div>
//         </td>

//         <td className="text-center flex justify-around">
//           <div className="border-[1px] rounded border-secondary text-[12px] text-secondary font-light px-4 py-2">
//             View
//           </div>
//           {i.activestatus == "active" ? (
//             <div
//               onClick={() => handleDeactivate(i)}
//               className="border-[1px] rounded border-red-400 text-[12px] text-red-400 font-light px-4 py-2"
//             >
//               Deactivate
//             </div>
//           ) : (
//             <div
//               onClick={() => handleActivate(i)}
//               className="border-[1px] rounded border-green-600 text-[12px] text-green-600 font-light px-4 py-2"
//             >
//               Activate
//             </div>
//           )}
//         </td>
//       </tr>
//     ))}
//   </tbody>
// </table>

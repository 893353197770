// import CardTitle from "../../../CardTitle";
import "./styles.css";
import { IoClose } from "react-icons/io5";
import moment from "moment";
import { useEffect, useState } from "react";
import axios from "axios";
// import { meterController } from "../../../controllers/MeterController";
import toast from "react-hot-toast";
import { BsCalendar2Date } from "react-icons/bs";
import { FcFlashOn } from "react-icons/fc";
import { FaUser } from "react-icons/fa";
import { Button } from "../../../Button";
import { themeColor as color } from "../../../../constant/color";
import { meterController } from "../../../../controllers/MeterController";

const MeterInfoModal = ({ closeModal, meterNumber }) => {
  const [meterDetails, setMeterDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        // let res = await axios("/meter/getmeter", {
        //   meternumber: meterNumber,
        // });
        // let result = meterController(res);
        // if (result.type !== "success") {
        //   toast[result.type](result.message);
        //   setLoading(false);
        //   return;
        // }
        // setMeterDetails(result.message.body);
        // setLoading(false);
      } catch (err) {
        toast.error(err.message);
        console.log(err.message);
      }
    })();
  }, []);

  return (
    <div className="backdrop">
      <div className="meter-info__modal px-3 py-3 rounded-1 shadow-sm bg-white animate__animated animate__fadeInUp">
        <div className="meter-info__top pb-1 d-flex align-items-center justify-content-between">
          <div className="meter-info-title">Meter Info</div>
          <div className="meter-info-title" onClick={() => closeModal(false)}>
            <IoClose size={18} color={color.darkColor} />
          </div>
        </div>
        <div className="">
          <div className="meter-info__entry mt-2 d-flex align-items-center justify-content-between">
            <div className="meter-info__title">Meter number:</div>
            <div className="meter-info__caption">324277</div>
          </div>
          <div className="meter-info__entry mt-2 d-flex align-items-center justify-content-between">
            <div className="meter-info__title">Meter Type:</div>
            <div className="meter-info__caption">Meter</div>
          </div>
          <div className="meter-info__entry mt-2 d-flex align-items-center justify-content-between">
            <div className="meter-info__title">Meter Registrar:</div>
            <div className="meter-info__caption">Kunle</div>
          </div>
          <div className="meter-info__entry mt-2 d-flex align-items-center justify-content-between">
            <div className="meter-info__title">Meter power status:</div>
            <div
              className="meter-info__caption"
              style={{
                color: color.greenColor,
              }}
            >
              Active
            </div>
          </div>
          <div className="meter-info__entry mt-2 d-flex align-items-center justify-content-between">
            <div className="meter-info__title">Meter load limit:</div>
            <div className="meter-info__caption">
              304
              <span>
                <FcFlashOn size={15} />
              </span>
            </div>
          </div>
          <div className="meter-info__entry mt-2 d-flex align-items-center justify-content-between">
            <div className="meter-info__title">Date registered:</div>
            <div className="meter-info__caption">
              <span className="mb-3 mx-1">
                <BsCalendar2Date size={13} color={color.baseColor} />
              </span>
              {moment(Date.now())
                .subtract("1", "week")
                .format("DD-MM-YYYY HH:mm")}
            </div>
          </div>
          <div className="meter-info__entry mt-2 d-flex align-items-center justify-content-between">
            <div className="meter-info__title">Date decommissioned:</div>
            <div className="meter-info__caption">
              <span className="mb-3 mx-1">
                <BsCalendar2Date size={13} color={color.baseColor} />
              </span>
              {moment(Date.now()).format("DD-MM-YYYY HH:mm")}
            </div>
          </div>
          <div className="meter-info__section my-2">
            <div className="meter-info__entry mt-2 d-flex align-items-center justify-content-between">
              <div className="meter-info__title">Load control Policy:</div>
              <div className="meter-info__caption">
                No load policy issued on meter.
              </div>
            </div>
          </div>
          <div className="row mt-4 d-flex align-items-center justify-content-end">
            <div className="col-lg-6 d-none">
              <Button
                text={"Cancel"}
                bg={color.btnFadeColor}
                color={color.darkColor}
                height={"35px"}
              />
            </div>
            <div className="col-4" onClick={() => closeModal(false)}>
              <Button
                text={"Done"}
                bg={color.baseColor}
                color={color.whiteColor}
                height={"35px"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeterInfoModal;

import { useEffect, useState } from "react";
import { useParams, useSearchParams, Link } from "react-router-dom";
import BackBtn from "../../../components/BackBtn";
import CardTitle from "../../../components/CardTitle";
import PowerQuality from "../../../components/Dashboards/PowerQuality";
import StatsCard from "../../../components/Dashboards/StatsCard";
import Layout from "../../../components/Layout";
import MoreBtn from "../../../components/MoreBtn";
import ProjectManagerCard from "../../../components/Roles/Card/ProjectManagerCard";
import RolesCard from "../../../components/Roles/Card/RolesCard";
import TopCard from "../../../components/TopCard";
import TopNav from "../../../components/TopNav";
import axios from "../../../utils/axios";
import toast from "react-hot-toast";
import moment from "moment";
import DateRangePicker from "../../../components/DateRangePicker";
import { Empty } from "antd";
import TableTop from "../../../components/TableTop";
import MeterClassTable from "../../../components/MeterClass/Tables/MeterClassTable/MeterClassTable";
import Rule from "../../../components/Rule/Index";
import SalesLineChart from "../../../components/Dashboards/SalesAgent/Charts/SalesLineChart";
const ProjectManagerDashboard = () => {
  let { id } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const sitename = searchParams.get("projectsite");
  // sitename = decodeURI(sitename);
  const [projectSite, setProjectSite] = useState({});
  const [startDate, setStartDate] = useState(
    moment(Date.now()).subtract("1", "week").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );
  const [refresh, setRefresh] = useState(false);
  const [projectManagers, setProjectManagers] = useState([]);
  const [customerManagers, setCustomerManagers] = useState([]);
  const [meters, setMeters] = useState(0);
  const [customers, setCustomers] = useState(0);
  const [revenue, setRevenue] = useState(0);

  const [meterclass, setMeterClass] = useState([]);

  let projectSiteName = sitename || id;
  useEffect(() => {
    try {
      axios
        .post("/sitemanagement/getspecificsitebysitename", {
          sitename: projectSiteName,
        })
        .then((response) => {
          setProjectSite(response.data);
        });
      axios
        .post("/roles/getallusersbyzoneandrole", {
          role: "Project Admin",
          projectzone: projectSiteName,
        })
        .then((response) => {
          setProjectManagers(response.data.body);
        });
      axios
        .post("/roles/getallusersbyzoneandrole", {
          role: "Customer Representative",
          projectzone: projectSiteName,
        })
        .then((response) => {
          setCustomerManagers(response.data.body);
        });
      axios
        .post("/roles/getallusersbyzoneandrole", {
          role: "Customer",
          projectzone: projectSiteName,
        })
        .then((response) => {
          setCustomers(response.data.meta.count);
        });
      axios
        .post("/meter/getallmetersinzone", { zone: projectSiteName })
        .then((response) => {
          setMeters(response.data.meta.count);
        });
    } catch (err) {
      toast(err.message);
    }
  }, []);

  useEffect(() => {
    axios
      .post("/salesanalytics/getsaleshistoryperzone", {
        projectzone: JSON.stringify([sitename]),
        assettype: JSON.stringify([]),
        paymentoperation: JSON.stringify(["Vending"]),
        startdate: startDate,
        enddate: endDate,
        limit: 30,
        offset: 0,
      })
      .then((response) => {
        setRevenue(response.data.body.reduce((p, c) => (p += c.amount), 0));
      })
      .catch((err) => toast(err.message));
  }, [refresh]);

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/settings/getallcustomerclassdata", {});
        setMeterClass(res.data.body);
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, []);

  return (
    <Layout>
      <div className="container-fluid mt-4">
        <BackBtn text={"Go back"} />
        {/* stats and Project site */}
        <div className="grid grid-cols-2 gap-4">
          <div className=" p-2 w-full bg-white shadow-md rounded-md">
            <div className="font-semibold">Project site details</div>
            <div className="font-bold text-[30px] mt-1">{projectSiteName}</div>
            <Rule />
            <div className="py-2 flex items-center justify-between">
              <div className="">Site type:</div>
              <div className="font-semibold">
                {projectSite ? projectSite?.sitetype : ""}
              </div>
            </div>
            <div className="py-2 flex items-center justify-between">
              <div className="">GPS:</div>
              <div className="font-semibold">
                {projectSite ? projectSite?.gps : ""}
              </div>
            </div>
            <div className="py-2 flex items-center justify-between">
              <div className="">Date created:</div>
              <div className="font-semibold">
                {projectSite
                  ? moment(projectSite?.datecreated).format("lll")
                  : ""}
              </div>
            </div>
          </div>
          <div className="row bg-white shadow-md rounded-md">
            <StatsCard
              meters={meters}
              meterTxt="Total meters"
              customerManagers={customerManagers.length}
              customers={customers}
              revenue={revenue}
            />
          </div>
        </div>
        {/* date filter */}
        <div className="row mt-3 hidden justify-content-between">
          <div className="col-4 hidden">
            <TopCard
              title={"Project Site"}
              value={sitename}
              fontSize={"17px"}
              caption={projectSite.sitetype}
            />
          </div>
          <div className="col-4">
            <DateRangePicker
              setEndDate={setEndDate}
              setStartDate={setStartDate}
              refreshBtn={setRefresh}
            />
          </div>
        </div>
        {/* Power Quality and Roles */}
        <div className="w-100 mt-4 bg-white p-2 shadow-md rounded-md ">
          <div className="flex h-[40px] border-b-1 border-gray-400  items-start justify-between">
            <div className="">
              <div className="font-semibold">Revenue</div>
              <div className="text-gray-500 font-light">
                Revenue generated from project site.{" "}
              </div>
            </div>
            <div className="">
              <div className="italic hidden font-light text-sm text-end">
                Select a date range
              </div>
              <div className="h-[500px]">
                <DateRangePicker />
              </div>
            </div>
          </div>

          <div className="h-[500px]">
            <SalesLineChart />
          </div>
        </div>
        {/* Roles in site */}
        <div className="grid grid-cols-3 gap-4 mt-4">
          {/* customer Representative */}
          <div className="w-100 h-[500px] bg-white p-2 dshb-roles__cover shadow-md rounded-md">
            <div className="w-100 flex items-center justify-between">
              <div className="">
                <CardTitle title={"Customer Representative."} />
              </div>
              <div className="d-flex align-items-center justify-content-center more-btn">
                <Link
                  className="text-primary"
                  to="/dashboard/system-admin/roles?role=Customer Manager"
                >
                  View all{" "}
                </Link>
              </div>
            </div>
            {customerManagers.length ? (
              <div className="pt-2">
                {customerManagers.map((customerManager, i) => (
                  <RolesCard
                    key={i}
                    role={"Customer Representative"}
                    entity={customerManager}
                    link={`/dashboard/customermanager/${customerManager?.projectzone}/${customerManager?.username}`}
                  />
                ))}
              </div>
            ) : (
              <Empty style={{ height: "100%", paddingTop: "20%" }} />
            )}
          </div>
          {/* Sales Agent */}
          <div className="w-100 h-[500px] bg-white p-2 dshb-roles__cover shadow-md rounded-md">
            <div className="w-100 flex items-center justify-between">
              <div className="">
                <CardTitle title={"Sales Agent."} />
              </div>
              <div className="d-flex align-items-center justify-content-center more-btn">
                <Link
                  className="text-primary"
                  to="/dashboard/system-admin/roles?role=Customer Manager"
                >
                  View all{" "}
                </Link>
              </div>
            </div>
            {customerManagers.length ? (
              <div className="pt-2">
                {customerManagers.map((customerManager, i) => (
                  <RolesCard
                    key={i}
                    role={"Customer Representative"}
                    entity={customerManager}
                    link={`/dashboard/customermanager/${customerManager?.projectzone}/${customerManager?.username}`}
                  />
                ))}
              </div>
            ) : (
              <Empty style={{ height: "100%", paddingTop: "20%" }} />
            )}
          </div>
          <div className="col-sm-12 col-md-4 col-lg-5 col-xl-5">
            <div className="bg-white h-[500px] shadow-sm rounded-1 p-2">
              <div className="">
                <CardTitle title={"Recent roles."} />
              </div>
              {customerManagers.length ? (
                customerManagers.map((item) => (
                  <ProjectManagerCard
                    entities={customerManagers}
                    role={"Customer Manager"}
                    link={`/dashboard/customermanager/${item?.projectzone}/${item?.username}`}
                  />
                ))
              ) : (
                <Empty style={{ paddingBottom: "10%" }} />
              )}
            </div>
          </div>
        </div>
        {/*  */}

        {/* Power Quality and Roles */}
        <div className="w-100 mt-4 bg-white p-2 shadow-md rounded-md ">
          <div className="flex h-[30px] border-b-1 border-gray-400  items-start justify-between">
            <div className="">
              <div className="font-semibold">Power quality data</div>
              <div className="text-gray-500 font-light">
                Power quality data from active meter from project site .{" "}
              </div>
            </div>
            <div className="">
              <div className="italic hidden font-light text-sm text-end">
                Select a date range
              </div>
              <div className="h-[500px]">
                <DateRangePicker />
              </div>
            </div>
          </div>

          <div className="h-[500px]">
            <PowerQuality />
          </div>
        </div>

        {/* Meters table*/}
        <div
          className="row hidden bg-white mt-4"
          style={{
            marginBottom: "500px",
          }}
        >
          <div className="">
            <TableTop title={"Meter Class"} />
          </div>
          <div className="">
            <MeterClassTable data={meterclass} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProjectManagerDashboard;

import { themeColor as color } from "../../../constant/color";
import "./styles.css";
import { IoIosClose } from "react-icons/io";
import moment from "moment";
const TOUCard = ({ data, setTOU, tou }) => {
  return (
    <div
      className="tou-card my-2 bg-white p-2 rounded-1 shadow-sm"
      style={{
        borderLeft: `2px solid ${color.baseColor}`,
      }}
    >
      <div
        className="tou-close__btn"
        onClick={() => {
          let filteredArray = tou.filter((item) => item.bp !== data?.bp);
          setTOU(filteredArray);
        }}
      >
        <IoIosClose size={20} color={color.darkColor} />
      </div>
      <div className="tou-entry mt-3 d-flex aligns-items-center justify-content-between w-100">
        <div className="tou-date">Start Time: </div>
        <div className="tou-value">{data?.st}</div>
      </div>
      <div className="tou-entry d-flex aligns-items-center justify-content-between w-100">
        <div className="tou-date">End Time:</div>
        <div className="tou-value">{data?.et}</div>
      </div>
      <div className="tou-entry d-flex aligns-items-center justify-content-between w-100">
        <div className="tou-bp">Billing %:</div>
        <div className="tou-value">{Number(data?.bp)}</div>
      </div>
    </div>
  );
};

export default TOUCard;

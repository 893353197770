import moment from "moment";
import { FcCalendar } from "react-icons/fc";
import { Link } from "react-router-dom";

import { HiUserGroup } from "react-icons/hi";
import { useState } from "react";
import EmptyState from "../../../EmptyState";

const MeterClassTable = ({ data = [], setdetails, setmodal }) => {
  const touFormatter = (item) =>
    item
      .substring(1)
      .split("#")
      .map((item) => {
        return {
          pt: parseInt(item.substring(item.length - 3, item.length)) + "%",
          st:
            item.substring(0, 6).substring(0, 2) +
            ":" +
            item.substring(0, 6).substring(2, 4) +
            ":" +
            item.substring(0, 6).substring(4, 6),
          et:
            item.substring(6, 13).substring(0, 2) +
            ":" +
            item.substring(6, 13).substring(2, 4) +
            ":" +
            item.substring(6, 13).substring(4, 6),
        };
      });

  return (
    <div>
      {!data.length ? (
        <EmptyState>
          <div className="h-[70vh] w-full">
            <div className="flex items-center justify-center mt-[200px]">
              <HiUserGroup size={30} />
            </div>
            <div className="font-normal text-[15px] text-center">
              You don't have any meter class
            </div>
          </div>
        </EmptyState>
      ) : (
        <table className="w-full border-collapse">
          <thead className="h-[50px] drop-shadow-md border-b-[0.5px] border-gray-800">
            <th className="font-semibold text-gray-800">S/N</th>
            <th className="font-semibold text-gray-800">Meterclass Name</th>
            <th className="font-semibold text-gray-800 w-[200px]">
              Active time of use
            </th>
            <th className="font-semibold text-gray-800">Description</th>

            <th className="font-semibold text-gray-800">Date created</th>
            {/* <th className="font-semibold text-gray-800">Time range</th> */}
            <th className="font-semibold text-gray-800">Action</th>
          </thead>
          <tbody>
            {data.map((i, index) => (
              <tr
                className="bg-white whitespace-nowrap hover:bg-gray-50 cursor-pointer h-[50px] even:bg-gray-50"
                key={i?.roleid}
              >
                <td className="text-center">{index + 1}</td>
                <td className="text-center">{i?.meterclass}</td>
                <td className="text-center text-secondary w-[400px]">
                  <div className="flex flex-wrap justify-center">
                    {touFormatter(i?.tou).length > 1
                      ? touFormatter(i?.tou)
                          .slice(0, 2)
                          .map(({ pt, st, et }, index, arr) => (
                            <div
                              className="even:border-l-[1px] even:ml-2 border-[1px] rounded-lg p-2 my-[2px] border-t-secondary"
                              key={index}
                            >
                              <div className="flex items-center justify-start">
                                <div className="font-light text-[12px]">
                                  Start time:
                                </div>
                                <div className="font-light text-[12px]">
                                  {" "}
                                  {"  "}
                                  {st}
                                </div>
                              </div>
                              <div className="flex items-center justify-start">
                                <div className="font-light text-[12px]">
                                  End time:
                                </div>
                                <div className="font-light text-[12px]">
                                  {" "}
                                  {"  "}
                                  {et}
                                </div>
                              </div>{" "}
                              <div className="flex items-center justify-start">
                                <div className="font-light text-[12px]">
                                  Billing percentage:
                                </div>
                                <div className="font-light text-[12px]">
                                  {" "}
                                  {"  "}
                                  {pt}
                                </div>
                              </div>
                            </div>
                          ))
                      : touFormatter(i?.tou).map(
                          ({ pt, st, et }, index, arr) => (
                            <div
                              className="even:border-l-[1px] even:ml-2 border-[1px] rounded-lg p-2 my-[2px] border-t-secondary"
                              key={index}
                            >
                              <div className="flex items-center justify-start">
                                <div className="font-light text-[12px]">
                                  Start time:
                                </div>
                                <div className="font-light text-[12px]">
                                  {" "}
                                  {"  "}
                                  {st}
                                </div>
                              </div>
                              <div className="flex items-center justify-start">
                                <div className="font-light text-[12px]">
                                  End time:
                                </div>
                                <div className="font-light text-[12px]">
                                  {" "}
                                  {"  "}
                                  {et}
                                </div>
                              </div>{" "}
                              <div className="flex items-center justify-start">
                                <div className="font-light text-[12px]">
                                  Billing percentage:
                                </div>
                                <div className="font-light text-[12px]">
                                  {" "}
                                  {"  "}
                                  {pt}
                                </div>
                              </div>
                            </div>
                          )
                        )}
                  </div>
                  {/* {touFormatter(i?.tou).map((i, index) => (
                    <div key={index}>
                      <div> tou</div>
                    </div>
                  ))} */}
                </td>
                <td className="rounded-lg text-center">{i?.description}</td>
                <td className="text-center">
                  <div className="flex items-center justify-center">
                    <div className="pl-2">
                      {moment(i?.dateentered).format("lll")}
                    </div>
                  </div>{" "}
                </td>

                <td className="">
                  <div className="grid grid-cols-2 gap-4 place-content-end justify-center">
                    <div className="border-[1px] hidden text-center rounded border-orange-600 text-[12px] text-orange-600 font-light px-4 py-2">
                      Edit
                    </div>
                    <div
                      onClick={() => {
                        setdetails(i);
                        setmodal(true);
                      }}
                      className="border-[1px] text-center rounded border-secondary text-[12px] text-secondary font-light px-4 py-2"
                    >
                      View
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default MeterClassTable;

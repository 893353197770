import { BiUserPlus } from "react-icons/bi";
import "./styles.css";
import AddRoleImg from "../../../../assets/svg/adduser.svg";
import { useState, useEffect } from "react";
import { Select } from "antd";
import "antd/dist/antd.css";
import toast from "react-hot-toast";
import axios from "../../../../utils/axios";
import { userController } from "../../../../controllers/UserController";
import { meterController } from "../../../../controllers/MeterController";
import { GiConsoleController } from "react-icons/gi";
import { getToken } from "../../../../utils/token";
import { themeColor as color } from "../../../../constant/color";
import { Button } from "../../../Button";

const AssignMeter = ({ closeModal }) => {
  const [showInput, setShowInput] = useState(false);
  const [tabState, setTabState] = useState(0);
  const [loading, setLoading] = useState(false);

  const { Option } = Select;

  const [customerId, setcustomerId] = useState("");
  const [meterId, setMeterId] = useState("");
  const [address, setAddress] = useState([]);
  const [selectedAddress, setselectedAddress] = useState("");
  const [connectionfee, setConnectionfee] = useState("");
  const [periodicdebit, setPeriodicdebit] = useState("");
  const [connectionbalance, setConnectionBalance] = useState("");
  const [dumbMeterfee, setDumbMeterFee] = useState("");
  const handleUserName = async () => {
    try {
      if (!customerId) return toast.error("Please enter a username");
      setLoading(true);
      let res = await axios.post("/auth/account/getfirstandlastname", {
        username: customerId,
      });

      let result = userController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setLoading(false);
        return;
      }

      if (!result.message.firstname && !result.message.lastname) {
        toast.error("You can only assign roles to verified account.");
        setLoading(false);
        return;
      }
      setLoading(false);
      setTabState(1);

      let userAddress = await axios.post(
        "/customer/getallmetercustomeraddresses",
        {
          customerid: customerId,
        }
      );

      let resultAddress = userController(userAddress);
      if (resultAddress.type !== "success") {
        toast[resultAddress.type](resultAddress.message);
        return;
      }
      setAddress(resultAddress.message.body);
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      // console.log(
      //   periodicdebit,
      //   connectionbalance,
      //   connectionfee,
      //   dumbMeterfee,
      //   address,
      //   customerId,
      //   meterId
      // );

      if (
        !periodicdebit ||
        !connectionbalance ||
        !connectionfee ||
        !dumbMeterfee ||
        !selectedAddress ||
        !customerId ||
        !meterId
      )
        return toast.error("All values are required");
      setLoading(true);
      let res = await axios.post("/meter/assignmeter", {
        meternumber: meterId,
        customerid: customerId,
        address: selectedAddress,
        connectionfee: connectionfee,
        connectionbalance: connectionbalance,
        periodicdebit: periodicdebit,
        dumbmeterfee: dumbMeterfee,
      });
      let result = meterController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setLoading(false);
        return;
      }
      if (!result.message.response) {
        setLoading(false);
        toast.error("Operation failed. Please try again.");
        return;
      }
      setLoading(false);
      toast.success("Meter issued successfully");
      closeModal(false);
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  };

  return (
    <div className="backdrop">
      <div className="addrole-card px-3 py-4 animate__animated animate__fadeInUp d-flex flex-column align-items-center justify-content-start p-2 rounded-2 shadow-sm bg-white  shadow-sm rounded-1">
        <div className="addrole-img">
          <img
            src={AddRoleImg}
            width={"100%"}
            height={"100%"}
            alt={"role img"}
          />
        </div>
        <div
          className="cardtitle var(---basecolor) mb-1 "
          style={{
            color: color.baseColor,
            fontWeight: "400",
            fontSize: "14px",
          }}
        >
          Assign Meter to Customer
        </div>
        {tabState == 1 || tabState == 2 ? (
          <div className="cardcaption text-center mb-2">
            Fill the input fields below to add a new customer.
          </div>
        ) : null}

        {tabState == 0 && (
          <div>
            <div className="cardcaption text-center mb-4">
              To assign a meter to a customer, start by searching for the
              username of the account.
            </div>
            <div className="input-entry w-100 mt-3 ">
              <input
                type="text"
                className="w-100 input-box px-2 rounded-1"
                placeholder={"Enter the username of the user."}
                value={customerId}
                style={{
                  border: `1px solid ${color.baseColor}`,
                }}
                onChange={(e) => setcustomerId(e.target.value)}
              />
            </div>
          </div>
        )}

        {tabState == 1 && (
          <>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="input-entry w-100 mt-3 ">
                  <input
                    type="text"
                    className="w-100 input-box px-2 rounded-1"
                    placeholder={"Enter meterId"}
                    style={{
                      fontSize: "13px",
                    }}
                    value={meterId}
                    onChange={(e) => setMeterId(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="input-entry w-100 mt-3 ">
                  <input
                    type="text"
                    className="w-100 input-box px-2 rounded-1"
                    placeholder={"Enter customerId"}
                    value={customerId}
                    disabled={true}
                    style={{
                      fontSize: "13px",
                    }}
                    onChange={(e) => setcustomerId(e.target.value)}
                  />
                </div>
              </div>
              <div
                className="input-entry w-100 mt-3 "
                style={{
                  border: `1px solid ${color.baseColor}`,
                }}
              >
                {/* <textarea
                  style={{
                    height: "70px",
                    resize: "none",
                    fontSize: "13px",
                  }}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  className="w-100 input-box px-2 rounded-1"
                  placeholder={"Enter address."}
                /> */}
                <Select
                  size="large"
                  style={{
                    width: "100%",
                    height: 45,
                    fontSize: "13px",
                  }}
                  bordered={false}
                  placeholder={"Select address"}
                  onChange={(e) => setselectedAddress(e)}
                >
                  {address.map((item) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="col-sm-12 col-md-12">
                <div className="input-entry w-100 mt-3 ">
                  <input
                    type="text"
                    className="w-100 input-box px-2 rounded-1"
                    placeholder={"Enter connection fee."}
                    value={connectionfee}
                    style={{
                      fontSize: "13px",
                    }}
                    onChange={(e) => setConnectionfee(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {tabState == 2 && (
          <>
            <div className="row">
              <div className="col-sm-12 col-md-12 mt-3">
                <div className="input-entry w-100 ">
                  <input
                    type="text"
                    className="w-100 input-box px-2 rounded-1"
                    placeholder={"Enter Connection balance"}
                    value={connectionbalance}
                    style={{
                      fontSize: "13px",
                    }}
                    onChange={(e) => setConnectionBalance(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-12 mt-3">
                <div className="input-entry w-100 ">
                  <input
                    type="text"
                    className="w-100 input-box px-2 rounded-1"
                    placeholder={"Enter periodic debit"}
                    value={periodicdebit}
                    style={{
                      fontSize: "13px",
                    }}
                    onChange={(e) => setPeriodicdebit(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-12 mt-3">
                <div className="input-entry w-100 ">
                  <input
                    type="text"
                    className="w-100 input-box px-2 rounded-1"
                    placeholder={"Enter dumbmeter fee."}
                    value={dumbMeterfee}
                    style={{
                      fontSize: "13px",
                    }}
                    onChange={(e) => setDumbMeterFee(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        <div className="d-flex align-items-center mt-3 justify-content-between w-100 addrolebtn-cover pt-3">
          <div
            className="addrole-btn"
            onClick={() => {
              if (tabState == 1) {
                return setTabState(0);
              } else if (tabState == 2) {
                return setTabState(1);
              } else if (tabState == 0) {
                return closeModal(false);
              }
            }}
          >
            <Button
              text={tabState == 1 ? "Back" : tabState == 0 ? "Cancel" : "Back"}
              bg={color.btnFadeColor}
              color={color.darkColor}
              height={"40px"}
            />
          </div>
          <div
            className="addrole-btn"
            onClick={() => {
              if (tabState == 1) {
                setTabState(2);
              } else if (tabState == 0) {
                return handleUserName();
              } else if (tabState == 2) {
                return handleSubmit();
              }
            }}
          >
            <Button
              text={tabState == 0 ? "Search" : tabState == 1 ? "Next" : "Done"}
              bg={color.baseColor}
              color={color.whiteColor}
              height={"40px"}
              status={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignMeter;

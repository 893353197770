import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import Login from "./screens/Auth/Login";
import { Navigate, Route, Routes, useNavigate, Outlet } from "react-router-dom";
import ResetPassword from "./screens/Auth/ResetPassword";
import ForgotPassword from "./screens/Auth/ForgotPassword";
import CompleteRegistration from "./screens/Auth/CompleteRegistration";
import UserDashboard from "./screens/Dashboard/UserDashboard";
import UserRoles from "./screens/Roles/UserRoles";
import SalesAgentDashboard from "./screens/Dashboard/SalesAgentDashboard";
import SysAdminDashboard from "./screens/Dashboard/SysAdminDashboard";
import CreateAccount from "./screens/Auth/CreateAccount";
import toast, { Toaster } from "react-hot-toast";
import EmailVerification from "./screens/Auth/EmailVerification";
import SignIn from "./screens/Auth/SignIn";
import SystemAdminList from "../src/screens/Dashboard/SysAdminDashboard/roleList";
import ProjectManagerDetails from "./screens/Dashboard/SysAdminDashboard/ProjectManagerDetails";
import CustomerManagerDetails from "./screens/Dashboard/SysAdminDashboard/CustomerManagerDetails";
import { ChannelContextProvider } from "./context/channelContext";
import AllChannels from "./screens/channels/Allchannels";
import MChannelDetails from "./screensMobile/Channels/MChannelDetails";
import ChannelDetails from "./screens/channels/ChannelDetails";
import SiteEnergyRecords from "./screens/Dashboard/SysAdminDashboard/Records/SiteEnergyRecords";
import SiteRevenueRecords from "./screens/Dashboard/SysAdminDashboard/Records/SiteRevenueRecords";
import SiteUsersRecords from "./screens/Dashboard/SysAdminDashboard/Records/SiteUsersRecords";
import SiteMeterRecords from "./screens/Dashboard/SysAdminDashboard/Records/SiteMeterRecords";
import Users from "./screens/Users";
import EnergyAnalytics from "./screens/Charts/EnergyAnalytics";
import RUP from "./screens/Users/Charts/RUP";
import { BillingContextProvider } from "./context/BillingContext";
import { MeterClassProvider } from "./context/MeterClassContext";
import UsersManagement from "./screens/Users/UserManagement";
import { MQTTProvider } from "./context/MqttContext";
import Groupedchannels from "./screens/channels/Groupedchannels";
import NotFound from "./screens/NotFound";

import Customers from "./screens/Customers";
import ProjectSites from "./screens/ProjectSite";
import ProjectSiteDashboard from "./screens/Dashboard/SysAdminDashboard/ProjectSiteDashboard";
import AddMeterClass from "./screens/MeterClass/AddMeterClass";
import AllUserMeters from "./screens/Users/Meter/AllUserMeters";

import Settings from "./screens/Settings";
import PaymentPlan from "./screens/Users/PaymentPlan";
import { PaymentPlanProvider } from "./context/PaymentPlanContext";
import MeterClass from "./screens/MeterClass";
import SalesAgentCustomer from "./screens/SalesAgent/SalesAgentCustomer";
import CustomerMeterDetails from "./components/SalesAgent/Modals/CustomerMeterDetails";
import CustomerMeterRecharge from "./screens/SalesAgent/CustomerMeterdetails";
import SalesAnalytics from "./screens/SalesAgent/SalesAnalytics";
import Wallet from "./screens/Wallet";
import CustomerDashboard from "./screens/Dashboard/CustomerDashboard";
import ManagerMeter from "./screens/ManageMeter";
import ProjectManagerDashboard from "./screens/Dashboard/ProjectManagerDashboard";
import { getToken, setToken } from "./utils/token";
import Notification from "./screens/Notification";
import IncomingRequest from "./screens/IncomingRequest";
import ChannelPaymentPlan from "./components/Channels/PaymentPlan/ChannelPaymentPlan";
import { useDispatch, useSelector } from "react-redux";
import { postLogin } from "./redux/actions/auth.action";
import { ProfileContext } from "./context/profileContext";
import { ProfileProvider } from "./context/ProfileContextN";
import ChannelUsers from "./screens/channels/ChannelUsers";
import MeterDetails from "./screens/Users/Meter/MeterDetails";
import RealtimeConsumptionMeter from "./screens/MeterClass/Chart/RealtimeConsumptionMeter";
import AddPlanToChannel from "./screens/channels/AddPlanToChannel";
import AllRoles from "./screens/Roles/AllRoles";
import DropDownSettings from "./screens/Settings/DropDownSettings";
import ControlSettings from "./screens/Settings/ControlSetting";
import Meters from "./screens/Meters";

function App() {
  const [loading, setloading] = useState(false);
  const [usertoken, setusertoken] = useState(" ");
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.authReducer);
  const navigate = useNavigate();

  // useEffect(() => {
  //   callInt();
  //   return () => {};
  // }, []);

  // const callInt = async () => {
  //   try {
  //     let token = await getToken("spiral_token");
  //     if (!token || !authState?.loggedIn) {
  //       navigate("/");
  //       return;
  //     }
  //     // dispatch(postLogin());
  //   } catch (err) {
  //     toast.err(err.message);
  //     setloading(false);
  //   }
  // };

  // loading ? (
  //   <div className="w-screen h-screen flex items-center justify-center">
  //     <div className="font-kanit font-semibold text-grey-800">
  //       Spiral Tech...
  //     </div>
  //   </div>
  // ) :

  return (
    <MQTTProvider>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/createaccount" element={<CreateAccount />} />
        <Route path="/emailverification" element={<EmailVerification />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route
          path="/completeregistration"
          element={<CompleteRegistration />}
        />

        {/* System Admin */}
        <Route
          path={"/dashboard/systemadmin/projectsite"}
          element={<ProjectSites />}
        />

        {/* Site details */}
        <Route
          path={"/dashboard/systemadmin/projectsite/:sitename"}
          element={<ProjectSiteDashboard />}
        />

        {/* Meters */}
        <Route path={"/allmeters"} element={<Meters />} />

        {/* Meter Class */}
        <Route path={"/meterclass"} element={<MeterClass />} />
        <Route path={"/addmeterclass"} element={<AddMeterClass />} />
        <Route path={"/editmeterclass"} element={<AddMeterClass />} />
        {/* Sales agent */}
        <Route
          path="/dashboard/salesagent/:id"
          element={<SalesAgentDashboard />}
        />
        {/* Project Manager */}
        <Route
          path="/dashboard/projectmanager"
          element={<ProjectManagerDashboard />}
        />
        <Route path="/salesagent/customer" element={<SalesAgentCustomer />} />
        <Route path="/revenue" element={<SalesAnalytics />} />

        <Route
          path="/salesagent/customer/meter/:meterid/:customer"
          element={<CustomerMeterRecharge />}
        />
        {/* Customer Representative */}
        {/* <Route
          path={"/dashboard/customerrepresentative/:site"}
          element={<CustomerManagerDashboard />}
        /> */}
        {/* Customer manager */}
        <Route
          path={"/dashboard/customermanager/:id"}
          element={<CustomerDashboard />}
        />
        <Route path="/manage-meter" element={<ManagerMeter />} />
        {/* system admin */}
        <Route path="/dashboard/systemadmin" element={<SysAdminDashboard />} />
        <Route
          path={`/dashboard/system-admin/roles`}
          element={<SystemAdminList />}
        />
        <Route
          path={"/dashboard/projectsiteinfo/:projectsite/:username"}
          element={<ProjectManagerDetails />}
        />

        {/* Records */}
        <Route
          path={"/dashboard/records/energy/:projectsite/:id"}
          element={<SiteEnergyRecords />}
        />
        <Route
          path={"/dashboard/records/revenue/:projectsite/:id"}
          element={<SiteRevenueRecords />}
        />
        <Route
          path={"/dashboard/records/users/:projectsite/:id"}
          element={<SiteUsersRecords />}
        />
        <Route
          path={"/dashboard/records/meters/:projectsite/:id"}
          element={<SiteMeterRecords />}
        />
        <Route path={"/dashboard/customers"} element={<Customers />} />
        <Route path={"/users"} element={<Users />} />
        {/* user roles */}
        <Route path="userroles" element={<UserRoles />} />

        <Route path="/roles/alluserroles" element={<AllRoles />} />
        {/* Settings */}
        <Route path="/settings/profile" element={<Settings />} />
        <Route path="settings/controls" element={<ControlSettings />} />
        <Route path={"/settings/dropdown"} element={<DropDownSettings />} />
        <Route path="notification" element={<Notification />} />
        <Route path="request" element={<IncomingRequest />} />
        <Route path="wallet" element={<Wallet />} />
        {/* </Route> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Toaster />
    </MQTTProvider>
  );
}

export default App;
const ProtectedRoute = () => {
  const [userToken, setUserToken] = useState("");
  const [loading, setloading] = useState(true);
  const authState = useSelector((state) => state.authReducer);
  let navigate = useNavigate();
  useEffect(() => {
    (async () => {
      try {
        let token = await getToken("spiral_token");
        if (!token) {
          return;
        }
        setUserToken(token);

        // if (!token) {
        //   setUserToken(false);
        //   return;
        // }
        // setUserToken(true);
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, []);

  return userToken ? <Outlet /> : <Navigate to="/" />;
};

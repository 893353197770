import { themeColor as color } from "../../constant/color";
import "./style.css";

const CardTitle = ({ title, caption }) => {
  return (
    <div className="card-cover w-100">
      <div className="font-bold">{title}</div>
      <div
        className="card-caption"
        style={{
          color: "#7c7c7c",
        }}
      >
        {caption}
      </div>
    </div>
  );
};

export default CardTitle;

import {
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Group,
  Inject,
  Page,
  Search,
  Toolbar,
  ExcelExport,
  Sort,
  Freeze,
} from "@syncfusion/ej2-react-grids";
import moment from "moment";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Button } from "../../../Button";
// import { color } from "../../../../constant/color";
import { sampleData } from "./data";
// import MeterInfoModal from "../../MeterInfoModal";
import { toast } from "react-hot-toast";
import axios from "../../../../utils/axios";
import { meterController } from "../../../../controllers/MeterController";
import SetLoadPolicy from "../Modals/SetLoadPolicy";
import { themeColor as color } from "../../../../constant/color";
import MeterInfoModal from "../../modals/MeterInfoModal";
import { FcCalendar } from "react-icons/fc";
import DataTable, { TableColumn } from "react-data-table-component";

const AllMeterTable = ({ data }) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectMeter, setselectMeter] = useState(null);
  const [selectedLoading, setselectedLoading] = useState(null);
  const [loading, setloading] = useState(null);
  const [loadType, setloadType] = useState(null);
  const [showSet, setshowSet] = useState(false);
  let grid = useRef();
  let num = 1;
  let final = data.map((i) => {
    return {
      num: num++,
      ...i,
    };
  });
  const [selectedId, setSelectedId] = useState(null);
  const [modal, setModal] = useState(false);
  const handleLoad = async (type, number) => {
    try {
      if (type == "set") {
        return setshowSet(true);
      } else if (type == "get") {
        let res = await axios.post("/meter/getpermeterloadcontrolpolicy", {
          meternumber: number,
        });
        let result = meterController(res);
        if (result.type !== "success") {
          toast[result.type](result.message);

          setloadType(null);
          setselectMeter(null);
          return;
        }
        console.log(result.message, "/get");
        setloadType(null);
        setselectMeter(null);
        setloading(false);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const Sample = (item) => {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <div
          className="d-flex mx-2 align-items-center justify-content-center rounded-1 text-decoration-none"
          style={{
            border: `1px solid ${color.baseColor}`,
            width: "80px",
          }}
        >
          <div
            style={{
              position: "relative",
              cursor: "pointer",
            }}
            className="text-center "
            onClick={() => {
              setModal(true);
            }}
          >
            <Button
              border={`1px solid ${color.baseColor}`}
              text={"View Info"}
              color={color.baseColor}
              fontSize={"11px"}
              fontWeight={"400"}
              height={"30px"}
              bg={color.whiteColor}
            />
          </div>
        </div>
        <Link
          to="/dashboard/systemadmin/meters/:id"
          className=" d-flex align-items-center justify-content-center rounded-1 text-decoration-none"
          style={{
            border: `1px solid ${color.baseColor}`,
            width: "80px",
          }}
        >
          <div
            style={{
              position: "relative",
              cursor: "pointer",
            }}
            className="text-center "
            onClick={() => {
              setModal(true);
            }}
          >
            <Button
              border={`1px solid ${color.baseColor}`}
              text={"View settings"}
              color={color.baseColor}
              fontSize={"11px"}
              fontWeight={"400"}
              height={"30px"}
              bg={color.whiteColor}
            />
          </div>
        </Link>
      </div>
    );
  };

  const columns = [
    {
      name: "Number",
      center: true,
      selector: (row) => row.number,
      grow: 1,
    },
    {
      name: "Meter type",
      center: true,
      selector: (row) => row.metertype,
      grow: 1,
    },
    {
      name: "phases",
      center: true,
      selector: (row) => row.phases,
      grow: 1,
    },
    {
      name: "registrar",
      center: true,
      selector: (row) => row.registrar,
      grow: 1,
    },
    {
      name: "assignedto",
      center: true,
      selector: (row) => row.assignedto,
      grow: 1,
    },
    {
      name: "powerstatus",
      center: true,
      selector: (row) => row.powerstatus,
      grow: 1,
    },
    {
      name: "loadlimit",
      center: true,
      selector: (row) => row.loadlimit,
      grow: 1,
    },
    {
      name: "powerstatus",
      center: true,
      selector: (row) => row.powerstatus,
      grow: 1,
    },
    {
      name: "powerstatus",
      center: true,
      selector: (row) => row.powerstatus,
      grow: 1,
    },

    {
      name: "Date registered",
      center: true,
      selector: (row) => row.dateregistered,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="px-1">
              <FcCalendar size={18} />
            </div>
            <div className="text-[11px]">
              {moment(item.dateregistered).format("lll")}
            </div>
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Date registered",
      center: true,
      selector: (row) => row.dateregistered,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="px-1">
              <FcCalendar size={18} />
            </div>
            <div className="text-[11px]">
              {moment(item.dateregistered).format("lll")}
            </div>
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Date registered",
      center: true,
      selector: (row) => row.datedecommissioned,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="px-1">
              <FcCalendar size={18} />
            </div>
            <div className="text-[11px]">
              {moment(item.datedecommissioned).format("lll")}
            </div>
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => row.siteid,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex relative rounded-md items-center justify-between">
            <div className="border-[1px] hidden cursor-pointer ml-2 rounded border-red-400 text-[12px] text-red-400 font-light px-4 py-2">
              Delete
            </div>
            <div
              onClick={() => {
                setloadType("get");
                setselectMeter(item?.number);
                handleLoad("get", item?.number);
              }}
              className="border-[1px] cursor-pointer ml-2 rounded border-green-600 text-[12px] text-green-600 font-light px-4 py-2"
            >
              Get load policy
            </div>
            <div
              onClick={() => {
                setselectMeter(item?.number);
                setshowSet(true);
              }}
              // to={`/dashboard/systemadmin/projectsite/${item.sitename}`}
              className="border-[1px] cursor-pointer ml-2 rounded border-gray-400 text-[12px] font-light px-4 py-2"
            >
              Set load policy
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div
      style={{
        borderTop: "1px solid #eee",
      }}
    >
      {modal && (
        <MeterInfoModal closeModal={setModal} meterNumber={selectedId} />
      )}

      {showSet && (
        <SetLoadPolicy meterId={selectMeter} closeModal={setshowSet} />
      )}
      <DataTable
        style={{
          height: "100%",
        }}
        columns={columns}
        data={data}
        pagination
        progressPending={loading}
        paginationPerPage={10}
      />
    </div>
  );
};

export default AllMeterTable;

{
  /* <div className="table-container" style={{}}>
        <GridComponent
          ref={(g) => (grid.current = g)}
          id="grid"
          style={{
            display: "block",
          }}
          allowPaging={true}
          allowSorting={true}
          dataSource={data}
          pageSettings={{
            pageSize: 15,
          }}
          // frozenColumns={2}
          allowExcelExport={false}
          toolbar={["Search"]}
          width={"100%"}
          height={"500px"}
          // searchSettings={searchOptions}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="number"
              width="150"
              textAlign="Center"
              headerText="Meter Number"
              headerTextAlign="Center"
              headerTemplate={() => {
                return (
                  <div
                    className="st-header"
                    style={{
                      color: color.darkColor,
                      fontSize: "12px",
                    }}
                  >
                    Meter Number
                  </div>
                );
              }}
              template={(item) => {
                return (
                  <div
                    style={{
                      color: color.baseColor,
                      fontSize: "13px",
                    }}
                    className="text-center"
                  >
                    {!item?.number ? "nill" : item?.number}
                  </div>
                );
              }}
            />

            <ColumnDirective
              field="metertype"
              width="150"
              textAlign="Center"
              headerText="Meter type"
              headerTextAlign="Center"
              headerTemplate={() => {
                return (
                  <div
                    className="st-header"
                    style={{
                      color: color.darkColor,
                      fontSize: "12px",
                    }}
                  >
                    Meter type
                  </div>
                );
              }}
              template={(item) => {
                return (
                  <div
                    style={{
                      color: color.darkColor,
                      fontSize: "13px",
                    }}
                    className="text-center"
                  >
                    {!item?.type ? "null" : item?.type}
                  </div>
                );
              }}
            />
            <ColumnDirective
              field="phases"
              width="100"
              textAlign="Center"
              headerText="Meter Phases"
              headerTextAlign="Center"
              headerTemplate={() => {
                return (
                  <div
                    className="st-header"
                    style={{
                      color: color.darkColor,
                      fontSize: "12px",
                    }}
                  >
                    Meter phases
                  </div>
                );
              }}
              template={(item) => {
                return (
                  <div
                    style={{
                      color: color.darkColor,
                      fontSize: "13px",
                    }}
                    className="text-center"
                  >
                    {!item?.phases ? "nill" : item?.phases}
                  </div>
                );
              }}
            />
            <ColumnDirective
              field="registrar"
              width="100"
              textAlign="Center"
              headerText="Registrar"
              headerTextAlign="Center"
              headerTemplate={() => {
                return (
                  <div
                    className="st-header"
                    style={{
                      color: color.darkColor,
                      fontSize: "12px",
                    }}
                  >
                    Registrar
                  </div>
                );
              }}
              template={(item) => {
                return (
                  <div
                    style={{
                      color: color.darkColor,
                      fontSize: "13px",
                    }}
                    className="text-center"
                  >
                    {!item?.registrar ? "null" : item?.registrar}
                  </div>
                );
              }}
            />
            <ColumnDirective
              field="assignedto"
              width="100"
              textAlign="Center"
              headerText="Assigned to"
              headerTextAlign="Center"
              headerTemplate={() => {
                return (
                  <div
                    className="st-header"
                    style={{
                      color: color.darkColor,
                      fontSize: "12px",
                    }}
                  >
                    Assigned to
                  </div>
                );
              }}
              template={(item) => {
                return (
                  <div
                    style={{
                      color: color.darkColor,
                      fontSize: "13px",
                    }}
                    className="text-center"
                  >
                    {item?.assignedto === "-"
                      ? "Not assigned"
                      : item?.assignedto}
                  </div>
                );
              }}
            />
            <ColumnDirective
              field="powerstatus"
              width="100"
              textAlign="Center"
              headerText="Powerstatus"
              headerTextAlign="Center"
              headerTemplate={() => {
                return (
                  <div
                    className="st-header"
                    style={{
                      color: color.darkColor,
                      fontSize: "12px",
                    }}
                  >
                    Power status
                  </div>
                );
              }}
              template={(item) => {
                return (
                  <div
                    style={{
                      color:
                        item?.powerstatus == "OFF"
                          ? color.redColor
                          : color.greenColor,
                      fontSize: "13px",
                    }}
                    className="text-center"
                  >
                    {!item?.powerstatus ? "null" : item?.powerstatus}
                  </div>
                );
              }}
            />
            <ColumnDirective
              field="loadlimit"
              width="100"
              textAlign="Center"
              headerText="loadlimit"
              headerTextAlign="Center"
              headerTemplate={() => {
                return (
                  <div
                    className="st-header"
                    style={{
                      color: color.darkColor,
                      fontSize: "12px",
                    }}
                  >
                    Load limit
                  </div>
                );
              }}
              template={(item) => {
                return (
                  <div
                    style={{
                      color: color.baseColor,
                      fontSize: "13px",
                    }}
                    className="text-center"
                  >
                    {!item?.loadlimit ? "null" : item?.loadlimit}
                  </div>
                );
              }}
            />

            <ColumnDirective
              field="dateregistered"
              width="150"
              textAlign="Center"
              headerText="Date registered"
              headerTextAlign="Center"
              headerTemplate={() => {
                return (
                  <div
                    className="st-header"
                    style={{
                      color: color.darkColor,
                      fontSize: "12px",
                    }}
                  >
                    Date registered
                  </div>
                );
              }}
              template={(item) => {
                return (
                  <div
                    style={{
                      color: color.darkColor,
                      fontSize: "13px",
                    }}
                    className="text-center"
                  >
                    {!item?.dateregistered
                      ? "null"
                      : moment(item?.dateregistered).format(
                          "DD-MM-YYYY HH:mm:ss"
                        )}
                  </div>
                );
              }}
            />
            <ColumnDirective
              field="datedecommissioned"
              width="200"
              textAlign="Center"
              headerText="Date decommissioned"
              headerTextAlign="Center"
              headerTemplate={() => {
                return (
                  <div
                    className="st-header"
                    style={{
                      color: color.darkColor,
                      fontSize: "12px",
                    }}
                  >
                    Date decommissioned
                  </div>
                );
              }}
              template={(item) => {
                return (
                  <div
                    style={{
                      color: color.darkColor,
                      fontSize: "13px",
                    }}
                    className="text-center"
                  >
                    {!item?.datedecommissioned
                      ? "null"
                      : moment(item?.datedecommissioned).format(
                          "DD-MM-YYYY HH:mm:ss"
                        )}
                  </div>
                );
              }}
            />
            <ColumnDirective
              field="number"
              width="300"
              textAlign="Center"
              headerText="Date decommissioned"
              headerTextAlign="Center"
              headerTemplate={() => {
                return (
                  <div
                    className="st-header"
                    style={{
                      color: color.darkColor,
                      fontSize: "12px",
                    }}
                  >
                    Load Control Policy
                  </div>
                );
              }}
              template={(item) => {
                return (
                  <div className="container-fluid">
                    <div className="row d-flex align-items-center justify-content-center">
                      <div className=" col-6 ">
                        <div
                          style={{
                            color: color.darkColor,
                            fontSize: "13px",
                          }}
                          className="text-center"
                          onClick={() => {
                            setselectMeter(item?.number);
                            setshowSet(true);
                          }}
                        >
                          <Button
                            text={"Set load policy"}
                            color={color.whiteColor}
                            bg={color.baseColor}
                            height={35}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div
                          style={{
                            color: color.darkColor,
                            fontSize: "13px",
                            border: `1px solid ${color.baseColor}`,
                          }}
                          className="text-center rounded"
                          onClick={() => {
                            setloadType("get");
                            setselectMeter(item?.number);
                            handleLoad("get", item?.number);
                          }}
                        >
                          <Button
                            text={"Get load policy"}
                            color={color.baseColor}
                            bg={color.whiteColor}
                            loading={
                              selectMeter == item?.number && loadType == "get"
                                ? true
                                : false
                            }
                            height={35}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }}
            />
            {/* <ColumnDirective
              field="number"
              width="150"
              textAlign="Center"
              headerText="Action"
              headerTextAlign="Center"
              headerTemplate={() => {
                return (
                  <div
                    className="st-header"
                    style={{
                      color: color.darkColor,
                      fontSize: "12px",
                    }}
                  >
                    Action
                  </div>
                );
              }}
              template={(item) => {
                return (
                  <div
                    style={{
                      color: color.darkColor,
                      fontSize: "13px",
                    }}
                    className="text-center"
                  >
                    <Button
                      text={"Assign Meter"}
                      color={color.whiteColor}
                      bg={color.baseColor}
                      height={38}
                    />
                  </div>
                );
              }}
            /> */
}
//   </ColumnsDirective>
//   <Inject
//     services={[
//       Page,
//       Sort,
//       Filter,
//       Group,
//       Toolbar,
//       Search,
//       ExcelExport,
//       Freeze,
//     ]}
//   />
// </GridComponent>
// </div>

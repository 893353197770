import { Button } from "../../../components/Button";
import CardTitle from "../../../components/CardTitle";
import Layout from "../../../components/Layout";
import TableTop from "../../../components/TableTop";
import TopNav from "../../../components/TopNav";

import "./styles.css";
import { Select } from "antd";
import { useEffect, useState } from "react";
import axios from "../../../utils/axios";
import toast from "react-hot-toast";
import { controlfields } from "./controlfields";
// import ViewModal from "../../../components/ViewModal";
import SetMinimumVending from "../../../components/Settings/SetMinimumVending";
import SetAgentDiscount from "../../../components/Settings/SetAgentDiscount";
import SetLoadControl from "../../../components/Settings/SetLoadControl";
import SetPowerControl from "../../../components/Settings/SetPowerControl";
import ViewModal from "../../../components/Settings/Modals/ViewModal";
import { themeColor } from "../../../constant/color";

const { Option } = Select;

const ControlSettings = () => {
  const [fieldZone, setFieldZone] = useState({
    vending: null,
    discount: null,
    load: null,
    power: null,
  });
  const [fieldMeter, setFieldMeter] = useState({
    load: null,
    power: null,
  });
  const [loading, setLoading] = useState({
    vending: false,
    discount: false,
    load: false,
    power: false,
  });
  const [projectSites, setProjectSites] = useState([]);
  const [meterTypes, setMeterTypes] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMeta, setModalMeta] = useState("");
  const [modalImg, setModalImg] = useState("");
  const [modalValue, setModalValue] = useState("");

  const [showSetMinVend, setShowSetMinVend] = useState(false);
  const [showSetLoadControl, setShowSetLoadControl] = useState(false);
  const [showSetPowerControl, setShowSetPowerControl] = useState(false);
  const [showSetAgentDiscount, setShowSetAgentDiscount] = useState(false);

  const showValue = (field) => {
    let controlfield = controlfields[field];
    if (!fieldZone[field]) return toast.error("Please select a zone");
    if (controlfield.withMeter && !fieldMeter[field])
      return toast("Please select a meter type");
    setLoading((curr) => {
      let newLoading = { ...curr };
      newLoading[field] = true;
      return newLoading;
    });
    let reqBody = { zone: fieldZone[field] };
    if (controlfield.withMeter) reqBody.metertype = fieldMeter[field];
    axios
      .post(controlfield.url, reqBody)
      .then((response) => {
        // console.log(response);
        setModalTitle(controlfield.title);
        setModalMeta(controlfield.meta);
        setModalImg(controlfield.img);
        setModalValue(controlfield.format(response.data));
        setShowModal(true);
        setLoading((curr) => {
          let newLoading = { ...curr };
          newLoading[field] = false;
          return newLoading;
        });
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading((curr) => {
          let newLoading = { ...curr };
          newLoading[field] = false;
          return newLoading;
        });
      });
  };

  useEffect(() => {
    axios
      .post("/sitemanagement/getallsitenames", {})
      .then((response) => {
        // console.log(response);
        setProjectSites(response.data.body);
      })
      .catch((err) => {
        toast(err.message);
      });
    axios
      .post("/meter/getuniquemetertypes", {})
      .then((response) => {
        // console.log(response);
        setMeterTypes(response.data.body);
      })
      .catch((err) => {
        toast(err.message);
      });
  }, []);

  return (
    <Layout>
      {showModal && (
        <ViewModal
          title={modalTitle}
          meta={modalMeta}
          img={modalImg}
          value={modalValue}
          closeModal={setShowModal}
        />
      )}
      {showSetMinVend && (
        <SetMinimumVending
          zones={projectSites}
          closeModal={setShowSetMinVend}
        />
      )}
      {showSetAgentDiscount && (
        <SetAgentDiscount
          zones={projectSites}
          closeModal={setShowSetAgentDiscount}
        />
      )}
      {showSetLoadControl && (
        <SetLoadControl
          zones={projectSites}
          meterTypes={meterTypes}
          closeModal={setShowSetLoadControl}
        />
      )}
      {showSetPowerControl && (
        <SetPowerControl
          zones={projectSites}
          meterTypes={meterTypes}
          closeModal={setShowSetPowerControl}
        />
      )}
      <div className="flex items-center justify-between bg-white rounded-md p-2 shadow-md">
        <div className="flex items-center ">
          <div className=" w-[15px] h-[25px] bg-[#e0c5ff] rounded-sm"></div>
          <div className="font-semibold ml-2">Control Project site.</div>
        </div>
        {/* <div className="flex items-center w-[170px] justify-end">
          <Button
            text={"Add project site."}
            bg={themeColor.secondary}
            onClick={() => setAddSite(true)}
          />
        </div> */}
      </div>
      <div className=" bg-white mt-2 p-4 rounded-md shadow-md">
        {/* /vending */}
        <div className="grid grid-cols-2 gap-4">
          <div className="">
            <div className="font-bold">Vending Control</div>
            <div className="control-caption">
              Select zone from the options dropdown to get vending value of any
              project site
            </div>
          </div>

          <div className="flex flex-col items-end justify-end">
            <Select
              style={{ width: "100%", fontSize: "1rem" }}
              allowClear
              size="large"
              placeholder="Select project site"
              value={fieldZone.vending}
              onChange={(val) =>
                setFieldZone((curr) => {
                  return { ...curr, vending: val };
                })
              }
            >
              {projectSites.map((site) => (
                <Option value={site} key={site}>
                  {site}
                </Option>
              ))}
            </Select>
            <div className="flex items-center mt-2 justify-end w-[200px]">
              <Button
                onClick={() => showValue("vending")}
                text={"Get Value"}
                bg={themeColor.white}
                color={themeColor.secondary}
                height="20px"
                border={`1px solid ${themeColor.secondary}`}
                status={loading.vending}
              />
            </div>
          </div>
        </div>
        <div
          className="flex items-center justify-between mt-5 pb-4"
          style={{
            borderBottom: "1px solid #CACACA",
          }}
        >
          <div className="">
            <div className="font-bold">Vend New site</div>
            <div className="control-caption">
              Select this option to vend a new project site.
            </div>
          </div>
          <div className="w-[200px]">
            <Button
              text={"Set New Minimum"}
              bg={themeColor.secondary}
              color={themeColor.white}
              height={"40px"}
              onClick={() => setShowSetMinVend(true)}
            />
          </div>
        </div>
        {/*Agent Discount */}
        <div className="grid grid-cols-2 gap-4 mt-4 ">
          <div className="">
            <div className="font-bold">Agent Discount</div>
            <div className="control-caption">
              Select zone front the options dropdown to get the discount issued
              to a project site .
            </div>
          </div>
          <div className="flex flex-col items-end justify-end">
            <Select
              style={{ width: "100%", fontSize: "1rem" }}
              allowClear
              size="large"
              placeholder="Select project site"
              value={fieldZone.discount}
              onChange={(val) =>
                setFieldZone((curr) => {
                  return { ...curr, discount: val };
                })
              }
            >
              {projectSites.map((site) => (
                <Option value={site} key={site}>
                  {site}
                </Option>
              ))}
            </Select>
            <div className="flex items-center mt-2 justify-end w-[200px]">
              <Button
                bg={themeColor.white}
                color={themeColor.secondary}
                height="20px"
                text={"Get value"}
                border={`1px solid ${themeColor.secondary}`}
                onClick={() => showValue("discount")}
                status={loading.discount}
              />
            </div>
          </div>
        </div>
        <div
          className="flex items-center justify-between mt-5 pb-4"
          style={{
            borderBottom: "1px solid #CACACA",
          }}
        >
          <div className="">
            <div className="font-bold">Issue Discount </div>
            <div className="control-caption">
              Select zone front the options dropdown to issue discount to agent
              in a project site
            </div>
          </div>
          <div className="">
            <div>
              {/* <div className="control-select"></div> */}
              <div className="w-[200px]">
                <Button
                  text={"Set discount"}
                  height={"40px"}
                  bg={themeColor.secondary}
                  color={themeColor.white}
                  onClick={() => setShowSetAgentDiscount(true)}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Load control */}
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div className="">
            <div className="font-bold">Load Control</div>
            <div className="control-caption">
              Request for the load control of a meter type in a project site
            </div>
          </div>
          <div className="flex flex-col items-end justify-end">
            <Select
              style={{ width: "100%", fontSize: "1rem" }}
              allowClear
              size="large"
              placeholder="Select project site"
              value={fieldZone.load}
              onChange={(val) =>
                setFieldZone((curr) => {
                  return { ...curr, load: val };
                })
              }
            >
              {projectSites.map((site) => (
                <Option value={site} key={site}>
                  {site}
                </Option>
              ))}
            </Select>
            <Select
              style={{ width: "100%", marginTop: 6, fontSize: "1rem" }}
              allowClear
              size="large"
              className="mt-4"
              placeholder="Select meter type"
              value={fieldMeter.load}
              onChange={(val) =>
                setFieldMeter((curr) => {
                  return { ...curr, load: val };
                })
              }
            >
              {meterTypes.map((type) => (
                <Option value={type} key={type}>
                  {type}
                </Option>
              ))}
            </Select>
            <div className="w-[200px] mt-4">
              <Button
                text={"Get Value"}
                onClick={() => showValue("load")}
                bg={themeColor.white}
                color={themeColor.secondary}
                height="20px"
                border={`1px solid ${themeColor.secondary}`}
                status={loading.load}
              />
            </div>
          </div>
        </div>
        <div
          className="flex items-center justify-between mt-5 pb-4"
          style={{
            borderBottom: "1px solid #CACACA",
          }}
        >
          <div className="">
            <div className="font-bold">Assign load control to meter</div>
            <div className="control-caption">
              Use this option to set load on control on a specific meter type.
            </div>
          </div>
          <div className="">
            <div>
              {/* <div className="control-select"></div> */}
              <div className="w-[200px]">
                <Button
                  text={"Set Load control"}
                  bg={themeColor.secondary}
                  color={themeColor.white}
                  height={"40px"}
                  onClick={() => setShowSetLoadControl(true)}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Power control */}
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div className="">
            <div className="font-bold">Power Control</div>
            <div className="control-caption">
              Request for the power control of a meter type in a project site.
            </div>
          </div>
          <div className="flex flex-col items-end justify-end">
            <Select
              style={{ width: "100%", fontSize: "1rem" }}
              allowClear
              size="large"
              placeholder="Select project site"
              value={fieldZone.power}
              onChange={(val) =>
                setFieldZone((curr) => {
                  return { ...curr, power: val };
                })
              }
            >
              {projectSites.map((site) => (
                <Option value={site} key={site}>
                  {site}
                </Option>
              ))}
            </Select>
            <Select
              style={{ width: "100%", marginTop: 6, fontSize: "1rem" }}
              allowClear
              className="mt-4"
              size="large"
              placeholder="Select meter type"
              value={fieldMeter.power}
              onChange={(val) =>
                setFieldMeter((curr) => {
                  return { ...curr, power: val };
                })
              }
            >
              {meterTypes.map((type) => (
                <Option value={type} key={type}>
                  {type}
                </Option>
              ))}
            </Select>
            <div className="w-[200px]">
              <Button
                text={"Get Value"}
                onClick={() => showValue("power")}
                height="20px"
                bg={themeColor.white}
                color={themeColor.secondary}
                border={`1px solid ${themeColor.secondary}`}
                status={loading.power}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between mt-5 pb-4">
          <div className="">
            <div className="font-bold">
              Assign power control to meters in a project site.
            </div>
            <div className="control-caption">
              Use this option to set load on control on a specific meter type.
            </div>
          </div>
          <div className="">
            <div>
              {/* <div className="control-select"></div> */}
              <div className="w-[200px]">
                <Button
                  text={"Set Power control"}
                  height={"40px"}
                  bg={themeColor.secondary}
                  color={themeColor.white}
                  onClick={() => setShowSetPowerControl(true)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ControlSettings;

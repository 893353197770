import axios from "../../../utils/axios";
import moment from "moment";
import { useState, useEffect } from "react";
import CustomerCardMeter from "../../../components/Customer/Cards/CustomerCardMeter";
import DateRangePicker from "../../../components/DateRangePicker";
import Layout from "../../../components/Layout";
import PageTitle from "../../../components/PageTitle";
import AllMetersTab from "../../../components/ProjectMeter/Tab/AllMetersTab";
import Rule from "../../../components/Rule/Index";
import { channelController } from "../../../controllers/channelController";
import { toast } from "react-hot-toast";
import ViewProjectMeter from "../../../components/ProjectMeter/Modals/ViewProjectMeter";
import TableSkimmer from "../../../components/TableSkimmer";
import { useParams } from "react-router-dom";
const CustomerDashboard = () => {
  const [tabstate, settabstate] = useState(1);
  const [count, setCount] = useState([]);
  const [startDate, setStartDate] = useState(
    moment(Date.now()).subtract("1", "year").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );
  const [loading, setloading] = useState(false);
  const [modal, setmodal] = useState(false);
  const [details, setdetails] = useState(null);
  const [decommissionedModal, setDecommissionModal] = useState(false);
  const [commissionedModal, setCommissionModal] = useState(false);
  const [registeredModal, setRegisteredModal] = useState(false);
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const controller = new AbortController();
  const [addMeterModal, setAddMeterModal] = useState(false);
  const [userRole, setUserRole] = useState("");
  const { id } = useParams();
  // get all meters
  useEffect(() => {
    (async () => {
      try {
        setloading(true);
        let res = await axios.post("/meter/getallmeters", {
          limit: 30,
          offset: 0,
          startdate: startDate,
          enddate: endDate,
        });
        let result = channelController(res);
        if (result.type !== "success") {
          toast[result.type](result.message);
          setloading(false);
          return;
        }
        setData(result.message.body);
        setloading(false);
      } catch (err) {
        toast.error(err.message);
        setloading(false);
      }
    })();

    return () => {
      controller.abort();
    };
  }, []);
  // meters in service, registered amd decommissioned
  useEffect(() => {
    Promise.all([
      axios.post("/meter/countalldecommisionedmeters", {
        startdate: startDate,
        enddate: endDate,
      }),
      axios.post("/meter/countallmetersinservice", {
        startdate: startDate,
        enddate: endDate,
      }),
      axios.post("/meter/countallregisteredmeters", {
        startdate: startDate,
        enddate: endDate,
      }),
    ])
      .then((res) => {
        let response = res.map((item) => channelController(item));
        setCount(response);
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      controller.abort();
    };
  }, [refresh]);

  return (
    <Layout>
      {modal && (
        <ViewProjectMeter open={modal} setopen={setmodal} details={details} />
      )}

      <div className="w-full bg-white rounded-md drop-shadow-md px-2 mb-10">
        <div className="my-2 flex items-center justify-between px-4">
          <div className="">
            <PageTitle title={`All meters created in ${id}`} />
          </div>
          <div className="">
            <DateRangePicker
              setEndDate={setEndDate}
              setStartDate={setStartDate}
              refreshBtn={setRefresh}
              desc
            />
          </div>
        </div>
        <div className="border rounded-md py-3">
          <div className="grid grid-cols-3 gap-10 mx-auto w-[90%] p-3">
            <CustomerCardMeter
              title={"Decommissioned Meter"}
              caption={"Count of all decommissioned meter."}
              value={!count.length ? "0" : count[0].message.count}
            />
            <CustomerCardMeter
              title={"Active Meters"}
              caption={"Count of all active meter."}
              value={!count.length ? "0" : count[1].message.count}
            />
            <CustomerCardMeter
              title={"Registered Meters."}
              caption={"Count of all active meter."}
              value={!count.length ? "0" : count[2].message.count}
            />
          </div>
        </div>

        <div className="flex items-center mt-6">
          <div
            className={
              tabstate == 1
                ? "px-8 py-1 border-b-[2px] border-gray-800 mx-2 cursor-pointer"
                : "px-8 py-1 border-b-[2px] border-gray-300 mx-2 cursor-pointer"
            }
            onClick={() => settabstate(1)}
          >
            All Meters
          </div>
        </div>
        <Rule />
        <div className="p-3">
          {tabstate == 1 &&
            (loading ? (
              <TableSkimmer entries={12} />
            ) : (
              <AllMetersTab
                data={data}
                setdetails={setdetails}
                setopen={setmodal}
                open={modal}
              />
            ))}
        </div>
      </div>
    </Layout>
  );
};

export default CustomerDashboard;

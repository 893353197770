import moment from "moment";
import { FcCalendar } from "react-icons/fc";
import { Link } from "react-router-dom";
import { HiUserGroup } from "react-icons/hi";
import { useState } from "react";
import { Select } from "antd";
import EmptyState from "../../../EmptyState";
import { VscCopy } from "react-icons/vsc";

const AllCustomerTable = ({ data = [], setdetails, setmodal }) => {
  return !data.length ? (
    <EmptyState>
      <div className="h-[70vh] w-full">
        <div className="flex items-center justify-center mt-[200px]">
          <HiUserGroup size={30} />
        </div>
        <div className="font-normal text-[15px] text-center">
          Please use the Advanced filter option at the top to search for a user.
        </div>
      </div>
    </EmptyState>
  ) : (
    <div>
      <table className="w-full border-collapse">
        <thead className="h-[50px] drop-shadow-md border-b-[0.5px] border-gray-800">
          <th className="font-semibold text-gray-800">S/N</th>
          <th className="font-semibold text-gray-800">Customer name</th>
          <th className="font-semibold text-gray-800">Zone</th>
          <th className="font-semibold text-gray-800">Meter No.</th>
          <th className="font-semibold text-gray-800">Customer Type</th>
          <th className="font-semibold text-gray-800">Date added</th>
          {/* <th className="font-semibold text-gray-800">Time range</th> */}
          <th className="font-semibold text-gray-800">Action</th>
        </thead>
        <tbody>
          {data.map((i, index) => (
            <tr
              className="bg-white hover:bg-gray-50 cursor-pointer h-[50px] even:bg-gray-50"
              key={i?.roleid}
            >
              <td className="text-center">{index + 1}</td>
              <td className="text-center">{i?.customerid}</td>
              <td className="text-center ">{i.zone}</td>
              <td className="rounded-lg text-center">
                <div className="flex items-center justify-center">
                  <div className="text-secondary">{i?.meter}</div>
                  <div className="">
                    <VscCopy />
                  </div>
                </div>
              </td>
              <td className="rounded-lg text-center">{i?.customertype}</td>
              <td className="text-center">
                <div className="flex items-center justify-center">
                  <div className="pl-2">
                    {moment(i?.datecreated).format("lll")}
                  </div>
                </div>{" "}
              </td>

              <td className="text-center flex justify-center">
                <div
                  onClick={() => {
                    setdetails(i);
                    setmodal(true);
                  }}
                  className="border-[1px] rounded border-orange-600 text-[12px] text-orange-600 font-light px-4 py-2"
                >
                  Details
                </div>
                {/* <Link
                  to={`/salesagent/customer/meter/${i.meter}/${i.customerid}`}
                  className="ml-2 border-[1px] rounded border-secondary text-[12px] text-secondary font-light px-4 py-2"
                >
                  View Recharge history
                </Link> */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllCustomerTable;

import {
  AiFillEye,
  AiOutlineEye,
  AiOutlineKey,
  AiOutlinePhone,
  AiOutlineUser,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import { Button } from "../../../components/Button";
import { useEffect, useState } from "react";
import { Link, redirect, useNavigate } from "react-router-dom";
import axios from "axios";
import { setToken } from "../../../utils/token";
import toast, { Toaster } from "react-hot-toast";
import AuthBg from "../../../assets/png/1162369_ORH93N1345678.png";
import BGImg from "../../../assets/png/v300batch2-nunoon-13.jpg";

const CreateAccount = () => {
  const [resetpassword, setresetpassword] = useState(true);
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [password, setpassword] = useState("");
  const [username, setusername] = useState("");
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const [eye, seteye] = useState(false);
  let domain = "IKJ";

  const handleSignup = async () => {
    try {
      console.log(phone, email, username, password);
      if (!(email && password && phone && username))
        return toast.error("All values are required. Please try again.");
      setloading(true);
      let res = await axios.post(
        "https://api.app.spiral.systems/authselfhelp/createaccount",
        {
          callerid: username,
          username,
          defaultpassword: password,
          phone,
          email,
          subdomain: "IKJ",
        }
      );
      let { data } = res;
      if (data.response == "userexists") {
        toast.error(data.response);
        setloading(false);
        return;
      } else if (data.response == "success") {
        toast.success("A code has been sent you email address");
        await setToken("spiral_username", email);
        navigate(`/emailverification?email=${email}&username=${username}`);
        setloading(false);
        return;
      } else if (data.response == "failure") {
        toast.error(data.response);
        setloading(false);
        return;
      }
    } catch (err) {
      toast.error(err.message);
      if (err.response) {
        if (err.reponse.data.response == "userexist") {
          toast.error("User already exist. Please again.");
          setloading(false);
          return;
        }
        toast.error(err.response?.data.response);
        setloading(false);
        return;
      }
      toast.error(err.message);
      return setloading(false);
    }
  };

  return (
    <div className="h-screen  bg-gray-50 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 place-items-center place-content-center gap-10">
      <div
        className="h-screen absolute top-0 left-0 w-screen"
        style={{
          background: `url(${BGImg})`,
          backgroundPosition: "center",
          backgroundRepeat: "center",
          backgroundSize: "cover",
          opacity: 0.3,
          backgroundAttachment: "fixed",
        }}
      ></div>

      <div className="w-full z-50 bg-white rounded-md shadow-md md:w-[500px] mx-auto">
        <div className="text-center text-primary font-semibold text-2xl">
          Spiral Energy
        </div>
        <div className=" w-[full] h-auto rounded-md p-6">
          <div className="font-light text-center text-[16px] text-gray-600">
            Fill in the fields below to create an account.
          </div>
          <hr className="my-4" />
          <div className="">
            {/* Firstname */}
            <div className="">
              <label className="text-primary font-normal text-sm">
                Username
              </label>
              <div className="flex bg-white items-center w-100 border-[1px] rounded-sm border-gray-300 mt-2 h-[55px]">
                <div className="d-inline flex items-center justify-center px-2">
                  <AiOutlineUser
                    size={20}
                    color="text-primary"
                    className="text-primary"
                  />
                </div>
                <div className="ml-2">
                  <input
                    value={username}
                    onChange={(e) => setusername(e.target.value)}
                    type={"text"}
                    placeholder={"Enter your username"}
                    className=" placeholder:text-sm placeholder:font-thin outline-none border-[0px]"
                  />
                </div>
              </div>
            </div>
            {/* Lastname */}
            <div className="mt-4">
              <label className="text-primary font-normal text-sm">Email</label>
              <div className="flex bg-white items-center w-100 border-[1px] rounded-sm border-gray-300 mt-2 h-[55px]">
                <div className="d-inline flex items-center justify-center px-2">
                  <AiOutlineUser
                    size={20}
                    color="text-primary"
                    className="text-primary"
                  />
                </div>
                <div className="ml-2 w-full">
                  <input
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                    type={"email"}
                    placeholder={"Enter your lastname"}
                    className="w-full block placeholder:text-sm placeholder:font-thin outline-none border-[0px]"
                  />
                </div>
              </div>
            </div>
            {/* Phone */}
            <div className="mt-4">
              <label className="text-primary font-normal text-sm">
                Phone number
              </label>
              <div className="flex bg-white items-center w-100 border-[1px] rounded-sm border-gray-300 mt-2 h-[55px] ">
                <div className="d-inline flex items-center justify-center px-2">
                  <AiOutlinePhone
                    size={20}
                    color="text-primary"
                    className="text-primary"
                  />
                </div>
                <div className="ml-2 w-full">
                  <input
                    value={phone}
                    onChange={(e) => setphone(e.target.value)}
                    type={"number"}
                    placeholder={"Enter your phone number"}
                    className="w-full block placeholder:text-sm placeholder:font-thin outline-none border-[0px]"
                  />
                </div>
              </div>
            </div>
            {/* Password */}
            <div className="mt-4">
              <label className="text-primary font-normal text-sm">
                Password
              </label>
              <div className="flex items-center justify-between w-100 border-[1px] rounded-sm bg-white border-gray-300 mt-2 h-[55px]">
                <div className="flex ">
                  <div className="d-inline flex items-center justify-center px-2">
                    <AiOutlineKey
                      size={20}
                      color="text-primary"
                      className="text-primary"
                    />
                  </div>
                  <div className="ml-2 w-full">
                    <input
                      value={password}
                      onChange={(e) => setpassword(e.target.value)}
                      type={eye ? "text" : "password"}
                      placeholder={"Enter your password"}
                      className="w-full block placeholder:text-sm placeholder:font-thin outline-none border-[0px]"
                    />
                  </div>
                </div>

                <div
                  className="px-3 cursor-pointer"
                  onClick={() => seteye(!eye)}
                >
                  {eye ? (
                    <AiOutlineEye
                      size={20}
                      color="text-primary"
                      className="text-primary"
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      size={20}
                      color="text-primary"
                      className="text-primary"
                    />
                  )}
                </div>
              </div>
            </div>
            {/* Button */}
            <div className=" w-100 mt-7">
              <Button
                text={"Create"}
                loading={loading}
                onClick={handleSignup}
              />
            </div>

            <div className="w-100 mt-4">
              <div className="text-primary text-center font-normal py-3">
                {/*  Need help with you account? */}
              </div>
              <Link
                to="/"
                className="underline pl-2 w-100 block cursor-pointer text-sm font-light text-secondary text-center"
              >
                Already have an account?
                <span className="font-semibold text-1xl"> Log in.</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;

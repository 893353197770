import { BsSoundwave } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { measurementCommands, powerData } from "../../data";
import { css } from "@emotion/react";
import { useState, useEffect } from "react";
import { MoonLoader } from "react-spinners";
import { toast } from "react-hot-toast";
import { commandController } from "../../../../../../controllers/CommandController";
import { getToken } from "../../../../../../utils/token";
import axios from "../../../../../../utils/axios";
import { useMQTT } from "../../../../../../context/MqttContext";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const PowerSupplyTab = ({ loading, setloading, closeBtn }) => {
  const { id } = useParams();

  const { mqttEvents, clients } = useMQTT();
  const [getPSSLoading, setgetPSSLoading] = useState(false);
  const [refresh, setrefresh] = useState(false);
  useEffect(() => {
    (async () => {
      try { 
        setgetPSSLoading(true);
        let user = await getToken("spiral_username");
        let res = await axios.post("/commands/getpowersupplystatus", {
          transactiontopic: `mqtt_${user}`,
          meterid: id,
        });
        let result = commandController(res);
        if (result.type !== "success") {
          toast[result.type](result.message);
          setgetPSSLoading(false);
          return;
        }
        // toast.success("Success");
        var time = setTimeout(() => {
          // toast.success("Your command is being processed");
          setgetPSSLoading(false);
        }, process.env.REACT_APP_TIME);

        mqttEvents.on("message", (message) => {
          if (message.topic === `mqtt_${user}`) {
            if (!message.payloadString) return null;
            if (JSON.parse(message.payloadString).type == "PSS05") {
              setgetPSSLoading(false);
              return clearTimeout(time);
            }
          }
        });

        // setgetPSSLoading(false);
      } catch (err) {
        toast.error(err.message);
      }
    })();
    return () => {};
  }, [refresh]);

  const enquiryHandler = (enquiry) => {
    switch (enquiry) {
      case "poweron":
        (async () => {
          try {
            setloading(true);
            let user = await getToken("spiral_username");
            let res = await axios.post("/commands/poweruponsupply", {
              transactiontopic: `mqtt_${user}`,
              meterid: id,
            });
            let result = commandController(res);
            if (result.type !== "success") {
              toast[result.type](result.message);
              setloading(false);
              return;
            }
            var time = setTimeout(() => {
              setloading(false);
              toast.success("Your command is being processed");
              closeBtn(false);
            }, process.env.REACT_APP_TIME);

            mqttEvents.on("message", (message) => {
              if (message.topic === `mqtt_${user}`) {
                if (!message.payloadString) return null;
                if (JSON.parse(message.payloadString).type == "PSS00") {
                  setloading(false);
                  closeBtn(false);
                  return clearTimeout(time);
                }
              }
            });
          } catch (err) {
            toast.error(err.message);
            setloading(false);
          }
        })();
        return;
      case "poweroff":
        (async () => {
          try {
            setloading(true);
            let user = await getToken("spiral_username");
            let res = await axios.post("/commands/powerdownonsupply", {
              transactiontopic: `mqtt_${user}`,
              meterid: id,
            });
            let result = commandController(res);
            if (result.type !== "success") {
              toast[result.type](result.message);
              setloading(false);
              return;
            }
            var time = setTimeout(() => {
              setloading(false);
              toast.success("Your command is being processed");
              closeBtn(false);
            }, process.env.REACT_APP_TIME);

            mqttEvents.on("message", (message) => {
              if (message.topic === `mqtt_${user}`) {
                if (!message.payloadString) return null;
                if (JSON.parse(message.payloadString).type == "PSS05") {
                  setloading(false);
                  closeBtn(false);
                  return clearTimeout(time);
                }
              }
            });
          } catch (err) {
            toast.error(err.message);
            setloading(false);
          }
        })();
        return;
      default:
        break;
    }
  };
  return (
    <div>
      <div className="mt-4">
        <div className="font-Kanit text-center text-secondary font-semibold text-[17px]">
          Power supply on your device
        </div>
        <div className=" text-center text-gray-700 font-light w-[70%] mx-auto ">
          Use the option to determine state you device take when there ie power
          supply
        </div>
      </div>
      <div className="my-3 border-dashed border-[1px] border-gray-400 rounded-md h-[100px] flex items-center justify-center">
        {getPSSLoading ? (
          <div className="flex flex-col items-center justify-center">
            <MoonLoader
              css={override}
              color={"#000"}
              size={20}
              speedMultiplier={1}
            />
            <div className="">fetching last saved power supply state...</div>{" "}
          </div>
        ) : (
          "You device will remain on when there is power supply."
        )}
      </div>
      <div className="grid grid-cols-2 gap-10 mt-6 h-[150px] place-content-center">
        {powerData.map(({ title, id, desc, icon, cmd }) => (
          <div
            key={id}
            onClick={() => enquiryHandler(cmd)}
            className="bg-white rounded-md drop-shadow-xl p-2 border-b-[2px] transition-all ease-out border-gray-600 hover:border-secondary cursor-pointer relative"
          >
            <div className="">{icon}</div>
            <div className="font-semibold pt-1">{title}</div>
            <div className="font-light">{desc}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PowerSupplyTab;

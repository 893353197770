import NotFoundImg from "../../assets/png/Screenshot 2023-03-20 at 18.13.07.png";
import { Link, useNavigate } from "react-router-dom";
const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="h-screen w-screen  items-center justify-center flex bg-gray-100">
      <div className="flex items-center flex-col justify-center mt-[-50px] bg-white rounded-md drop-shadow-sm p-3">
        <div className="w-[250px] h-[250px]">
          <img src={NotFoundImg} alt="" className="" />
        </div>
        <div className="font-Kanit text-2xl text-gray-800">
          Oppss, You have enter an unknown page!!!.
        </div>
        <div
          className="text-center underline text-blue-500 cursor-pointer"
          onClick={() => navigate(-1)}
        >
          Go back
        </div>
      </div>
    </div>
  );
};

export default NotFound;

import { LOG_IN, LOG_OUT } from "../types";

export const postLogin = () => {
  return {
    type: LOG_IN,
  };
};

export const postLogout = () => {
  try {
    localStorage.clear();
    return {
      type: LOG_OUT,
    };
  } catch (err) {
    console.log(err);
  }
};

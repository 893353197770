import React, { useMemo, useState, useEffect } from "react";
import Layout from "../../../components/Layout";
import { useLocation, useSearchParams } from "react-router-dom";
import { useAsync } from "../../../utils/Hooks/useAsync";
import { client } from "../../../utils/api";
import { themeColor } from "../../../constant/color";
import { Button } from "../../../components/Button";
import AllRolesTable from "../../../components/Roles/Tables/Tables/AllRolesTable";
import axios from "../../../utils/axios";
import { toast } from "react-hot-toast";
import { channelController } from "../../../controllers/channelController";
import { Select } from "antd";
import AddRoles from "../../../components/Roles/AddRole";
const SysAdminDashboard = () => {
  const { Option } = Select;
  const [modal, setModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);
  const [selectedItem, setselectedItem] = useState("");
  const location = useLocation();
  const query = new URLSearchParams(location.search).get("role");
  let dropDown = [
    "All",
    "Project Manager",
    "Customer Representative",
    "Customer Agent",
    "System Manager",
  ];

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/roles/getallroles", {});

        let result = channelController(res);

        if (result.type !== "success") {
          toast.error(result.message);
          return;
        }

        if (query && !selectedItem) {
          setData(result.message.body.filter((i) => i.role === query));
          // console.log("Got here");
          return;
        }

        if (selectedItem == "All" || selectedItem == "") {
          setData(result.message.body);
          return;
        }

        setData(result.message.body.filter((i) => i.role == selectedItem));
      } catch (err) {
        if (err.response) {
          return toast.error(err.response.data.response);
        }
        toast.error(err.message);
      }
    })();
  }, [selectedItem, refresh]);

  return (
    <Layout>
      {modal && <AddRoles closeModal={setModal} refreshBtn={setRefresh} />}
      <div className="flex items-cente justify-between bg-white rounded-md shadow-md p-2">
        <div className="flex items-center">
          <div className=" w-[15px] h-[25px] bg-[#e0c5ff] rounded-sm"></div>
          <div className="font-semibold ml-2">
            Project manager details for Abuja
          </div>
        </div>
        <div className="flex items-center justify-end">
          <div className="mr-4 h-[40px]">
            <Select
              style={{
                width: 200,
              }}
              showSearch
              showArrow
              bordered
              defaultValue={query || "All"}
              value={selectedItem}
              // placeholder={dropDownPlaceholder}
              onChange={(e) => setselectedItem(e)}
            >
              {dropDown.map((item) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </div>
          <div className="flex items-center w-[170px] justify-end">
            <Button
              text={"Assign Role"}
              height={35}
              bg={themeColor.secondary}
              onClick={() => setModal(true)}
            />
          </div>
        </div>
      </div>
      <div className="bg-white mt-2 rounded-md shadow-md p-2 h-[90vh]">
        <AllRolesTable data={data} />
      </div>

      {/* <div className="bg-white rounded-md shadow-md">
        <div className="mt-8 my-1 font-semibold"></div>
        <div className="text-gray-500 font-ligth">
          List of all {queryParameters.get("role").replace("-", " ")}s
        </div>
      </div> */}
    </Layout>
  );
};

export default SysAdminDashboard;

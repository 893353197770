import {
  AiFillEye,
  AiOutlineEye,
  AiOutlineKey,
  AiOutlinePhone,
  AiOutlineUser,
} from "react-icons/ai";
import { Button } from "../../../components/Button";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { getToken, setToken } from "../../../utils/token";
import axios from "axios";
import BGImg from "../../../assets/png/v300batch2-nunoon-13.jpg";

const CompleteRegistration = () => {
  let navigate = useNavigate();
  const [eye, setEye] = useState(false);
  const [password, setPassword] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      if (!firstname || !lastname || !email || !phone) {
        return toast.error("All values are required");
      }
      let callerid = await getToken("spiral_username");
      let sessionid = await getToken("spiral_token");
      setLoading(true);
      let res = await axios.post(
        "https://api.mms.spiral.systems/auth/completeregistration",
        {
          callerid: callerid,
          sessionid,
          firstname,
          lastname,
          phone,
          email,
        }
      );
      if (res.data.response == "failure") {
        setLoading(false);
        return toast.error("Operation failed. Please try again");
      }

      if (res.data.response == "invalid session") {
        setLoading(false);
        toast.error(res.data.response);
        navigate("/");
        return;
      }

      setLoading(false);
      toast.success("Registration complete. Please log in to continue.");
      await setToken("spiral_registration", 1);

      // Make request to get User Role
      // let userName = getToken("spiral_username");
      // let rolesReq = await axios.post(
      //   "https://api.mms.spiral.systems/roles/getuserroles",
      //   {
      //     callerid: callerid,
      //     targetusername: callerid,
      //     sessionid: sessionid,
      //   }
      // );

      // let userRoles = rolesReq.data.body;

      // if (!userRoles.length) {
      //   await setToken("spiral_role", "Customer");
      //   navigate("/dashboard");
      //   setLoading(false);
      // } else {
      //   await setToken("spiral_role", "");
      //   navigate("/userroles");
      //   setLoading(false);
      // }
      return navigate("/");
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  };

  return (
    <div className="h-screen bg-gray-50 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 place-items-center place-content-center gap-10">
      <div
        className="h-screen absolute top-0 left-0 w-screen"
        style={{
          background: `url(${BGImg})`,
          backgroundPosition: "center",
          backgroundRepeat: "center",
          backgroundSize: "cover",
          opacity: 0.3,
          backgroundAttachment: "fixed",
        }}
      ></div>
      <div className="w-[95%] z-50 md:w-[500px] mx-auto bg-white rounded-md shadow-md">
        <div className="text-center pt-2 text-primary font-semibold text-2xl">
          Spiral Management System
        </div>
        <div className=" w-[full] h-auto rounded-md p-6">
          <div className="font-noraml text-center text-[15px] text-gray-600">
            Fill the fields below to complete registration.
          </div>
          <hr className="my-4" />
          <div className="">
            {/* Firstname */}
            <div className="">
              <label className="text-primary font-normal text-sm">
                Firstname
              </label>
              <div className="flex bg-white items-center w-100 border-[1px] rounded-sm border-gray-300 mt-2 h-[50px]">
                <div className="d-inline flex items-center justify-center px-2">
                  <AiOutlineUser
                    size={20}
                    color="text-primary"
                    className="text-primary"
                  />
                </div>
                <div className="ml-2">
                  <input
                    value={firstname}
                    onChange={(e) => setFirstName(e.target.value)}
                    type={"text"}
                    placeholder={"Enter your username"}
                    className=" placeholder:text-sm placeholder:font-thin outline-none border-[0px]"
                  />
                </div>
              </div>
            </div>
            {/* Lastname */}
            <div className="mt-4">
              <label className="text-primary font-normal text-sm">
                Lastname
              </label>
              <div className="flex bg-white items-center w-100 border-[1px] rounded-sm border-gray-300 mt-2 h-[50px]">
                <div className="d-inline flex items-center justify-center px-2">
                  <AiOutlineUser
                    size={20}
                    color="text-primary"
                    className="text-primary"
                  />
                </div>
                <div className="ml-2">
                  <input
                    value={lastname}
                    onChange={(e) => setLastName(e.target.value)}
                    type={"text"}
                    placeholder={"Enter your lastname"}
                    className=" placeholder:text-sm placeholder:font-thin outline-none border-[0px]"
                  />
                </div>
              </div>
            </div>
            {/* Email */}
            <div className="mt-4">
              <label className="text-primary font-normal text-sm">Email</label>
              <div className="flex bg-white items-center w-100 border-[1px] rounded-sm border-gray-300 mt-2 h-[50px]">
                <div className="d-inline flex items-center justify-center px-2">
                  <AiOutlineUser
                    size={20}
                    color="text-primary"
                    className="text-primary"
                  />
                </div>
                <div className="ml-2">
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type={"email"}
                    placeholder={"Enter your username"}
                    className=" placeholder:text-sm placeholder:font-thin outline-none border-[0px]"
                  />
                </div>
              </div>
            </div>
            {/* Phone */}
            <div className="mt-4">
              <label className="text-primary font-normal text-sm">
                Phone number
              </label>
              <div className="flex bg-white items-center w-100 border-[1px] rounded-sm border-gray-300 mt-2 h-[50px] ">
                <div className="d-inline flex items-center justify-center px-2">
                  <AiOutlinePhone
                    size={20}
                    color="text-primary"
                    className="text-primary"
                  />
                </div>
                <div className="ml-2">
                  <input
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    type={"number"}
                    placeholder={"Enter your phone number"}
                    className=" placeholder:text-sm placeholder:font-thin outline-none border-[0px]"
                  />
                </div>
              </div>
            </div>
            {/* Button */}
            <div className=" w-100 mt-7">
              <Button
                text={"Continue"}
                loading={loading}
                onClick={handleSubmit}
              />
            </div>

            <div className="w-100 mt-4">
              <div className="text-primary text-center font-normal py-3">
                {/* Need help with you account? */}
              </div>
              <Link
                to="/createaccount"
                className="underline w-100 block cursor-pointer text-sm font-light text-secondary text-center"
              >
                Don't have an account?
                <span className="font-semibold text-1xl"> Log in.</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompleteRegistration;

import axios from "../../../utils/axios";
import { useState, useEffect } from "react";
import { channelController } from "../../../controllers/channelController";
import PageTitle from "../../PageTitle";
import { Dropdown, Select } from "antd";
import ProjectSiteTable from "../Table/ProjectSiteTable";
import Rule from "../../Rule/Index";
const ProjectSiteMeters = ({
  dropDown,
  data,
  setSelectedZone,
  selectedZone,
  sitename,
}) => {
  const { Option } = Select;
  return (
    <div className="">
      <div className="flex place-items-center justify-between">
        <PageTitle title={"Meters in " + sitename} caption={"Description."} />
        <div></div>
        <div className="w-[200px] hidden">
          <div className="text-gray-700 italic text-right">
            Select a project site
          </div>
          <Select
            placeholder="Select project site."
            allowClear
            showAction
            showSearch
            onChange={(e) => setSelectedZone(e)}
            style={{
              width: "100%",
            }}
          >
            {dropDown.map((i, index) => (
              <Option key={index} value={i}>
                {i}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <Rule />
      <div className="">
        <ProjectSiteTable data={data} />
      </div>
    </div>
  );
};

export default ProjectSiteMeters;

import { IoNotificationsOutline } from "react-icons/io5";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoMdArrowDropdown } from "react-icons/io";
import { deleteToken, getToken } from "../../utils/token";
import { toast } from "react-hot-toast";
import { useState, useEffect } from "react";
import {
  useLocation,
  Link,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Button } from "../Button";
import AddUser from "../Modals/AddUser";
import AddCustomer from "../Modals/AddCustomer";
import { HiOutlineUserPlus } from "react-icons/hi2";
import axios from "../../utils/axios";
import { errorBlock } from "../../controllers/errorBlock";
import SideNav from "../SideNav";
import MobileSideNav from "../MobileSideNav";
import Backdrop from "../Backdrop";
import { useDispatch } from "react-redux";
import { postLogout } from "../../redux/actions/auth.action";
import { BsPersonPlus } from "react-icons/bs";
import TopNavTitle from "./TopNavTitle";
import { pageTitle } from "./data";
const TopNav = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [showdd, setshowdd] = useState(false);
  const navigate = useNavigate();
  const [usermodal, setusermodal] = useState(false);
  const [customermodal, setcustomermodal] = useState(false);
  const [role, setrole] = useState("");
  const [refresh, setrefresh] = useState(false);
  const [noti, setnoti] = useState("");
  const [opendrawal, setopendrawal] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    (async () => {
      try {
        let userrole = await getToken("spiral_role");
        setrole(userrole);
      } catch (err) {
        toast.error(err.message);
      }
    })();
    return () => {};
  }, []);

  // request
  const [request, setrequest] = useState(0);
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       let res = await axios.post("/submeter/getreceivedchanneladdrequests", {
  //         limit: 100,
  //         offset: 0,
  //       });

  //       setrequest(res?.data.meta.count);
  //       // setusers();
  //     } catch (err) {
  //       errorBlock(err);
  //     }
  //   })();
  //   return () => {};
  // }, []);

  const title = () => {
    if (searchParams.get("name")) {
      return {
        title: searchParams.get("name"),
      };
    }

    switch (window.location.pathname) {
      case "/dashboard":
        return {
          title: "Dashboard",
        };
      case "/allmeters":
        return {
          title: "Meters",
        };
      case "/allchannels":
        return { title: "Meters" };
      case "/meterclass":
        return { title: "Meterclass" };
      case "/wallet":
        return { title: "Wallets" };
      case "/settings":
        return { title: "Profile" };
      default:
        return {
          title: "",
        };
    }
  };

  return (
    <>
      {opendrawal && (
        <Backdrop open={opendrawal}>
          <MobileSideNav
            opendrawal={opendrawal}
            setopendrawal={setopendrawal}
          />
        </Backdrop>
      )}

      {usermodal && <AddUser open={usermodal} setopen={setusermodal} />}
      {customermodal && (
        <AddCustomer open={customermodal} setopen={setcustomermodal} />
      )}
      <div className="flex px-4 items-center justify-between h-[65px] drop-shadow-md bg-white sticky top-0 left-0 ">
        <div className="">
          <div className="font-semibold font-kanit text-[17px]">
            {/* {title().title} */}
            {pageTitle(window.location.pathname)}
          </div>
          <div className="font-light text-gray-600 hidden">Meter Overview</div>
        </div>
        <div className="flex items-start">
          {/* Add customer */}
          {/* {role == "Project Manager" && ( */}
          {/* <div className="w-[110px] mx-4">
            <Button
              text={"Onboard Customer"}
              height={"35px"}
           
            />
          </div> */}
          {/* )} */}
          {/* add user */}
          {/* {role == "Customer Manager" && (
            <div className="w-[110px] mx-4">
              <Button
                text={"Add User"}
                height={"35px"}
                onClick={() => setusermodal(true)}
              />
            </div>
          )} */}
          {/* profile cover */}
          <Link
            to="/request"
            className="items-center hidden justify-center pt-1 relative mx-3 px-2"
          >
            <div className="w-[15px] h-[15px] left-[22px] top-[-3px] bg-red-500 flex items-center justify-center rounded-full absolute">
              <div className="text-white text-[12px] ">{request}</div>
            </div>
            <HiOutlineUserPlus size={20} className="text-gray-600" />
          </Link>
          {/* onbaord customer */}
          <div
            onClick={() => setcustomermodal(true)}
            className="items-center px-2 py-1 mr-2 rounded-md cursor-pointer border-[1px] border-secondary flex justify-center pt-1"
          >
            <BsPersonPlus className="text-secondary " size={18} />
            <div className="text-secondary ml-2">Onboard customer</div>
          </div>

          {/* Add user */}
          <div
            onClick={() => setusermodal(true)}
            className="items-center px-2 py-1 rounded-md cursor-pointer border-[1px] border-secondary flex justify-center pt-1"
          >
            <BsPersonPlus className="text-secondary " size={18} />
            <div className="text-secondary ml-2">Add User</div>
          </div>
          <div
            onClick={() => setshowdd(!showdd)}
            className="flex cursor-pointer relative ml-3 items-center justify-between rounded px-2 py-1 w-[100px] border-[1px] border-gray-300"
          >
            <div className="">Profile</div>
            <div className="flex items-center justify-center">
              <IoMdArrowDropdown size={18} />
            </div>
          </div>
          {showdd && (
            <div className="absolute top-[60px] right-[10px] animate__fadeInUp animate__animated p-2 w-[150px] bg-white rounded-sm drop-shadow-sm border-[0.5px]">
              <Link
                to="/settings"
                className="text-gray-700 py-2 px-1 my-2 hover:text-secondary hover:underline"
              >
                View Profile
              </Link>
              <div
                onClick={async () => {
                  try {
                    // dispatch(postLogout());
                    // window.localStorage.href = "/login";
                    localStorage.clear();
                    window.location.href = "/";
                  } catch (err) {
                    toast.error(err.message);
                  }
                }}
                className="text-gray-700 py-2 px-1 my-2 hover:text-secondary hover:underline"
              >
                Logout
              </div>
            </div>
          )}

          {/* {pathname !== "/userroles" && opendrawal && ( */}

          <div
            className="flex md:flex lg:hidden items-center justify-center pt-1 px-2 ml-2 cursor-pointer"
            onClick={() => setopendrawal(true)}
          >
            <HiOutlineMenuAlt3 size={22} />
          </div>
        </div>
      </div>
    </>
  );
};

export default TopNav;

import "./styles.css";
import { IoClose } from "react-icons/io5";
import moment from "moment";
import { useEffect, useState } from "react";
import axios from "../../../utils/axios";
import toast from "react-hot-toast";
import { BsCalendar2Date } from "react-icons/bs";
import { FcFlashOn } from "react-icons/fc";
import { FaUser, FaUsersCog } from "react-icons/fa";
import { meterController } from "../../../controllers/MeterController";
import CardTitle from "../../CardTitle";
import { themeColor as color } from "../../../constant/color";
import { Button } from "../../Button";
// import Skimmer from "../../../Skimmer";
// import { rolesController } from "../../../controllers/RolesController";
import { commandController } from "../../../controllers/CommandController";

const RoleInfoModal = ({ closeModal, roleId, username }) => {
  const [meterDetails, setMeterDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [assignLoader, setAssignLoader] = useState({
    assign: false,
    unassign: false,
  });
  const [data, setData] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/roles/getinfobyroleid", {
          roleid: roleId,
        });
        setData(res.data);
        setLoading(false);
      } catch (err) {
        toast.error(err.message);
        setLoading(false);
      }
    })();
  }, []);
  const handleAssign = async () => {
    try {
      setAssignLoader({ assign: true });
      let res = await axios.post("/roles/assignrole", {
        roleid: roleId,
        targetusername: username,
      });
      let result = commandController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setAssignLoader({ assign: false });
      }
      toast[result.type]("Roles assigned successfully.");
      setAssignLoader({ assign: false });
    } catch (err) {
      toast.error(err.message);
    }
  };
  const handleUnAssign = async () => {
    try {
      setAssignLoader({ unassign: true });
      let res = await axios.post("/roles/unassignrole", {
        roleid: roleId,
      });
      let result = commandController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setAssignLoader({ unassign: false });
      }
      toast[result.type]("Roles unassigned successfully.");
      setAssignLoader({ unassign: false });
    } catch (err) {
      toast.error(err.message);
      setAssignLoader({ unassign: false });
    }
  };

  return (
    <div className="backdrop">
      <div className="meter-info__modal px-3 py-3 rounded-1 shadow-sm bg-white animate__animated animate__fadeInUp">
        <div className="meter-info__top pb-1 d-flex align-items-center justify-content-between">
          <div className="meter-info-title">Role Info</div>
          <div className="meter-info-title" onClick={() => closeModal(false)}>
            <IoClose size={18} color={color.darkColor} />
          </div>
        </div>
        <div className="">
          <div className="meter-info__entry mt-2 d-flex align-items-center justify-content-between">
            <div className="meter-info__title">Username:</div>
            <div className="meter-info__caption">
              {loading ? (
                <div className="text-danger"></div>
              ) : data?.username ? (
                data?.username
              ) : (
                <div className="text-danger">Account suspended</div>
              )}
            </div>
          </div>
          <div className="meter-info__entry mt-2 d-flex align-items-center justify-content-between">
            <div className="meter-info__title">Role:</div>
            <div className="meter-info__caption">
              {loading ? <div className=""></div> : data?.role}
            </div>
          </div>
          <div className="meter-info__entry mt-2 d-flex align-items-center justify-content-between">
            <div className="meter-info__title">Project zone:</div>
            <div className="meter-info__caption">
              {loading ? <div className=""></div> : data?.projectzone}
            </div>
          </div>

          <div className="meter-info__entry mt-2 d-flex align-items-center justify-content-between">
            <div className="meter-info__title">Date created:</div>
            <div className="meter-info__caption">
              <span className="mb-3 mx-1">
                <BsCalendar2Date size={13} color={color.baseColor} />
              </span>
              {moment(data?.datecreated).format("DD-MM-YYYY HH:mm")}
            </div>
          </div>
          <div className="container-fluid mt-2 px-0">
            <CardTitle title={"Actions"} />
            <div className="row px-2 mt-2 d-flex align-items-center justify-content-between">
              <div
                className="col-4 bg-white d-flex p-1 align-items-center rounded-1 jsutify-content-center"
                onClick={handleAssign}
                style={{
                  color: color.baseColor,
                  border: `1px solid ${color.baseColor}`,
                  cursor: "pointer",
                }}
              >
                <div className="d-flex px-2 align-items-center jsutify-content-center">
                  <FaUsersCog size={15} color={color.baseColor} />
                </div>
                <div
                  className="px-2"
                  style={{
                    color: color.baseColor,
                  }}
                >
                  <Button
                    text={"Assign role"}
                    color={color.baseColor}
                    bg={color.whiteColor}
                    height={"30px"}
                    loaderColor={color.baseColor}
                    status={assignLoader.assign}
                  />
                </div>
              </div>
              <div
                className="col-5 bg-white rounded-1 p-1 d-flex align-items-center jsutify-content-center"
                onClick={handleUnAssign}
                style={{
                  color: color.redColor,
                  border: `1px solid ${color.redColor}`,
                  cursor: "pointer",
                }}
              >
                <div className="d-flex px-2 align-items-center jsutify-content-center">
                  <FaUsersCog size={15} color={color.redColor} />
                </div>
                <div
                  className=" px-2"
                  style={{
                    color: color.redColor,
                  }}
                >
                  <Button
                    text={"Unassign role"}
                    color={color.redColor}
                    bg={color.whiteColor}
                    height={"30px"}
                    loaderColor={color.redColor}
                    status={assignLoader.unassign}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4 d-flex align-items-center justify-content-end">
            <div className="col-lg-6 d-none">
              <Button
                text={"Cancel"}
                bg={color.btnFadeColor}
                color={color.darkColor}
                height={"35px"}
              />
            </div>
            <div className="col-4" onClick={() => closeModal(false)}>
              <Button
                text={"Done"}
                bg={color.baseColor}
                color={color.whiteColor}
                height={"35px"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoleInfoModal;

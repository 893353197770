import moment from "moment";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { BsThreeDotsVertical, BsInfoCircle } from "react-icons/bs";
import { Button } from "../../../Button";
import { themeColor as color } from "../../../../constant/color";
import { FcCalendar } from "react-icons/fc";
import DataTable, { TableColumn } from "react-data-table-component";

const ProjectSitesTable = ({
  data,
  loading,
  setEditSite,
  setDeleteSite,
  selectedSite,
  setselectedSite,
}) => {
  const [selectedRow, setSelectedRow] = useState(null);
  let grid = useRef();
  let num = 1;

  const [selectedId, setSelectedId] = useState(null);
  const [modal, setModal] = useState(false);

  const Sample = (item) => {
    return (
      <div className="dropend">
        <button
          type="button"
          className="btn btn-sm dropdown-toggle menu-button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <BsThreeDotsVertical />
        </button>

        <div
          className="dropdown-menu dropdown-menu-end"
          style={{ padding: 0, fontSize: "12px !important", minWidth: "unset" }}
        >
          <Link
            className="dropdown-item table-dropdown-item"
            to={
              "/customer/" +
              encodeURI(item.personalinfo.username) +
              "/meterdetails/" +
              encodeURI(item.number)
            }
          >
            <BsInfoCircle /> View
          </Link>
        </div>
      </div>
    );
  };

  const columns = [
    {
      name: "Site name",
      center: true,
      selector: (row) => row.sitename,
      grow: 1,
    },
    {
      name: "Site type",
      center: true,
      selector: (row) => row.sitetype,
      grow: 1,
    },
    {
      name: "GPS",
      center: true,
      selector: (row) => row.gps,
      grow: 1,
    },

    {
      name: "Date created",
      center: true,
      selector: (row) => row.datecreated,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="px-1">
              <FcCalendar size={18} />
            </div>
            <div className="text-[11px]">
              {moment(item.datecreated).format("lll")}
            </div>
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => row.siteid,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex relative rounded-md items-center justify-between">
            <div
              onClick={() => {
                setselectedSite(item.sitename);
                setDeleteSite(true);
              }}
              className="border-[1px] cursor-pointer ml-2 rounded border-red-400 text-[12px] text-red-400 font-light px-4 py-2"
            >
              Delete
            </div>
            <div
              onClick={() => setEditSite(item)}
              className="border-[1px] cursor-pointer ml-2 rounded border-green-600 text-[12px] text-green-600 font-light px-4 py-2"
            >
              Edit
            </div>
            <Link
              to={`/dashboard/systemadmin/projectsite/${item.sitename}`}
              className="border-[1px] cursor-pointer ml-2 rounded border-gray-400 text-[12px] font-light px-4 py-2"
            >
              Details
            </Link>
          </div>
        );
      },
    },
  ];

  return (
    <DataTable
      style={{
        height: "100%",
      }}
      columns={columns}
      data={data}
      pagination
      progressPending={loading}
      paginationPerPage={10}
    />
  );
};

export default ProjectSitesTable;
